import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import fetch, { METHOD } from '../../../../fetch.js'
import { setSleeveProducts } from '../../../../reducer/action/cart.js'
import Input from '../../../UI/Input/Input.js'
import { StyledRow, Name, ArtNr, CountRequired, CountPackage } from './Row.Styled.js'

const Row = ({ item, overwriteSleeveProducts }) => {
  const translation = useSelector((s) => s.translation)
  const token = useSelector((s) => s.token)
  const [units, setUnits] = useState(null)
  const dispatch = useDispatch()
  const cartSleeveProducts = useSelector((s) => s.cartSleeveProducts)

  // const sleeveProductsToUse = overwriteSleeveProducts || cartSleeveProducts

  useEffect(() => {
    const fetchUnits = async () => {
      const { status, results } = await fetch(METHOD.GET, '/unit/get-all', { token })
      if (status && results) setUnits(results)
    }

    if (token && !units) fetchUnits()
  }, [token, units])

  useEffect(() => {
    const resolveCount = (foundUnit) => {
      if (item.count <= parseInt(foundUnit.VPE)) return 1
      else {
        const count =
          item.count % parseInt(foundUnit.VPE) === 0
            ? item.count / parseInt(foundUnit.VPE)
            : Math.floor(item.count / parseInt(foundUnit.VPE) + 1)

        return count
      }
    }

    if (cartSleeveProducts && !overwriteSleeveProducts) {
      const foundUnit = units?.find((u) => u.BezKabelMuffen === item.value)
      if (foundUnit) {
        const foundProduct = cartSleeveProducts?.find((s) => s.TITYPN === foundUnit.TITYPN)
        if (!foundProduct)
          dispatch(
            setSleeveProducts(
              Array.isArray(cartSleeveProducts)
                ? [
                    ...cartSleeveProducts,
                    {
                      count: resolveCount(foundUnit),
                      countRequired: item.count,
                      TITYPN: foundUnit.TITYPN,
                      prop: item.prop,
                      value: item.value,
                    },
                  ]
                : [
                    {
                      count: resolveCount(foundUnit),
                      countRequired: item.count,
                      TITYPN: foundUnit.TITYPN,
                      prop: item.prop,
                      value: item.value,
                    },
                  ],
            ),
          )
      }
    }
  }, [cartSleeveProducts, units, item, dispatch, overwriteSleeveProducts])

  const resolveUnitCount = () => {
    if (!units) return
    const foundUnit = units.find((u) => u.BezKabelMuffen === item.value)

    if (item.count <= parseInt(foundUnit.VPE))
      return { count: parseInt(foundUnit.VPE), unit: foundUnit.Einheit, artNr: foundUnit.TITYPN }
    else {
      const count = parseInt(foundUnit.VPE)
      // parseInt(foundUnit.VPE) / parseInt(item.count) + (parseInt(foundUnit.VPE) % item.count) ===
      // 0
      //   ? 0
      //   : 1

      return { count, unit: foundUnit.Einheit, artNr: foundUnit.TITYPN }
    }
  }

  const updateCount = (value) => {
    const foundUnit = units.find((u) => u.BezKabelMuffen === item.value)
    const foundProduct = cartSleeveProducts.find((s) => s.TITYPN === foundUnit.TITYPN)
    if (foundProduct) {
      dispatch(
        setSleeveProducts([
          ...cartSleeveProducts.filter((s) => s.TITYPN !== foundUnit.TITYPN),
          { ...foundProduct, count: parseInt(value) },
        ]),
      )
    }
  }

  return (
    <StyledRow>
      <Name>{`${translation['sleeve'][item.prop]}: ${item.value}`}</Name>
      <ArtNr>{resolveUnitCount()?.artNr}</ArtNr>
      <CountRequired>{`${item.count} ${resolveUnitCount()?.unit}`}</CountRequired>
      <CountPackage>
        <Input
          disabled={!!overwriteSleeveProducts}
          width="40%"
          type="number"
          value={
            [...(overwriteSleeveProducts || cartSleeveProducts)]?.find(
              (s) => s.TITYPN === resolveUnitCount()?.artNr,
            )?.count
          }
          onChange={updateCount}
        />
        x {resolveUnitCount()?.count}
        {` ${resolveUnitCount()?.unit}`}
      </CountPackage>
    </StyledRow>
  )
}

export default Row
