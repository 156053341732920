import _ from 'lodash'

export const set = (state, action) => {
  return { ...state, cart: action.payload }
}

export const add = (state, action) => {
  const item = action.payload
  const cartCopy = _.cloneDeep(state.cart)

  const existingItem = cartCopy.find(
    (el) =>
      el.article.uuid === item.uuid ||
      (el.article.uuid === item.article?.uuid && el.article.uuid && item.article?.uuid),
  )

  if (existingItem) {
    existingItem.productCount++
  } else {
    cartCopy.push({ article: item, productCount: 1 })
  }

  return { ...state, cart: cartCopy }
}

export const addItems = (state, action) => {
  const items = action.payload
  const cartCopy = _.cloneDeep(state.cart)
  for (const item of items) {
    const existingItem = cartCopy.find(
      (el) =>
        el.article.uuid === item.uuid ||
        (el.article.uuid === item.article?.uuid && el.article.uuid && item.article?.uuid),
    )
    if (existingItem) {
      existingItem.productCount++
    } else {
      cartCopy.push({ article: item.article, productCount: item.productCount || 1 })
    }
  }

  return { ...state, cart: cartCopy }
}

export const updateCount = (state, action) => {
  const item = action.payload.item
  const count = action.payload.count

  const cartCopy = _.cloneDeep(state.cart)

  const existingItem = cartCopy.find(
    (el) =>
      el.article.uuid === item.uuid ||
      (el.article.uuid === item.article?.uuid && el.article.uuid && item.article?.uuid),
  )

  if (existingItem && count > 0) {
    existingItem.productCount = count
  }

  return { ...state, cart: cartCopy }
}

export const remove = (state, action) => {
  const item = action.payload.article
  const cartCopy = _.cloneDeep(state.cart)
  const existingItem = cartCopy.find((el) => el.article.uuid === item.uuid)

  if (existingItem && existingItem.productCount > 1) {
    existingItem.productCount--
  } else {
    const newCart = cartCopy.filter((el) => el.article.uuid !== item.uuid)
    return { ...state, cart: newCart }
  }

  return { ...state, cart: cartCopy }
}

export const removePosition = (state, action) => {
  return {
    ...state,
    cart: state.cart.filter((el) => el.article.uuid !== action.payload.article.uuid),
  }
}

export const setSleeveProducts = (state, action) => {
  return { ...state, cartSleeveProducts: action.payload }
}

export const updateWireAndSleeveCount = (state, action) => {
  const { index, wireCount, sleeveCount } = action.payload

  const cartCopy = _.cloneDeep(state.cart)

  cartCopy[index].article.countWireInMeter = wireCount
  cartCopy[index].article.countSleeve = sleeveCount
  return { ...state, cart: cartCopy }
}
