import { useSelector } from 'react-redux'
import { formatNumber, getUUID } from '../../../../../utility'
import Text from '../../../../UI/Text/Text'
import { Card, Column, CustomFlex, DataRow } from './WireData.Styled'
import TLSuggestions from './TLSuggestions/TLSuggestions'

const WireData = ({ attributeSorting, wire, wireFamily, previousVersion, newerVersion }) => {
  const translation = useSelector((state) => state.translation)
  const theme = useSelector((state) => state.theme)
  const seperatePropertiesIfCombinedWire = [
    'leiterdurchmesser',
    'anzahlverseilelemente',
    'verseilelement',
    'aderzahl',
    'leiterdurchmesserinmm',
    'leiterwiderstanddesadernpaarsinkmbei10c',
    'leiterwiderstanddereinzeladerinkmbei10c',
    'leiterwiderstanddereinzeladerinkm',
    'isolationswiderstandingxkm',
    'betriebskapazittinnfkmbei800hz',
    'quetschverbinder',
    'mengequetschverbinder',
  ]

  const hiddenProperties = [
    'aderzahl',
    'kurzzeichenalternativ',
    'name',
    'neualt',
    'vaterid',
    'anzahlverseilelementekabel',
    'anzahlverseilelemente',
    'verseilelement',
    'isinner',
  ]

  const getValue = (attr) => {
    if (!wire.sibling || !seperatePropertiesIfCombinedWire.includes(attr)) return wire[attr]

    return (
      <>
        {wire[attr]} {attr !== 'leiterdurchmesser' && `[${wire.leiterdurchmesser}er]`}
        <br />
        {wire.sibling[attr]}{' '}
        {attr !== 'leiterdurchmesser' && `[${wire.sibling.leiterdurchmesser}er]`}
      </>
    )
  }

  const getAttributeSortingSections = (attributeSection, i) => {
    const attributes = attributeSorting[attributeSection].filter((a) => !!wire[a])
    if (attributes.length === 0) return <div key={getUUID()}></div>
    return (
      <div key={getUUID()}>
        <Text
          margin={i === 0 ? '0 0 5px 0' : '15px 0 5px 0'}
          size={theme.size.sm}
          text={translation.wireView.wireData[attributeSection]}
        />
        {attributes.map((attr) => (
          <DataRow key={getUUID()}>
            <Column width="60%">{translation.wire[attr]}</Column>
            <Column width="40%">{getValue(attr)}</Column>
          </DataRow>
        ))}
      </div>
    )
  }

  const sorting = [
    'sapmaterialnummerdb',
    'artikelnummerbayka',
    'nettogewichtinkgkm',
    'minbiegeradiuseinminmm',
    'minbiegeradiusmehrminmm',
    'maxzugbelastunginn',
    'auendurchmesserinmm',
    'durchmesserberbewehrunginmm',
    'durchmesserbermantelinmm',
    'cuzahlinkgkm',
    'lastenheft',
    'kurzzeichen',
    'rkkennzahl',
  ]

  const sortConditionProperties = (a, b) => {
    return sorting.indexOf(a) - sorting.indexOf(b)
  }

  return (
    <div>
      <Text color={theme.color.primary} text={translation.wireView.wireData.headline} />
      <CustomFlex gap="50px" margin="10px 0px 0px 0px" wrap="nowrap" justify="unset">
        <Card>{Object.keys(attributeSorting).map(getAttributeSortingSections)}</Card>
        <Card>
          {Object.keys(wire)
            .filter((a) => {
              return (
                ![...attributeSorting.generic1, ...attributeSorting.generic2].includes(a) &&
                !hiddenProperties.includes(a) &&
                translation.wire[a]
              )
            })
            .sort(sortConditionProperties)
            .map((attr) => (
              <DataRow key={getUUID()}>
                <Column width="60%">{translation.wire[attr]}</Column>
                <Column width="40%">
                  {attr !== 'sapmaterialnummerdb' && attr !== 'artikelnummerbayka'
                    ? formatNumber(getValue(attr))
                    : getValue(attr)}
                </Column>
              </DataRow>
            ))}
          {previousVersion && (
            <>
              <Text
                margin="15px 0 5px 0"
                size={theme.size.sm}
                text={translation.wireView.wireData.oldVariantTitle}
              />
              {
                <DataRow key={getUUID()}>
                  <Column width="60%">{previousVersion.name}</Column>
                </DataRow>
              }
            </>
          )}
        </Card>
        {wire.lastenheft && <TLSuggestions lastenheft={wire.lastenheft} />}
      </CustomFlex>
    </div>
  )
}

export default WireData
