import {
  StyledCartItem,
  ButtonWrapper,
  ImageWrapper,
  CustomImage,
  NameColumn,
  StyledWire,
  Name,
  Count,
  StyledSleeve,
  ItemLabel,
  ItemLabelCount,
} from './Item.Styled.js'
import PlusIcon from '../../../assets/image/plus.png'
import MinusIcon from '../../../assets/image/minus.png'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../UI/Button/Button.js'
import { addCartItem, removeCartItem, removeCartPosition } from '../../../reducer/action/cart.js'
import { useEffect, useState } from 'react'
import { getSingleByArtikelnummer } from '../../../interface/article.js'
import getWireLabel from '../../../helper/getWireLabel.js'

const Item = ({ item }) => {
  const translation = useSelector((state) => state.translation)
  const token = useSelector((s) => s.token)
  const [wire, setWire] = useState(null)
  const [sleeve, setSleeve] = useState(null)
  const dispatch = useDispatch()

  const wireUUID = item?.article?.wireUUID
  const sleeveUUID = item?.article?.sleeveUUID
  const artikelnummer = item?.article?.wireArticleNumber

  useEffect(() => {
    const fetchWireAndSleeve = async () => {
      const { wire, sleeve } = await getSingleByArtikelnummer(token, artikelnummer)

      if (wire) {
        setWire(wire)
      }
      if (sleeve) {
        setSleeve(sleeve)
      }
    }

    if (token && !wire && !sleeve && artikelnummer) fetchWireAndSleeve()
  }, [token, wire, sleeve, wireUUID, sleeveUUID, artikelnummer])

  const removePosition = (item) => () => dispatch(removeCartPosition(item))

  const decrement = (item) => () => dispatch(removeCartItem(item))

  const increment = (item) => () => dispatch(addCartItem(item))

  return (
    <StyledCartItem key={item.article.uuid}>
      <NameColumn>
        {wire && (
          <StyledWire>
            <Name>{`${translation.profile.projectLists.list.item.labelWire} ${getWireLabel(
              wire,
              true,
              true,
            )}`}</Name>
            <Count>{`${item?.article?.countWireInMeter} ${translation.profile.projectLists.list.item.countWire}`}</Count>
          </StyledWire>
        )}
        {sleeve && (
          <StyledSleeve>
            <Name>{`${translation.profile.projectLists.list.item.labelSleeve}`}</Name>
            <Count>{`${item?.article?.countSleeve} ${translation.profile.projectLists.list.item.countSleeve}`}</Count>
          </StyledSleeve>
        )}
      </NameColumn>
      {item.article && (
        <ItemLabel>
          {item?.article?.TIBEZ1
            ? `${item.article.TIBEZ1} ${item.article.TIBEZ2}`
            : item.article.name}
        </ItemLabel>
      )}
      {item.article.id && (
        <ItemLabel>{`${translation.cart.id} ${item.article.TITYPN || item.article.id}`}</ItemLabel>
      )}
      <ItemLabelCount>{`${translation.cart.count} ${item.productCount}`}</ItemLabelCount>
      <ButtonWrapper>
        <ImageWrapper>
          <CustomImage src={MinusIcon} alt="minus" onClick={decrement(item)} />
        </ImageWrapper>
        <ImageWrapper>
          <CustomImage src={PlusIcon} alt="plus" onClick={increment(item)} />
        </ImageWrapper>
        <Button text={translation.cart.remove} onClick={removePosition(item)} width="100%" />
      </ButtonWrapper>
    </StyledCartItem>
  )
}

export default Item
