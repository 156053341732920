import styled from 'styled-components'
import Form from '../UI/Form/Form'

export const StyledSearch = styled.div`
  margin: 50px 0px;
`
export const Text = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.font.thirdary};
  font-size: ${({ theme }) => theme.size.md};
`

export const Strong = styled.span`
  font-family: 'bold';
`

export const InputAndSuggestionWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const CustomForm = styled(Form)`
  display: flex;
  justify-content: center;
  margin-top: 25px;
  gap: 15px;
`
export const SuggestionsWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: calc(100% - 2px);
  margin-top: 2px;
  max-height: 200px;
  border: thin solid ${({ theme }) => theme.color.border};
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #fff;
`
