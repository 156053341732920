import { StyledInput, Wrapper, Label } from './Input.Styled'

const Input = ({
  onChange,
  value,
  type,
  placeholder,
  margin,
  className,
  color,
  backgroundColor,
  size,
  label,
  labelColor,
  borderColor,
  width,
  autoComplete,
  required = false,
  minimized = false,
  disabled = false,
}) => {
  return (
    <Wrapper className={className} margin={margin} width={width}>
      {label && <Label text={label} color={labelColor} />}
      <StyledInput
        autoComplete={autoComplete}
        minimized={minimized}
        type={type || 'text'}
        onChange={(e) => onChange(e.target.value)}
        value={value || ''}
        placeholder={placeholder}
        required={required}
        color={color}
        backgroundColor={backgroundColor}
        size={size}
        disabled={disabled}
        borderColor={borderColor}
      />
    </Wrapper>
  )
}

export default Input
