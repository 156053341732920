import { getImage } from '../../../../utility'
import EvalRedirect from '../../../EvalRedirect/EvalRedirect'
import Image from '../../../UI/Image/Image'
import {
  StyledSuggestion,
  Name,
  Description,
  ImageWrapper,
  Content,
  FlexWrapper,
} from './Suggestion.Styled'

const Suggestion = ({ data, clickedCallback, input }) => {
  return (
    <StyledSuggestion>
      <EvalRedirect
        route={
          data.type === 'sleeve'
            ? `/detail/sleeve/${data.uuid}?input=${input}`
            : `/detail/${data.uuid}?input=${input}`
        }
        clickedCallback={clickedCallback}
      >
        <FlexWrapper>
          <ImageWrapper>
            {data.image && <Image maxHeight={'100%'} maxWidth={'100%'} src={getImage(data)} />}
          </ImageWrapper>
          <Content>
            <Name>{data.name || data.product_name_shop}</Name>
            <Description>{data.description}</Description>
          </Content>
        </FlexWrapper>
      </EvalRedirect>
    </StyledSuggestion>
  )
}

export default Suggestion
