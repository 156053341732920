import { StyledTextarea, Wrapper, Label } from './Textarea.Styled'

const Textarea = ({
  onChange,
  value,
  placeholder,
  margin,
  className,
  label,
  labelColor,
  rows = 3,
  required = false,
}) => {
  return (
    <Wrapper>
      {label && <Label text={label} color={labelColor} />}
      <StyledTextarea
        onChange={(e) => onChange(e.target.value)}
        value={value || ''}
        placeholder={placeholder}
        margin={margin}
        required={required}
        className={className}
        rows={rows}
      />
    </Wrapper>
  )
}

export default Textarea
