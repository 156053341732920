import styled from 'styled-components'

export const StyledLists = styled.div``

export const Title = styled.div`
  margin: 25px 0;
`

export const ListWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`

export const List = styled.div`
  width: 100%;
`

export const Name = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.color.primary};
  }
`

export const Comment = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  color: ${({ theme }) => theme.color.font.thirdary};
`
