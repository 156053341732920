import styled from 'styled-components'
import Link from '../../Link/Link'
import Text from '../../Text/Text'

export const StyledFooter = styled.div`
  display: flex;
  width: 100%;
  padding: 50px 0px;
  align-items: center;
  background-color: ${({ theme }) => theme.color.backgroundDark};
`

export const Column = styled.div`
  flex: 33%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex: 100%;
    margin: 20px 0;
  }
`

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.color.font.secondary};
  display: block;
  width: 100%;
`
export const CustomText = styled(Text)`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.size.sm};
`
