import styled from 'styled-components'

export const StyledRow = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
`

export const Name = styled.div`
  width: 40%;
  font-size: ${({ theme }) => theme.size.sm};
  color: ${({ theme }) => theme.color.font.thirdary};
`
export const ArtNr = styled.div`
  width: 20%;
  color: ${({ theme }) => theme.color.font.thirdary};
  font-size: ${({ theme }) => theme.size.sm};
`
export const CountRequired = styled.div`
  width: 20%;
  color: ${({ theme }) => theme.color.font.thirdary};
  font-size: ${({ theme }) => theme.size.sm};
`
export const CountPackage = styled.div`
  width: 20%;
  color: ${({ theme }) => theme.color.font.thirdary};
  font-size: ${({ theme }) => theme.size.sm};
  display: flex;
  gap: 10px;
  align-items: center;
`
