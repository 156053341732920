import styled from 'styled-components'

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`
export const Wrapper = styled.div`
  width: ${({ width }) => width || '100%'};
  margin: ${({ margin }) => margin || '0px'};
  position: relative;
`

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  /* clippath: inset(50%); */
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledSpan = styled.span`
  margin-left: 8px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.size.sm};
  * {
    cursor: pointer;
    font-size: ${({ theme }) => theme.size.sm};
  }
`

export const StyledCheckbox = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 3px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`
