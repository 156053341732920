import styled from 'styled-components'
import dropdownIcon from '../../../../assets/image/articleDetail/dropdown.png'
import Flex from '../../../UI/Flex/Flex'
import Image from '../../../UI/Image/Image'

export const StyledWireView = styled.div``
export const Header = styled.div`
  display: flex;
  gap: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
  }
`
export const ImageWrapper = styled.div`
  width: 25%;
  display: flex;
  height: 250px;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`
export const HeaderContent = styled.div`
  width: 75%;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const ReselectAderzahl = styled.div`
  margin: 25px 0;
  display: flex;
  align-items: center;
  gap: 10px;
`
export const Selected = styled.div`
  border: thin solid ${({ theme }) => theme.color.border};
  display: inline-block;
  padding: 3px 10px 3px 70px;
  cursor: pointer;
  background-image: url(${dropdownIcon});
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: 5px center;
  width: fit-content;
`

export const Details = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.color.secondary};
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    /* flex-wrap: wrap; */
    display: block;
  }
`

export const Column = styled.div`
  position: relative;
  padding-bottom: 50px;
  width: 50%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const CustomFlex = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    text-align: center;
    display: block;
    width: 100%;
  }
`

export const CustomImage = styled(Image)`
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    transform: rotate(90deg);
    height: 50px;
    margin: 20px 0px;
  }
`

export const ButtonCountWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  gap: 10px;
  width: 100%;
`
