import styled from 'styled-components'
import Text from '../../UI/Text/Text'

export const StyledSingleProjectList = styled.div``

export const Headline = styled.div`
  margin-top: 50px;
  color: ${({ theme }) => theme.color.primary};
  font-family: 'bold';
  font-size: ${({ theme }) => theme.size.lg};
`

export const Comment = styled.div`
  color: ${({ theme }) => theme.color.font.thirdary};
`

export const TextWrapper = styled.div`
  padding: 50px;
`

export const HeroHeadline = styled(Text)`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.size.lg};
  text-transform: uppercase;
  font-family: 'bold';
`

export const HeroText = styled(Text)`
  margin-top: 10px;
  color: ${({ theme }) => theme.color.font.secondary};
`

export const SearchWrapper = styled.div`
  width: 70%;
  margin-left: 15%;
`

export const HeroOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  width: 30%;
  height: inherit;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-right: 15px solid ${({ theme }) => theme.color.primary};
`

export const Footer = styled.div`
  padding-top: 20px;
  border-top: 1px solid ${({ theme }) => theme.color.border};
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
`
