import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { get, update, removeItem } from '../../../interface/projectList'
import Container from '../../UI/Container/Container'
import Layout from '../../UI/Layout/Layout'
import {
  StyledSingleProjectList,
  Headline,
  Comment,
  SearchWrapper,
  HeroHeadline,
  HeroText,
  HeroOverlay,
  TextWrapper,
  Footer,
} from './SingleProjectList.Styled'
import toast, { TYPE } from '../../../toast'
import Articles from './Articles/Articles'
import Hero from '../../UI/Hero/Hero'
import heroImage from '../../../assets/image/home/hero.jpg'
import Search from '../../Search/Search'
import _ from 'lodash'
import Button from '../../UI/Button/Button'
import { addCartItems } from '../../../reducer/action/cart'

const SingleProjectList = () => {
  const { uuid } = useParams()
  const [list, setList] = useState(null)
  const token = useSelector((s) => s.token)
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchLists = async () => {
      const list = await get(token, uuid)
      if (list) {
        setList(list)
      } else {
        toast(TYPE.ERROR, translation.profile.singleProjectList.error.loading)
      }
    }

    if (token && !list) {
      fetchLists()
    }
  }, [token, list, translation, uuid])

  const updateProp = (uuid) => (prop) => (value) => {
    const newList = _.cloneDeep(list)
    const item = newList.articles.find((i) => i.uuid === uuid)
    item[prop] = value
    setList(newList)
  }

  const save = async () => {
    const response = await update(token, list)
    if (response) {
      navigate('/profile/project-lists')
    }
  }

  const toCart = async () => {
    const items = list.articles.map((a) => ({ ...a, productCount: a.amount }))
    dispatch(addCartItems(items))

    toast(TYPE.SUCCESS, translation.projectListManager.results.success.addProjectList)
  }

  const remove = (uuid) => async () => {
    await removeItem(token, uuid)

    const newArticles = await list.articles.filter((a) => a.uuid !== uuid)

    setList({ ...list, articles: newArticles })
  }

  return (
    <Layout>
      <StyledSingleProjectList>
        <Hero img={heroImage}>
          <HeroOverlay>
            <TextWrapper>
              <HeroHeadline text={translation.home.hero.overlay.headline} />
              <HeroText text={translation.home.hero.overlay.text} />
            </TextWrapper>
          </HeroOverlay>
        </Hero>
        <Container>
          <SearchWrapper>
            <Search />
          </SearchWrapper>
          {list && (
            <>
              <Headline>{list.name}</Headline>
              <Comment>{list.comment}</Comment>
              {/* <FullscreenCart
                overwriteCart={list.articles.map((a) => ({ ...a, productCount: a.count }))}
              /> */}
              <Articles
                setList={setList}
                data={list.articles}
                updateProp={updateProp}
                remove={remove}
              />
            </>
          )}
          {/* <SleeveComponents
            overwriteCart={list?.articles?.map((article) => ({
              ...article,
              productCount: article.amount,
            }))}
          /> */}
          <Footer>
            <Button onClick={save} text={translation.profile.projectLists.list.save} />
            <Button
              backgroundColor={theme.color.danger}
              onClick={toCart}
              text={translation.profile.projectLists.list.toCart}
            />
            <Button
              backgroundColor={theme.color.font.thirdary}
              onClick={() => navigate('/profile/project-lists')}
              text={translation.profile.projectLists.list.abort}
            />
          </Footer>
        </Container>
      </StyledSingleProjectList>
    </Layout>
  )
}

export default SingleProjectList
