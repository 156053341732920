import { useSelector } from 'react-redux'
import {
  CartItemWrapper,
  ItemLabel,
  StyledCart,
  Wrapper,
  Header,
  Name,
  Count,
  Delete,
  Headline,
} from './FullscreenCart.Styled.js'
import Item from './Item/Item'
import SleeveComponents from './SleeveComponents/SleeveComponents.js'

const FullscreenCart = ({
  fullscreen = false,
  overwriteCart = null,
  overwriteSleeveProducts = null,
  margin,
  items,
}) => {
  const cart = useSelector((state) => state.cart)
  const token = useSelector((state) => state.token)
  const translation = useSelector((state) => state.translation)

  return (
    <Wrapper margin={margin}>
      <StyledCart fullscreen={fullscreen}>
        <Headline margin="0 0 20px 0">Signalkabel & Muffensets</Headline>
        <Header>
          <Name>{translation.profile.projectLists.list.header.name}</Name>
          <Count>{translation.profile.projectLists.list.header.count}</Count>
          {!overwriteCart && <Delete>{translation.profile.projectLists.list.header.delete}</Delete>}
        </Header>
        <CartItemWrapper fullscreen={fullscreen}>
          {(Array.isArray(cart) || Array.isArray(overwriteCart)) &&
          (cart.length > 0 || overwriteCart?.length > 0) ? (
            [...(overwriteCart || cart)]
              .filter((item) => item?.article?.type !== 'sleeve')
              .map((item, i) => <Item overwriteCart={overwriteCart} item={item} key={i} />)
          ) : (
            <ItemLabel>{token ? translation.cart.empty : translation.cart.blocked}</ItemLabel>
          )}
        </CartItemWrapper>
        <Headline margin="10px 0 0 0">Muffenbestandteile</Headline>
        <SleeveComponents
          overwriteCart={overwriteCart}
          overwriteSleeveProducts={overwriteSleeveProducts}
        />
        <Headline margin="20px 0 20px 0">Einzelteile</Headline>
        <Header>
          <Name>{translation.profile.projectLists.list.header.name}</Name>
          <Count>{translation.profile.projectLists.list.header.count}</Count>
          {!overwriteCart && <Delete>{translation.profile.projectLists.list.header.delete}</Delete>}
        </Header>
        <CartItemWrapper fullscreen={fullscreen}>
          {(Array.isArray(cart) || Array.isArray(overwriteCart)) &&
          (cart.length > 0 || overwriteCart?.length > 0) ? (
            [...(overwriteCart || cart)]
              .filter((item) => item?.article?.type === 'sleeve')
              .map((item, i) => <Item overwriteCart={overwriteCart} item={item} key={i} />)
          ) : (
            <ItemLabel>{token ? translation.cart.empty : translation.cart.blocked}</ItemLabel>
          )}
        </CartItemWrapper>
      </StyledCart>
    </Wrapper>
  )
}

export default FullscreenCart
