import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import fetch, { METHOD } from '../../fetch'
import Layout from '../UI/Layout/Layout'

const ImporterSleeve = () => {
  const [data, setData] = useState(null)
  const token = useSelector((s) => s.token)

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(METHOD.POST, '/importer/sleeve', { token })
      setData(response)
    }
    if (!data && token) {
      fetchData()
    }
  }, [data, token])

  return (
    <Layout>
      <div>{JSON.stringify(data)}</div>
    </Layout>
  )
}

export default ImporterSleeve
