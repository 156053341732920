import fetch, { METHOD } from '../fetch'

export const get = async (token) => {
  const { status, profile } = await fetch(METHOD.GET, '/profile', {
    token,
  })
  if (!status) return false
  return profile
}
export const update = async (token, profile) => {
  const { status } = await fetch(METHOD.PUT, '/profile', {
    token,
    profile,
  })

  return status
}
