import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../UI/Button/Button'
import Flex from '../../UI/Flex/Flex'
import Input from '../../UI/Input/Input'
import { StyledLoginForm, PasswordLost } from './LoginForm.Styled'
import { login, lostPassword } from '../../../interface/auth'
import toast, { TYPE } from '../../../toast'
import Cookies from 'universal-cookie'
import { setToken } from '../../../reducer/action/token'
import { useNavigate } from 'react-router-dom'

const LoginForm = ({ route }) => {
  const translation = useSelector((state) => state.translation)
  const cookies = new Cookies()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [mail, setMail] = useState('')
  const [lostPasswordActive, setLostPasswordActive] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const submitLogin = async () => {
    const token = await login(email, password)
    if (token) {
      cookies.set('token', token, { path: '/' })
      dispatch(setToken(token))
      navigate(route)
    } else {
      toast(TYPE.ERROR, translation.login.error.authentication)
    }
  }

  const submitLostPassword = async () => {
    const status = await lostPassword(mail)
    if (status) {
      toast(TYPE.SUCCESS, translation.login.success.lostPassword)
    } else {
      toast(TYPE.ERROR, translation.login.error.lostPassword)
    }
  }

  const submit = async () => {
    if (lostPasswordActive) {
      submitLostPassword()
    } else {
      submitLogin()
    }
  }

  return (
    <StyledLoginForm onSubmit={submit}>
      {!lostPasswordActive && (
        <>
          <Input
            minimized
            type="email"
            value={email}
            autoComplete="email"
            onChange={setEmail}
            placeholder={translation.generic.email}
            margin="10px 0"
            required
          />
          <Input
            minimized
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={setPassword}
            placeholder={translation.generic.password}
            required
          />
        </>
      )}
      {lostPasswordActive && (
        <>
          <Input
            minimized
            type="email"
            value={mail}
            onChange={setMail}
            placeholder={translation.generic.mail}
            margin="10px 0"
            required
          />
        </>
      )}
      <Flex wrap="no-wrap" justify={'space-between'} align="center" margin="20px 0 0 0">
        <PasswordLost onClick={() => setLostPasswordActive(!lostPasswordActive)}>
          {lostPasswordActive ? translation.login.backToLogin : translation.login.passwordLost}
        </PasswordLost>
        <Button
          minimized
          type="submit"
          text={lostPasswordActive ? translation.login.ctaPasswordLost : translation.login.cta}
        />
      </Flex>
    </StyledLoginForm>
  )
}

export default LoginForm
