import Layout from '../UI/Layout/Layout'
import { StyledDataProtection, CustomHero, CustomImage } from './DataProtection.Styled'
import heroImage from '../../assets/image/imprint/hero.jpg'
import logo from '../../assets/image/logo.png'
import Container from '../UI/Container/Container'
import Headline from '../UI/Headline/Headline'
import { useSelector } from 'react-redux'
import BreadCrumb from '../BreadCrumb/BreadCrunb'

const DataProtection = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <Layout>
      <CustomHero img={heroImage}>
        <Container>
          <CustomImage src={logo} />
        </Container>
      </CustomHero>
      <StyledDataProtection>
        <Container>
          <BreadCrumb
            items={[{ label: 'Homepage', link: '/' }, { label: 'Datenschutz-Grundverordnung' }]}
          />
          <Headline text={translation.dataProtection.headline} margin="50px 0px" />
          <div className="headline">Der Schutz Ihrer Daten ist unser Anliegen.</div>
          <p>
            Wir freuen uns über Ihr Interesse an unserem Unternehmen und unseren Produkten bzw.
            Dienstleistungen und möchten, dass Sie sich beim Besuch unserer Internetseiten auch
            hinsichtlich des Schutzes Ihrer personenbezogenen Daten sicher fühlen. Denn wir nehmen
            den Schutz Ihrer personenbezogenen Daten sehr ernst. Die Beachtung
            datenschutzrechtlicher Bestimmungen, wie etwa der Datenschutz-Grundverordnung und des
            BDSG-Neu, ist für uns eine Selbstverständlichkeit.
          </p>
        </Container>
      </StyledDataProtection>
    </Layout>
  )
}

export default DataProtection
