import fetch, { METHOD } from '../fetch'

export const getCountCompleted = async (token) => {
  const { status, count } = await fetch(METHOD.GET, '/cart/completed', {
    token,
  })
  if (status && !isNaN(count)) return count
  return false
}

export const getUserCount = async (token) => {
  const { status, count } = await fetch(METHOD.GET, '/user/count', {
    token,
  })
  if (status && !isNaN(count)) return count
  return false
}
