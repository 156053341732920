import { useSelector } from 'react-redux'
import { StyledLists, Title, ListWrapper, List, Name, Comment } from './Lists.Styled'
import { getUUID } from '../../../utility'
import { addItem } from '../../../interface/projectList'
import toast, { TYPE } from '../../../toast'

const Lists = ({ lists, itemToAdd, close, itemsToAdd }) => {
  const translation = useSelector((s) => s.translation)
  const token = useSelector((s) => s.token)

  const save = (uuid) => async () => {
    let status = null
    if (itemToAdd) {
      status = await addItem(token, uuid, itemToAdd)
    }
    if (Array.isArray(itemsToAdd)) {
      for (const item of itemsToAdd) {
        if (status !== false) {
          status = await addItem(token, uuid, item)
        }
      }
    }
    if (status) {
      toast(TYPE.SUCCESS, translation.projectListManager.results.success.addItem)
      close()
    } else {
      toast(TYPE.ERROR, translation.projectListManager.results.error.addItem)
    }
  }

  return (
    <StyledLists>
      <Title>{translation.projectListManager.results.title}</Title>
      <ListWrapper>
        {lists.map(({ uuid, name, comment }) => (
          <List key={getUUID()}>
            <Name onClick={save(uuid)}>{`${name} >>`}</Name>
            <Comment>{comment}</Comment>
          </List>
        ))}
      </ListWrapper>
    </StyledLists>
  )
}

export default Lists
