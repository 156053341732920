import fetch, { METHOD } from '../fetch'

export const listUser = async (token) => {
  const { status, userList } = await fetch(METHOD.GET, '/user/list', {
    token,
  })
  if (status && Array.isArray(userList)) {
    return userList
  }
  return false
}

export const removeUser = async (token, userUUID) => {
  const { status } = await fetch(METHOD.DELETE, '/user', {
    token,
    userUUID,
  })
  return status
}

export const updateUser = async (token, user) => {
  const { status } = await fetch(METHOD.PUT, '/user', {
    token,
    user,
  })
  return status
}
