import styled from 'styled-components'
import Text from '../UI/Text/Text'

export const StyledHome = styled.div``

export const HeroOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  width: 30%;
  height: inherit;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-right: 15px solid ${({ theme }) => theme.color.primary};
`

export const TextWrapper = styled.div`
  padding: 50px;
`

export const HeroHeadline = styled(Text)`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.size.lg};
  text-transform: uppercase;
  font-family: 'bold';
`

export const HeroText = styled(Text)`
  margin-top: 10px;
  color: ${({ theme }) => theme.color.font.secondary};
`

export const SearchWrapper = styled.div`
  width: 70%;
  margin-left: 15%;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    margin-left: 0;
  }
`
