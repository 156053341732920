import styled from 'styled-components'

export const StyledSuggestion = styled.div`
  border-bottom: thin solid ${({ theme }) => theme.color.border};
  padding: 5px;
  cursor: pointer;
  transition: all 0.5s;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

export const ImageWrapper = styled.div`
  height: 60px;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 90%;
  align-items: center;
`

export const Name = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.size.sm};
  color: ${({ theme }) => theme.color.primary};
`

export const Description = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${({ theme }) => theme.size.sm};
  color: ${({ theme }) => theme.color.font.thirdary};
`

export const FlexWrapper = styled.div`
  display: flex;
  gap: 10px;
`
