import { useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../../UI/Button/Button'
import Flex from '../../../UI/Flex/Flex'
import Input from '../../../UI/Input/Input'
import Modal from '../../../UI/Modal/Modal'
import _ from 'lodash'
import { ButtonWrapper, EditPropWrapper, Label, NameWrapper, StyledUser } from './User.Styled'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import { removeUser, updateUser } from '../../../../interface/userManagement'
import { useNavigate } from 'react-router-dom'

const props = [
  'firstname',
  'lastname',
  'company',
  'position',
  'mail',
  'phone',
  'newsletter',
  'isAdmin',
]

const User = ({ user }) => {
  const translation = useSelector((state) => state.translation)
  const token = useSelector((state) => state.token)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [newUser, setNewUser] = useState({})
  const navigate = useNavigate()

  const onClickRemoveUser = async () => {
    const status = await removeUser(token, user.uuid)
    if (status) {
      navigate('/admin')
    }
  }

  const update = (key) => (value) => {
    const newUserCopy = _.cloneDeep(newUser)
    newUserCopy[key] = value
    setNewUser(newUserCopy)
  }

  const onClickSave = async () => {
    const status = await updateUser(token, { ...newUser, uuid: user.uuid })
    if (status) {
      navigate('/admin')
    }
  }

  return (
    <StyledUser>
      <Flex gap="5px" wrap="no-wrap" align="center">
        <NameWrapper>
          <Label>{user.firstname}</Label>
          <Label>{user.lastname}</Label>
        </NameWrapper>
        <NameWrapper>
          <Label>{user.position}</Label>
          <Label>{user.company}</Label>
        </NameWrapper>
        <ButtonWrapper>
          <Button
            text={translation.userManagement.buttons.change}
            margin="0 0 5px 0"
            onClick={() => setShowEditModal(true)}
          />
          <Button
            text={translation.userManagement.buttons.delete}
            onClick={() => setShowRemoveModal(true)}
          />
        </ButtonWrapper>
      </Flex>

      {showEditModal && (
        <Modal
          close={() => setShowEditModal(false)}
          footerContent={
            <Button text={translation.userManagement.editModal.saveButton} onClick={onClickSave} />
          }
        >
          {props.map((key) => (
            <EditPropWrapper>
              <Label>{key}</Label>
              {typeof user[key] === 'boolean' ? (
                <Checkbox checked={newUser[key] || user[key]} onChange={update(key)} />
              ) : (
                <Input value={newUser[key] || user[key]} onChange={update(key)} placeholder={key} />
              )}
            </EditPropWrapper>
          ))}
        </Modal>
      )}

      {showRemoveModal && (
        <Modal
          title={translation.userManagement.deleteModal.title}
          close={() => setShowRemoveModal(false)}
        >
          <Flex gap="10px">
            <Button
              text={translation.userManagement.deleteModal.yesButton}
              onClick={onClickRemoveUser}
            />
            <Button
              text={translation.userManagement.deleteModal.noButton}
              onClick={() => setShowRemoveModal(false)}
            />
          </Flex>
        </Modal>
      )}
    </StyledUser>
  )
}

export default User
