import styled from 'styled-components'
import Text from '../../../UI/Text/Text'

export const StyledSetting = styled.div`
  margin-left: 35px;
`

export const Title = styled(Text)`
  color: ${({ theme }) => theme.color.font.thirdary};
  font-size: ${({ theme }) => theme.size.sm};
  font-family: 'bold';
`

export const OptionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
`

export const Option = styled.div`
  color: ${({ theme }) => theme.color.font.primary};
  padding-left: 25px;
  font-size: ${({ theme }) => theme.size.xsm};
  background-image: ${({ activeImg, inactiveImage, isActive }) =>
    `url(${isActive ? activeImg : inactiveImage})`};
  height: 20px;
  background-size: 20px 20px;
  background-position: left center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
`
