import { useSelector } from 'react-redux'
import { StyledFooter, Column, CustomLink, CustomText } from './Footer.Styled'
import Container from '../../Container/Container'
import Flex from '../../Flex/Flex'

const Footer = () => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)

  return (
    <StyledFooter>
      <Container>
        <Flex>
          <Column>
            <Flex gap="10px" wrap="wrap">
              <CustomLink
                newTab
                href="https://www.bayka.de/impressum/"
                text={translation.footer.imprint}
                size={theme.size.sm}
              />
              <CustomLink
                newTab
                href="https://www.bayka.de/datenschutzerklaerung/"
                text={translation.footer.dataProtection}
                size={theme.size.sm}
              />
              <CustomLink
                newTab
                href="https://www.bayka.de/haftungsausschluss/"
                text={translation.footer.haftungsausschluss}
                size={theme.size.sm}
              />
              <CustomLink
                newTab
                href="https://www.bayka.de/allgemeine-verkaufsbedingungen-avb-fuer-lieferungen-und-leistungen/"
                text={translation.footer.avb}
                size={theme.size.sm}
              />
            </Flex>
          </Column>
          <Column>
            <CustomText text={translation.footer.company} />
            <CustomText text={`${translation.footer.street}`} margin="10px 0 0 0" />
            <CustomText text={`${translation.footer.postcode} ${translation.footer.city}`} />
            <CustomText text={`${translation.footer.postbox}`} margin="10px 0 0 0" />
            <CustomText
              text={`${translation.footer.postboxPostcode} ${translation.footer.postboxCity}`}
            />
            <CustomText
              text={`${translation.footer.phoneLabel} ${translation.footer.phone}`}
              margin="10px 0 0 0"
            />
            <CustomText text={`${translation.footer.faxLabel} ${translation.footer.fax}`} />
            <CustomLink
              newTab
              href={'mailto:' + translation.footer.mail}
              text={`${translation.footer.mailLabel} ${translation.footer.mail}`}
              size={theme.size.sm}
              margin="10px 0 0 0"
            />
          </Column>
          <Column>
            <CustomText text={translation.footer.text} />
          </Column>
        </Flex>
      </Container>
    </StyledFooter>
  )
}

export default Footer
