export const setItemToAdd = (state, action) => {
  const itemToAdd = action.payload

  return { ...state, projectListManager: { ...state.projectListManager, itemToAdd } }
}

export const setItemsToAdd = (state, action) => {
  const itemsToAdd = action.payload

  return { ...state, projectListManager: { ...state.projectListManager, itemsToAdd } }
}
