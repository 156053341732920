import { StyledSetting, Option, OptionWrapper, Title } from './Setting.Styled'
import { getUUID } from '../../../../utility'
import settingActiveImage from '../../../../assets/image/searchList/settings/settingActive.png'
import settingInactiveImage from '../../../../assets/image/searchList/settings/settingInactive.png'

const Setting = ({ state, title, options, callback }) => {
  return (
    <StyledSetting>
      <Title text={title} />
      <OptionWrapper>
        {options.map((o) => (
          <Option
            activeImg={settingActiveImage}
            inactiveImage={settingInactiveImage}
            isActive={state === o.value}
            key={getUUID()}
            onClick={() => callback(o.value)}
          >
            {o.label}
          </Option>
        ))}
      </OptionWrapper>
    </StyledSetting>
  )
}

export default Setting
