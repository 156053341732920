import Separator from "../Separator/Separator";
import { StyledHeadline } from "./Headline.Styled";

const Headline = ({ text, withSeparator, color, className, margin }) => {
  return (
    <StyledHeadline color={color} className={className} margin={margin}>
      {text}
      {withSeparator && <Separator color={color} />}
    </StyledHeadline>
  );
};

export default Headline;
