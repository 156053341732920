//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  home: {
    hero: {
      overlay: {
        headline: 'Navigator Kabel und Muffen',
        text: 'einfache Suche nach Signalkabel und Muffen der Deutschen Bahn AG',
      },
    },
  },

  faq: {
    title: 'FAQ',
    hint: 'Stellen Sie uns hier Ihre Fragen:',
    name: 'Name',
    mail: 'E-Mail Adresse',
    message: 'Ihre Fragen',
    submitCTA: 'Abschicken',
    success: {
      submit: 'Wir haben Ihre Nachricht erhalten.',
    },
    error: {
      submit: 'Beim Versand Ihrer Nachricht ist ein Fehler aufgetreten',
    },
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Datenschutz',
  },

  login: {
    headline: 'Login',
    cta: 'Login',
    ctaPasswordLost: 'Senden',
    passwordLost: 'Passwort vergessen?',
    backToLogin: 'Zurück zum Login',
    switch: 'Zur Registrierung wechseln',
    error: {
      authentication: 'Falscher Benutzername oder Passwort.',
      lostPassword: 'Passwort zurücksetzen fehlgeschlagen.',
    },
    success: {
      lostPassword: 'Wir haben Ihnen eine E-Mail zum zurücksetzen Ihres Passworts geschickt.',
    },
    link: {
      headline: 'Zweck auswählen:',
      god: 'God Modus',
      admin: 'Admin Modus für ',
      abort: '< Zurück',
    },
  },

  passwordLost: {
    cta: 'Speichern',
    headline: 'Neues Passwort festlegen',
    error: {
      submit: 'Ein Fehler ist aufgetreten.',
    },
  },

  signup: {
    cta: 'Registrieren',
    name: 'Name',
    mail: 'E-Mail',
    firstname: 'Vorname',
    lastname: 'Nachname',
    functionWithinCompany: 'Funktion im Unternehmen (Planer, Einkäufer, ...)',
    companyBranch: 'Branche des Unternehmens (Bahn, ÖPNV, ...)',
    enterPasswort: 'Passwort eingeben',
    companyName: 'Name des Unternehmens',
    phone: 'Telefonnummer',
    newsletter: 'Newsletter kostenlos abonnieren',
    error: {
      submit:
        'Ihre Registrierung ist fehlgeschlagen. Diese E-Mail Adresse wird eventuell bereits verwendet.',
      phone: 'Bitte geben Sie eine gültige Telefonnummer ein.',
    },
    success: {
      submit:
        'Erfolgreich registriert! Prüfen Sie bitte Ihren Posteingang, um Ihre Registrierung zu bestätigen.',
    },
    confirm: {
      success: {
        headline: 'Registrierung erfolgreich bestätigt!',
        redirect: 'Zum Login',
      },
      failure: {
        headline: 'Registrierung nicht bestätigt!',
        hint: 'Bei der Aktivierung Ihres Accounts ist ein Fehler aufgetreten.',
      },
    },
  },

  loginOrSignUp: {
    switchToSignUp: 'Zur Registrierung wechseln',
    switchToLogin: 'Zum Login wechseln',
  },

  searchList: {
    noResults: 'Keine Ergebnisse gefunden.',
    noResults2: 'Weitere Signalkabel, Muffen sowie Zubehör auf',
    noResults3: 'Anfrage',
    noResults4: '.',
    filter: {
      cta: 'Anwenden',
      error: {
        attributes: 'Filter konnten nicht geladen werden.',
      },
    },
    error: {
      load: 'Es konnten keine Ergebnisse gefunden werden.',
    },
  },

  articleDetail: {
    selectAderzahl: 'Auswahl der Aderzahl',
    shortDescription: 'Kurzbeschrieb',
    sleeveSectionTitle: 'Passende Muffen und Zubehör nach Lastenheft Deutsche Bahn',
    addToProjectList: 'in Projektliste speichern',
    addToCart: 'Zur Anfrage hinzufügen',
    error: {
      load: 'Der Artikel konnte nicht geladen werden.',
    },
    success: {
      addToCart: 'Artikel zur Anfrageübersicht hinzugefügt.',
    },
  },

  wireView: {
    shortDescription: 'Kurzbeschrieb Signalkabel',
    countInM: 'benötigte Menge in m',
    countInStk: 'benötigte Menge in Stk.',
    submit: 'OK',
    infoOldSleeves: 'Signalkabeltypen vor 2012 können mit den gleichen Muffen verbunden werden!',
    detailLink: 'Produktinformation downloaden',
    wireData: {
      headline: 'Technische Daten Signalkabel',
      generic1: 'Planungsrichtwerte bei 10° C',
      generic2: 'Temperaturbereiche',
      oldVariantTitle: 'Kabelbezeichnung bis 2012:',
      more: 'Weitere Signalkabel des ',
      cta: {
        contact: 'Kontakt',
        baykaplus: 'BaykaPlus',
        faq: 'FAQ',
      },
    },
  },

  sleeveView: {
    shortDescription: 'Kurzbeschrieb Muffe',
    notice: 'HINWEIS:',
    noticeText:
      'Bei Signalkabel mit Induktionsschutz bzw. bei Kabeln mit Schichtenmantel ist weiteres Zubehör notwendig!',
    select: 'Auswahl',
    table: {
      header: {
        articlenr: 'Artikel-Nr.',
        description: 'Bezeichnung',
        countSternvierer: 'Max. Anzahl der Sternvierer',
        countAderverbindungen: 'Max. Anzahl der Aderverbindungen',
        details: 'Details',
        count: 'Menge',
      },
    },
    error: {
      fetch: 'Fehler beim Laden der Muffe',
    },
    success: {
      addToCart: 'Muffe erfolgreich zur Anfrageübersicht hinzugefügt.',
    },
    warning: {
      count: 'Anzahl des Artikels unter 1!',
    },
  },

  evalRedirect: {
    invalid: 'Bitte melden Sie sich zuerst an oder registrieren Sie sich.',
    modal: {
      title: 'Bitte melden Sie sich an oder registrieren Sie sich.',
    },
  },

  header: {
    nav: {
      adminDashboard: 'Admin Dashboard',
      generalInformation: 'Allgemeine Angaben',
      wires: 'Signalkabel',
      wireBuildAndCode: 'Kabelaufbau und Kennzeichnung',
      translationTable: 'Zuordnungstabelle',
      sleeves: 'Kabelmuffen',
      guidelines: 'Richtlinien',
      transportationStorage: 'Transport & Lagerung',
      wireTypes: 'Signalkabeltypen',
      toolsanddownload: 'Tools & Download',
      workshopsandtrainings: 'Workshops und Seminare',
      contact: 'Kontakt',
      faq: 'FAQ',
      cart: 'Anfrageübersicht',
      default: [],
      logout: 'Logout',
      userManagement: 'User Management',
      dashboard: 'Dashboard',
      profile: 'Profil',
      projectLists: 'Projektlisten',
      carts: 'Meine Anfragen',
      search: 'Suche',
    },
  },

  dashboard: {
    labelCompletedCarts: 'Bestellungen abgeschlossen',
    labelCountUser: 'aktive Nutzer',
  },

  cart: {
    headline: 'Meine Anfragen',
    positions: 'Positionen',
    position: 'Position',
    remove: 'Löschen',
    increment: 'Anzahl erhöhen',
    decrement: 'Anzahl verringern',
    count: 'Anzahl Artikel/Set: ',
    submit: 'zur Anfrageübersicht',
    empty: 'Keine Artikel in der Anfrageübersicht vorhanden.',
    blocked: 'Einloggen um die Anfrageübersicht nutzen zu können.',
    id: 'Artikelnummer:',
    error: {
      load: 'Die Anfrageübersicht konnte nicht geladen werden.',
      set: 'Die Anfrageübersicht konnte nicht gespeichert werden.',
    },
  },

  profile: {
    settings: {
      headline: 'Profil-Einstellungen',
      saveCTA: 'Speichern',
      name: 'Name',
      mail: 'E-Mail',
      firstname: 'Vorname',
      lastname: 'Nachname',
      functionWithinCompany: 'Funktion im Unternehmen (Planer, Einkäufer, ...)',
      companyBranch: 'Branche des Unternehmens (Bahn, ÖPNV, ...)',
      enterPasswort: 'Passwort eingeben',
      companyName: 'Name des Unternehmens',
      newsletter: 'Newsletter abonnieren',
      error: {
        submit: 'Fehler beim Speichern Ihrer Daten.',
        get: 'Fehler beim Laden Ihrer Daten.',
      },
      success: {
        submit: 'Erfolgreich gespeichert!',
      },
    },
    projectLists: {
      headline: 'Projektlisten',
      noLists: 'Sie haben noch keine Projektliste angelegt.',
      list: {
        toCart: 'In Anfrage übernehmen',
        show: 'anzeigen',
        delete: 'löschen',
        save: 'speichern',
        abort: 'Abbrechen',
        header: {
          name: 'Bezeichnung',
          count: 'Anzahl',
          delete: 'Löschen',
        },
        item: {
          countWire: 'Meter',
          countSleeve: 'Stück',
          labelWire: 'Signalkabel:',
          labelSleeve: 'Muffenset:',
          labelNumber: 'Artikelnummer Bayka: ',
          labelSAP: 'SAP-Nummer: ',
        },
      },
      error: {
        loading: 'Projektlisten konnten nicht geladen werden.',
      },
    },
    singleProjectList: {
      error: {
        loading: 'Projektliste konnte nicht geladen werden.',
      },
    },
  },

  projectListManager: {
    title: 'Projektlisten',
    newCTA: 'neue Projektliste erstellen',
    noResults: {
      title: 'Keine Projektliste gefunden',
      text: 'Sie können sich beliebig viele Projektlisten erstellen und mit Artikeln befüllen. Am Ende können Sie eine Projektliste zur Anfrage hinzufügen übertragen und als Bestellung aufgeben. Projektlisten können jederzeit wieder aufgerufen und bearbeitet werden. Unter dem Punkt "Mein Konto" können Sie Ihre Projektlisten verwalten.',
    },
    results: {
      title: 'Auf welche Projektliste möchten Sie den Artikel legen?',
      success: {
        addItem: 'Artikel erfolgreich zur Projektliste hinzugefügt.',
        addProjectList: 'Projektliste erfolgreich zur Anfrageübersicht hinzugefügt.',
      },
      error: {
        addItem: 'Artikel konnte nicht zur Projektliste hinzugefügt werden.',
      },
    },
    new: {
      name: 'Name',
      comment: 'Kommentar',
      cancelCTA: 'Abbrechen',
      saveCTA: 'speichern',
      error: {
        create: 'Ihre neue Projektliste konnte nicht angelegt werden.',
      },
    },
    error: {
      loading: 'Inhalte konnten nicht geladen werden.',
    },
  },

  checkout: {
    send: 'Anfrage absenden',
    workshop: 'Workshops/ Seminare anbieten',
    contact: 'Kontakt Vertrieb (Nach PLZ) + Kontakt BayKaPlus',
    wireCount: 'Meter Signalkabel: ',
    sleeveCount: 'Stück Kabelmuffen: ',
    success: {
      send: 'Anfrage wurde erfolgreich gesendet',
    },
    error: {
      send: 'Anfrage konnte nicht verschickt werden',
    },
  },

  genericInfo: {
    headline: 'Allgemeine Angaben',
    menu: [
      [
        { name: 'Signalkabel' },
        { name: 'Kabelaufbau und Kennzeichnung', href: '#wire-buildup-and-code' },
        { name: 'Richtlinien', href: '#guidelines' },
        { name: 'Transport und Lagerung', href: '#transportation-and-storage' },
      ],
      [
        { name: 'Kabelmuffen' },
        { name: 'Allgemeines', href: '#generic' },
        { name: 'Zuordnungstabelle', href: '#mapping-table' },
      ],
    ],
    wire: {
      headline: 'Signalkabel',
    },
    wireBuildupAndCode: {
      headline: 'Kabelaufbau und Kennzeichnung',
      code: {
        title: 'Kabelkennzeichnung',
      },
      testLeads: {
        title: 'Prüfadern',
        text: 'Signalkabel ab 14 Adern, bzw. 7 Sternvierer, besitzen zusätzlich 2 Überwachungsadern zur Nutzung durch ein elektrisches Überwachungssystem (RIso-Überwachung) und zu Messzwecken im Falle eines Wassereinbruchs. Die Überwachungsadern sind für eine Verwendung als Betriebsadern nicht geeignet, eine anderweitige Beschaltung als zu Zwecken der Isolationsüberwachung ist verboten. Sollen Sicherungskabelanlagen auf Ihren Isolationswiderstand hin laufend überwacht werden, so sind ausschließlich Signalkabel mit Überwachungsadern einzusetzen. Im Zuge des zu überwachenden Abschnitts müssen diese Kabel ohne Unterbrechung aneinandergereiht und durchgeschaltet sein.',
      },
      counting: {
        title: 'Zählweise',
        coreStranding: {
          title: 'Adrige Verseilung',
          code: 'Aderkennzeichnung',
          hint: 'Zählader blau, weitere Adern naturfarben',
        },
        layerBuild: {
          title: 'Lagenaufbau',
          hint: 'Zählader blau, weitere Adern naturfarben, hier am Beispiel mit 14 Adern',
        },
        quadrupleStranding: {
          title: 'Viererverseilung',
          hint: 'Beispiel mit 3x4 Adern',
        },
      },
      overviewOfReductionFactorRanges: {
        title: 'Übersicht der Reduktionsfaktorbereiche',
        text1: 'Anmerkungen zum Reduktionsfaktor:',
        text2:
          'Im Hinblick auf unterschiedliche Reichweiten der Kabel sowie wegen unterschiedlicher Beeinflussungsintensitäten stehen zahlreiche Induktionsschutzkabel zur Verfügung. Es kennzeichnen:',
        bulletpoints: [
          'die Kennzahl 600 Reduktionsfaktoren von etwa 0,55 bei etwa 100 V/km',
          'die Kennzahl 500 Reduktionsfaktoren von etwa 0,35 bei etwa 75 V/km',
          'die Kennzahl 400 Reduktionsfaktoren von etwa 0,15 bei etwa 100 V/km.',
        ],
        text3:
          'Der nachfolgend dargestellte Werteverlauf für den Reduktionsfaktor gilt als Planungsrichtwert bei der Frequenz 16,7 Hz. Er stellt einen zuverlässigen Wert aus der Kabelfertigung entsprechend den in den Lastenheften festliegenden Grenzwerten dar. Im Allgemeinen besteht bereits bei der einzelnen Lieferlänge eine ausreichende Sicherheit gegenüber dem Grenzwert. Wo dies ausnahmsweise nicht der Fall ist, kommt der Mittelwert durch die Kettenschaltung mehrerer Lieferlängen zustande.',
      },
    },
    guidelines: {
      headline: 'Richtlinien',
      section1: [
        'Folgende Signalkabel nach Technischen Lastenheft werden in unserem Kabelnavigator berücksichtigt:',
        [
          'Signalkabel nach TL 416.0113 - adrig (H115) und (H145)',
          'Signalkabel nach TL 416.0114 - adrig (H95)',
          'Signalkabel nach TL 416.0115 - Vierer (H45)',
        ],
        'Allgemeine Informationen und Auszüge aus Richtlinien',
        'Zur Erhöhung der Betriebssicherheit werden bei den adrig verseilten Kabeln',
        'weiterentwickelte längs- und querwassergeschützte Kabel-Typen eingesetzt gem. der aktuellen Richtlinien der DB AG.',
        'Unbewehrte Kabel und ungängige Kabeltypen wurden 2012 aus dem Portfolio gestrichen. Kabelgrößen die bis dahin nur unbewehrt ausgeführt wurden, werden seit 2012 bewehrt ausgeführt.',
        'Für die Planung und für die Verwendung der Signalkabel gilt die technische Mitteilung TM 2011-211 I.NVT 3.',
        'Abmessungen, Gewichte und technische Daten sind Nennwerte aus dem Technischen Kennblatt TL 416.0116. Zugbelastung gültig nur bei Beteiligung Kabelmantel und Kabelseele. Biegedurchmesser mehrm. Biegen zugleich Durchmesser von Umlenkmechanismen.',
      ],
      section2: [
        'Auszug aus der Richtlinie 892.9222:',
        'Die nachfolgenden Angaben sind ein Auszug aus der Richtlinie 892.9222',
        '(LST-Anlagen montieren und instandhalten).',
        [
          'Auf eine ordnungsgmäße Endenverkappung (Schrumpfkappe) zum Schutz vor Eindringen von Feuchtigkeit des gelegten und noch nicht montierten Kabels ist zu achten.',
          'Die unverkappten Kabelenden dürfen keiner direkten Feuchtigkeit (Regen, Pfützen und dgl.) ausgesetzt werden.',
          'Beim Verlegen und Einziehen der Kabel sind Hilfsmittel wie Kabelschutzbleche, Gleitrollen, Umlenkrollen und Kabelschleifbögen zu verwenden.',
          'Die zugelassenen kleinsten Biegeradien und der zulässige Verlegetemperaturbereich sind zu beachten.',
          'Verdrehungen der Kabel sind zu vermeiden.',
          'Werden Kabel mit einem Ziehstrumpf eingezogen, so ist dafür zu sorgen, dass die Zugkräfte von den Adern und dem Mantel aufgenommen werden (Kraftschluss zwischen Mantel und Adern).',
        ],
      ],
      section3: [
        'Auszug aus der Richtlinie 892.9222:',
        'Die nachfolgenden Angaben sind ein Auszug aus der Richtlinie 892.2102 (LST-Anlagen planen).',
        [
          'Die Signalkabel der Bauart Erdkabel stehen in den Ausführungen',
          'mit Nagetierschutz (Bewehrung) oder',
          'mit Induktionsschutz in 3 Wertegruppen des Reduktionsfaktors zur Verfügung.',
          'Die Reduktionsfaktorwertegruppen (dreistellige Kennzahlen) gliedern sich wie folgt: Induktionsschutz rk-Kennzahl',
          'mittel 600',
          'gut 500',
          'sehr gut 400',
          'Aufbaubestimmender Werkstoff der Aderisolierungen, des Mantels und der äußeren Schutzhülle ist Polyethylen (PE = 2Y), d. h. die Kabel sind völlig PVC-frei.',
          'Die Nagetierschutzbewehrung hat grundsätzlich keine elektrische Funktion (keine Schirmeigenschaft). Es ist deshalb keine Erdung vorzunehmen. Abweichungen hiervon sind in technisch begründeten Fällen jedoch zulässig.',
          'Der statische Schirm besteht aus einer Aluminiumfolie als (unlösbarer) Verbundbestandteil des Schichtenmantels und einem Beidraht.',
          'Der Induktionsschutzaufbau ist einheitlich, d. h. über dem Mantel befindet sich eine Lage Kupferdrähte (konzentrischer Schirm)',
        ],
      ],
    },
    transportationAndStorage: {
      headline: 'Transport und Lagerung',
      section: [
        'Beim Umgang mit Kabeln ist besondere Sorgfalt notwendig. Unzulässig hohe Zug-, Druck- und Torsionsbeanspruchungen sind zu vermeiden.',
        [
          'Trommeln mit einem Durchmesser über 1 m stehend lagern und transportieren.',
          'Trommeln nicht stapeln.',
          'Nur geeignete Fahrzeuge verwenden, z. B. LKW mit Kran oder Kabeltransportanhänger mit Hubeinrichtung.',
          'Trommeln beim Transport sichern. Sicherung nicht über die Kabel führen.',
          'Nur geeignete Ladehilfsmittel verwenden, z. B. Welle oder spezielle Greifhaken. Gewicht beachten.',
          'Das Abwerfen der Kabeltrommeln ist unzulässig.',
          'Beim Abladen und Lagern der Spulen auf festen Untergrund achten und gegen Wegrollen sichern.',
          'Kabel nur über kurze Strecken rollen. Rollrichtung beachten (Pfeil auf dem Trommelflansch).',
          'Beim Umspulen auf andere Trommeln die zulässigen Biegeradien nicht unterschreiten (Flanschdurchmesser).',
          'Beim Umspulen Wickelrichtung der Trommeln beachten.',
        ],
      ],
    },
    sleeves: {
      headline: 'Kabelmuffen',
      generic: {
        title: 'Allgemeines',
        section: [
          [
            'Zur Herstellung von Verbindungsmuffen für Signalkabel nach DB-Lastenheft 416.0113 bis 0116 sind DB-freigegebene faserverstärkte Verbindungsschrumpfmuffen SVSM zu verwenden.',
            'Auswahl und Zuordnung der Muffengrößen zu den Signalkabeltypen und Kabeldimensionen einschließlich zu berücksichtigender Absetz- und Montagemaße sowie weitere spezielle Anforderungen am Montageort, z.B. bei beeinflussten Kabelanlagen, werden in Richtlinien der Deutschen Bahn geregelt.',
            'Die SVSM kann an Signalkabeln mit Kabelmantelaufbau gemäß nachstehender Tabelle montiert werden. Die Montageabläufe werden unterschieden in:',
            'Montage von Signalkabeln ohne Induktionsschutz (ohne und mit Nagetierschutz-Bewehrung)',
            'Montage von Signalkabeln mit konzentrischem Schirm/Induktionsschutz-Bewehrung',
          ],
        ],
        text: 'Eine detaillierte Montageanweisung liegt jeder Muffe bei. Diese Montageanweisung beschreibt allgemeine Arbeitsschritte zur Vorbereitung und Herstellung von Verbindungsschrumpfmuffen SVSM für Signalkabel. Sie enthält Hinweise zur Verwendung spezieller Werkzeuge, zur Anwendung von Zubehör für Adernspleiße, Bausätze für die lötfreie Kontaktierung (LK) der konzentrischen Schirme/Induktionsschutz-Bewehrungen sowie der Schirmverbindungsleitung.',
      },
      svsm: {
        title: 'SVSM',
        section: [
          'Verbindungsschrumpfmuffe für Signalkabel, Bahnanwendung',
          [
            'Hohe mechanische Festigkeit durch faserverstärkte Schrumpfmanschette',
            'Installation auf allen Kabeltypen möglich',
            'Indikatorlinien für korrekte Installation',
            'Einfache und sichere Montage ohne Zusatzwerkzeuge auch bei Ovalitäten Kabel',
            'Hohe Temperaturbeständigkeit erlaubt das Schrumpfen mit harter Flamme bei ungünstigen Witterungsverhältnissen',
            'Unempfindlich gegen Bewegung und Vibrationen',
            'Hohe Schrumpfkraft ohne Längsschrumpf (kein Dichtlängenverlust)',
            'Integrierte Wasserdampfsperre',
          ],
        ],
      },
      vass: {
        title: 'VASS',
        section: [
          'Faserverstärkte Verbindungs- und Abzweigschrumpfschutzmuffe - Schrumpfschutzmuffe für Bahn- und Industrie Anwendungen',
          [
            'Hohe mechanische Festigkeit durch faserverstärkte Schrumpfmanschette und Metallschutzeinlage',
            'Installation auf allen Kabeltypen möglich',
            'Indikatorlinien für korrekte Installation',
            'Einfache und sichere Montage ohne Zusatzwerkzeuge auch bei Ovalitäten Kabel',
            'Hohe Temperaturbeständigkeit erlaubt das Schrumpfen mit harter Flamme bei ungünstigen Witterungsverhältnissen',
            'Hohe Schrumpfkraft ohne Längsschrumpf (kein Dichtlängenverlust)',
            'Die Muffe lässt sich bei Bedarf wieder öffnen',
          ],
        ],
      },
      vasssvsm: {
        headline: 'VASS Zuordnungstabelle für SVSM Muffen',
        title1: 'Muffenmaße SVSM für A-2Y2YV, A-2Y(L)2YV',
        title2: 'Muffenmaße SVSM für A-2Y2YB2Y, A-2YOF(L)2YB2Y, A-2Y(L)2YB2Y',
        title3: 'Muffenmaße SVSM für AJ-2Y2YDB2Y, AJ-2YOF(L)2YDB2Y, AJ-2Y(L)2YDB2Y',
        title4: 'Größenbezeichnungen',
        title7: 'Zubehör - Nicht im Lieferumfang enthalten!',
      },
    },
  },

  toolsAndDownloads: {
    headline: 'Tools & Downloads',
    toolsSubheadline: 'Tools',
    toolCTAs: [
      {
        title: 'Auswahlhilfe Fernmeldekabel',
        href: 'https://www.bayka.de/servicebereich/hilfsmittel-und-tools/auswahlhilfe-fernmeldekabel/',
        text: 'Sie planen Instandsetzung von Strecken/Teilstrecken oder neue Anlagen nach TL 416.0530 der Deutschen Bahn AG? Dann hilft Ihnen unser Auswahlhilfe-Tool die passenden Kombinationen zu finden …',
      },
      {
        title: 'KTG-Freimeldungs-App',
        href: 'https://www.bayka.de/servicebereich/logistik/#TrommelApp',
        text: 'KTG-Freimeldungs-App Die Anwendung dient dazu, nicht mehr benötigte Leihtrommeln und –spulen einfach und schnell zur Abholung an die KTG frei zu melden. Es ist möglich die Spulennummer zu scannen oder manuell einzugeben …',
      },
      {
        title: 'DoP (Declaration of Performance)',
        href: 'https://www.bayka.de/dop-abfrage/',
        text: 'DoP (Declaration of Performance) Die Leistungserklärungen können mit Hilfe der Referenznummer eingesehen werden, die auf Lieferschein, Rechnung und Trommeletikett zu finden ist …',
      },
      {
        title: 'Technische Informationen',
        href: 'https://www.bayka.de/servicebereich/technische_daten/',
        text: 'Technische Informationen In diesem Bereich finden Sie allgemeine Informationen, technische Tabellen zu elektrischen und mechanischen Eigenschaften, Werkstofftabellen, Kabelkurzzeichen und weitere Details …',
      },
      {
        title: 'Themenparks',
        href: 'https://www.bayka.de/branchen/themenpark/',
        text: 'Themenparks In den Themenparks werden spezielle Themengebiete beschrieben, deren Details betrachtet und teilweise Herausforderung und Lösung erörtert …',
      },
      {
        title: 'FAQ',
        href: 'https://www.bayka.de/servicebereich/faq/',
        text: 'FAQ Häufig gestellte Fragen zum Thema Kabeln, Verlegung, Dokumenten, Bauprodukteverordnung und vielem mehr. Die Antworten finden Sie …',
      },
    ],
    downloadsSubheadline: 'Downloads',
    downloadCTAs: [
      {
        title: 'Fachbeiträge & Sonderbroschüren',
        href: 'https://www.bayka.de/servicebereich/downloadcenter/fachbeitraege-sonderbroschueren-flyer-und-white-paper/',
        text: 'Fachbeiträge & Sonderbroschüren Dort finden Sie verschiedene WhitePaper und Dokumente rund ums Kabel, die Bauprodukteverordnung, Brandlasten …',
      },
      {
        title: 'Imagebroschüre Produkte & Unternehmen',
        href: 'https://www.bayka.de/wp-content/uploads/2020/09/Unternehmensbroschuere_DE.pdf',
        text: 'Imagebroschüre Produkte & Unternehmen Wer ist die Bayka? Mehr Hintergründe zur Geschichte, zu den Märkten und den Produkten der Bayka …',
      },
      {
        title: 'Katalog und Flyer',
        href: 'https://www.bayka.de/servicebereich/downloadcenter/download-kataloge/',
        text: 'Katalog und Flyer Hier finden Sie die Katalog und Flyer sortiert nach Märkten und Einsatzbereichen, zur besseren Übersicht …',
      },
    ],
  },

  workshopsAndTrainings: {
    headline: 'Workshops & Seminare',
    intro: {
      split: {
        headline: 'Workshop Signalkabel und Muffen',
        subheadline:
          'Muffenmontage an Signalkabeln, für angehende Monteure und Installateure, deren Einsatz die Montage im Bereich der Leit- und Sicherungstechnik erfordert.',
        bulletpoints: [
          '- Aufbau und Materialien von Signalkabeln',
          '- Aderanzahl und Zuordnung der Adern',
          '- Unterschiede im Aufbau von Vierer und Adrig verseilten Signalkabeln',
          '- Kabelverlegung & Transport',
          '- Praktische Grundlagen an Signalkabeln:',
          '- Montage von Endkappen',
          '- Montage von Reparaturmanschetten',
          '- Montage von Signalkabeln mit Induktionsschutzbewehrung',
          '- Montage von Signalkabeln ohne Induktionsschutzbewehrung',
          '- inkl. Teilnahme-Zertifikat',
        ],
        cta: 'Jetzt anfragen',
      },
    },
    overview: {
      title: 'Übersicht der Workshops:',
      links: [
        {
          href: 'https://www.baykaplus.de/product/111659012',
          text: '> Grundkurs Kunststoffisolierte Niederspannungskabel und Muffen',
        },
        {
          href: 'https://www.baykaplus.de/product/111659010',
          text: '> Cu-Fernmeldekabel und Muffen',
        },
        {
          href: 'https://www.baykaplus.de/product/111659011',
          text: '> Presstechnik für Nieder- und Mittelspannung',
        },
      ],
    },
    peopleForContact: {
      title: 'Sie haben konkrete Fragen zu Terminen oder Inhalten, dann melden Sie sich bei uns:',
    },
  },

  contact: {
    mail: 'E-Mail Adresse',
    message: 'Nachricht',
    send: 'Senden',
    success: 'Erfolgreich abgesendet',
    failed: 'Ein fehler ist beim Senden aufgetreten',
    sections: [
      {
        title: 'Vertrieb Baykaplus',
        people: [
          {
            name: 'Benedikt Herzog',
            area: 'Vertrieb Kabelgarnituren & Zubehör',
            phone: '+49 (0) 9171 – 806 283',
            mobile: '+49 (0) 171 – 654 14 20',
            fax: '+49 (0) 9171 – 806 222',
            mail: 'herzog.b@bayka.de',
          },
          {
            name: 'Moritz Brechtelsbauer',
            area: 'Vertrieb Kabelgarnituren & Zubehör',
            phone: '+49 (0) 9171 – 806 161',
            fax: '+49 (0) 9171 – 806 222',
            mail: 'brechtelsbauer.m@bayka.de',
          },
          // {
          //   name: 'Philipp Großbeck',
          //   area: 'Einkauf Handelsware',
          //   phone: '+49 (0) 9171 – 806 151',
          //   fax: '+49 (0) 9171 – 806 222',
          //   mail: 'grossbeck.p@bayka.de',
          // },
        ],
      },
      {
        title: 'Vertrieb Nord',
        postcodeAreas: '18-33, 37-38, 49',
        people: [
          {
            name: 'Jürgen Turowski',
            area: 'Vertrieb Nord',
            mobile: '+49 (0) 175 – 583 94 91',
            fax: '+49 (0) 9171 – 806 127',
            mail: 'turowski.j@bayka.de',
          },
          {
            name: 'Filippo Virduzzo',
            area: 'Innendienst Vertrieb Nord',
            postcodeArea: '18-32, 49',
            phone: '+49 (0) 9171 – 806 227',
            fax: '+49 (0) 9171 – 806 127',
            mail: 'virduzzo.f@bayka.de',
          },
          {
            name: 'Stella Singh',
            area: 'Innendienst Vertrieb Nord',
            postcodeArea: '33, 37, 38',
            phone: '+49 (0) 09171 – 806 290',
            fax: '+49 (0) 9171 – 806 255',
            mail: 'singh.s@bayka.de',
          },
        ],
      },
      {
        title: 'Vertrieb Ost',
        postcodeAreas: '01-17, 39, 98, 99',
        people: [
          {
            name: 'Franz Kabis',
            area: 'Vertrieb Ost',
            mobile: '+49 (0) 175 – 583 94 97',
            fax: '+49 (0) 9171 – 806 255',
            mail: 'kabis.f@bayka.de',
          },
          {
            name: 'Andrea Pollmann',
            area: 'Innendienst Vertrieb Ost',
            postcodeArea: '01-17, 39',
            phone: '+49 (0) 9171 – 806 133',
            fax: '+49 (0) 9171 – 806 255',
            mail: 'pollmann.a@bayka.de',
          },
          {
            name: 'Stella Singh',
            area: 'Innendienst Vertrieb Ost',
            postcodeArea: '98, 99',
            phone: '+49 (0) 09171 – 806 290',
            fax: '+49 (0) 9171 – 806 255',
            mail: 'singh.s@bayka.de',
          },
        ],
      },
      {
        title: 'Vertrieb West & Benelux',
        postcodeAreas: '34-36, 40-48, 50-61, 65',
        people: [
          {
            name: 'Andreas Ottersbach',
            area: 'Vertrieb West & Benelux',
            mobile: '+49 (0) 151 – 122 387 28',
            fax: '+49 (0) 9171 – 806 104',
            mail: 'ottersbach.a@bayka.de',
          },
          {
            name: 'Karin Zimmermann',
            area: 'Innendienst Vertrieb West & Benelux',
            postcodeArea: '40-48, 50-59, Benelux',
            phone: '+49 (0) 9171 – 806 204',
            fax: '+49 (0) 9171 – 806 104',
            mail: 'zimmermann.k@bayka.de',
          },
          {
            name: 'Stella Singh',
            area: 'Innendienst Vertrieb West',
            postcodeArea: '34-36, 60, 61, 65',
            phone: '+49 (0) 09171 – 806 290',
            fax: '+49 (0) 9171 – 806 255',
            mail: 'singh.s@bayka.de',
          },
        ],
      },
      {
        title: 'Vertrieb Südwest',
        postcodeAreas: '63-64, 66-79, 88, 89, 97',
        people: [
          {
            name: 'Eduard Neukamm',
            area: 'Vertrieb Südwest',
            mobile: '+49 (0) 175 – 221 63 48',
            fax: '+49 (0) 9171 – 806 210',
            mail: 'neukamm.e@bayka.de',
          },
          {
            name: 'Michelle Glungler',
            area: 'Innendienst Vertrieb Südwest',
            postcodeArea: '66-79, 88, 89',
            phone: '+49 (0) 9171 – 806 110',
            fax: '+49(0) 9171 – 806 210',
            mail: 'glungler.m@bayka.de',
          },
          {
            name: 'Stella Singh',
            area: 'Innendienst Vertrieb Südwest',
            postcodeArea: '63, 64, 97',
            phone: '+49 (0) 09171 – 806 290',
            fax: '+49 (0) 9171 – 806 255',
            mail: 'singh.s@bayka.de',
          },
        ],
      },
      {
        title: 'Vertrieb Südost & Österreich',
        postcodeAreas: '80-87, 90-96',
        people: [
          {
            name: 'Michael Winkler',
            area: 'Vertrieb Südost & Österreich',
            mobile: '+49 (0) 171 – 861 58 30',
            fax: '+49 (0) 9171 – 806 114',
            mail: 'winkler.m@bayka.de',
          },
          {
            name: 'Christina Trost',
            area: 'Innendienst Vertrieb Südost & Österreich',
            phone: '+49 (0) 9171 – 806 230',
            fax: '+49 (0) 9171 – 806 114',
            mail: 'trost.c@bayka.de',
          },
          {
            name: 'Inge Köpplinger',
            area: 'Innendienst Vertrieb Regional',
            phone: '+49 (0) 9171 – 806 141',
            fax: '+49 (0) 9171 – 806 114',
            mail: 'koepplinger.i@bayka.de',
          },
        ],
      },
      {
        title: 'Vertrieb Schweiz',
        people: [
          {
            name: 'Tobias Biegerl',
            area: 'Vertrieb Schweiz',
            phone: '+49 (0) 9171 – 806 146',
            fax: '+49 (0) 9171 – 806 114',
            mobile: '+49 (0) 151 – 270 241 24',
            mail: 'biegerl.t@bayka.de',
          },
          {
            name: 'Dominik Hosseini',
            area: 'Innendienst Vertrieb',
            phone: '+49 (0) 9171 – 806 180',
            fax: '+49 (0) 9171 – 806 222',
            mail: 'hosseini.d@bayka.de',
          },
          // {
          //   name: 'Annika Svoboda',
          //   area: 'Innendienst Vertrieb Schweiz',
          //   phone: '+49 (0) 9171 – 806 164',
          //   fax: '+49 (0) 9171 – 806 222',
          //   mail: 'svoboda.a@bayka.de',
          // },
        ],
      },
      {
        title: 'Vertrieb International',
        people: [
          {
            name: 'Holger Schäfer',
            area: 'Vertrieb International',
            phone: '+49 (0) 171 – 818 61 28',
            fax: '+49 (0) 9171 – 806 220',
            mail: 'schaefer.h@bayka.de',
          },
          {
            name: 'Elke Krafft',
            area: 'Innendienst Vertrieb International',
            phone: '+49 (0) 9171 – 806 228',
            fax: '+49 (0) 9171 – 806 220',
            mail: 'krafft.e@bayka.de',
          },
          {
            name: 'Olga Simin',
            area: 'Innendienst Vertrieb International',
            phone: '+49 (0) 9171 – 806 120',
            fax: '+49 (0) 9171 – 806 220',
            mail: 'simin.o@bayka.de',
          },
        ],
      },
      {
        title: 'Vertrieb Back Office',
        people: [
          {
            name: 'Michaela Huber',
            area: 'Innendienst Vertrieb',
            phone: '+49 (0) 9171 – 806 212',
            fax: '+49 (0) 9171 – 806 222',
            mail: 'huber.m@bayka.de',
          },
          {
            name: 'Daniela Fackelmeier',
            area: 'Innendienst Vertrieb',
            phone: '+49 (0) 9171 – 806 278',
            fax: '+49 (0) 9171 – 806 222',
            mail: 'fackelmeier.d@bayka.de',
          },
          {
            name: 'Maximilian Schwarz',
            area: 'Innendienst Vertrieb',
            phone: '+49 (0) 9171 – 806 205',
            fax: '+49 (0) 9171 – 806 222',
            mail: 'schwarz.m@bayka.de',
          },
        ],
      },
      {
        title: 'Vertriebsleitung',
        people: [
          {
            name: 'Johannes Köbler',
            area: 'Leiter Vertrieb',
            phone: '+49 (0) 9171 – 806 208',
            mobile: '+49 (0) 175 – 583 94 93',
            fax: '+49 (0) 9171 – 806 222',
            mail: 'koebler.j@bayka.de',
          },
          {
            name: 'René Meng',
            area: 'Leiter Innendienst Vertrieb',
            phone: '+49 (0) 9171 – 806 131',
            mobile: '+49 (0) 151 – 233 800 87',
            fax: '+49 (0) 9171 – 806 131',
            mail: 'meng.r@bayka.de',
          },
        ],
      },
      {
        title: 'Geschäftsleitung',
        people: [
          {
            name: 'Thomas Schrimpff',
            area: 'operative Geschäftsleitung kaufmännische Leitung und Vertrieb/Prokurist',
            mail: 'schrimpff.t@bayka.de',
          },
          {
            name: 'Bernd Platzöder',
            area: 'operative Geschäftsleitung technische Leitung und Personal',
            mail: 'platzoeder.b@bayka.de',
          },
        ],
      },
      {
        title: 'Marketing und Produktmanagement',
        people: [
          {
            name: 'Thomas Sorge',
            area: 'Leiter Marketing / PR & Produktmanagement',
            phone: '+49 (0) 9171 – 806 206',
            fax: '+49 (0) 9171 – 806 186',
            mail: 'sorge.t@bayka.de',
          },
          {
            name: 'Susan Flemming',
            area: 'Projektmanagement/Kommunikationsdesign',
            phone: '+49 (0) 9171 – 806 275',
            fax: '+49 (0) 9171 – 806 186',
            mail: 'flemming.s@bayka.de',
          },
          {
            name: 'David Raum',
            area: 'Produktmanagement',
            phone: '+49 (0) 9171 – 806 281',
            fax: '+49 (0) 9171 – 806 186',
            mail: 'raum.d@bayka.de',
          },
          {
            name: 'Tobias Fenzel',
            area: 'Messe & Eventcoordinator / Social Media',
            phone: '+49 (0) 9171 – 806 188',
            fax: '+49 (0) 9171 – 806 186',
            mail: 'fenzel.t@bayka.de',
          },
        ],
      },
    ],
  },

  userManagement: {
    buttons: {
      change: 'ändern',
      delete: 'löschen',
    },
    editModal: {
      saveButton: 'Speichern',
    },
    deleteModal: {
      title: 'Benutzer löschen?',
      yesButton: 'Ja',
      noButton: 'Nein',
    },
  },

  mail: {
    confirmation: {
      subject: 'E-Mail Adresse bestätigen',
    },
    notification: {
      subject: 'Ein neuer Nutzer hat sich registriert!',
    },
  },

  wire: {
    label: 'Signalkabel',
    filter: {
      betriebskapazitaet: 'Betriebskapazität',
      verseilung: 'Verseilung',
      tl: 'Technisches Lastenheft (TL)',
      reduktionsfaktor: 'Reduktionsfaktor rK',
    },

    name: 'Name',
    sapmaterialnummerdb: 'SAP-Materialnummer DB',
    auendurchmesserinmm: 'Außendurchmesser in mm',
    anzahlverseilelemente: 'Anzahl Verseilelemente',
    verseilelement: 'Verseilelement',
    aderzahl: 'Aderzahl',
    leiterdurchmesserinmm: 'Leiterdurchmesser in mm',
    artikelnummerbayka: 'Artikelnummer Bayka',
    cuzahlinkgkm: 'Cu-Zahl in kg/km',
    nettogewichtinkgkm: 'Nettogewicht in kg/km',
    durchmesserbermantelinmm: 'Durchmesser über Mantel in mm',
    minbiegeradiuseinminmm: 'min. Biegeradius einm. in mm',
    minbiegeradiusmehrminmm: 'min. Biegeradius mehrm. in mm',
    maxzugbelastunginn: 'max. Zugbelastung in N',
    lastenheft: 'Lastenheft',
    leiterwiderstanddereinzeladerinkm: 'Leiterwiderstand der Einzelader in Ω/km',
    isolationswiderstandingxkm: 'Isolationswiderstand in GΩ x km',
    betriebskapazittinnfkmbei800hz: 'Betriebskapazität in nF/km (bei 800Hz)',
    temperaturlegenundmontierenincmin: 'Temperatur legen und montieren in °C [min]',
    temperaturlegenundmontierenincmax: 'Temperatur legen und montieren in °C [max]',
    temperaturtransportlagerungundbetriebincmin:
      'Temperatur Transport, Lagerung und Betrieb in °C [min]',
    temperaturtransportlagerungundbetriebincmax:
      'Temperatur Transport, Lagerung und Betrieb in °C [max]',
    durchmesserberbewehrunginmm: 'Durchmesser über Bewehrung in mm',
    rkkennzahl: 'rK-Kennzahl',
    leiterwiderstanddesadernpaarsinkmbei10c: 'Leiterwiderstand des Adernpaars in Ω/km (bei 10°C)',
    leiterwiderstanddereinzeladerinkmbei10c: 'Leiterwiderstand der Einzelader in Ω/km (bei 10°C)',
    isinner: 'isInner',
    isouter: 'isOuter',

    leiterdurchmesser: 'Leiterdurchmesser',
    neualt: 'neu_alt',
    ersetztdurchartikelnummerbayka: 'ersetzt durch Artikelnummer Bayka',
    kurzzeichen: 'Kurzzeichen',
    kurzzeichenalternativ: 'Kurzzeichen alternativ',
    anzahlverseilelementekabel: 'Anzahl Verseilelemente Kabel',

    pairindex: 'pairIndex',
    vaterid: 'Vater-ID',
  },
  sleeve: {
    label: 'Muffe',
    filter: {
      innenmuffe: 'Verbindungsmuffe',
      muffe: 'Muffe',
      tl: 'Technisches Lastenheft (TL)',
    },
    TITYPN: 'Typennummer',
    name: 'Name',
    auendurchmesserinmm: 'Außendurchmesser in mm',
    durchmesserbermantelinmm: 'Durchmesser über Mantel in mm',
    innenmuffe: 'Verbindungsmuffe',
    mengeinnenmuffe: 'Menge Verbindungsmuffe',
    quetschverbinder: 'Quetschverbinder',
    mengequetschverbinder: 'Menge Quetschverbinder',
    schirmverbindungsleitung: 'Schirmverbindungsleitung',
    mengeschirmverbindungsleitung: 'Menge Schirmverbindungsleitung',
    durchmesserberbewehrunginmm: 'Durchmesser über Bewehrung in mm',
    rkkennzahl: 'rK-Kennzahl',
    schutzmuffe: 'Schutzmuffe',
    mengeschutzmuffe: 'Menge Schutzmuffe',
    bausatz: 'Bausatz',
    mengebausatz: 'Menge Bausatz',
    erdseil: 'Erdseil',
    mengeerdseil: 'Menge Erdseil',
    schrumpfschlauch: 'Schrumpfschlauch',
    mengeschrumpfschlauch: 'Menge Schrumpfschlauch',
    thermoplastischesabdichtband: 'Thermoplastisches Abdichtband',
    mengethermoplastischesabdichtband: 'Menge Thermoplastisches Abdichtband',

    pairindex: 'pairIndex',
  },

  footer: {
    imprint: 'Impressum',
    dataProtection: 'Datenschutz',
    haftungsausschluss: 'Haftungsausschluss',
    avb: 'AVB',
    company: 'BAYERISCHE KABELWERKE AG',
    street: 'Otto-Schrimpff-Straße 2',
    postcode: 'D – 91154',
    city: 'Roth',
    postbox: 'Postfach 1153',
    postboxPostcode: 'D – 91152',
    postboxCity: 'Roth',
    phoneLabel: 'Tel:',
    phone: '+49 (0) 09171/806-111',
    faxLabel: 'Fax:',
    fax: '+49 (0) 09171/806-222',
    mailLabel: 'E-Mail:',
    mail: 'kabel@bayka.de',
    text: 'Copyright © 2022 BAYERISCHE KABELWERKE AG. Alle Rechte vorbehalten.',
  },

  generic: {
    firstname: 'Vorname',
    lastname: 'Nachname',
    company: 'Firma',
    position: 'Position',
    mail: 'E-Mail: ',
    phone: 'Tel: ',
    postcodeArea: 'PLZ-Gebiete: ',
    postcode: 'PLZ: ',
    mobile: 'Mobil: ',
    fax: 'Fax: ',
    username: 'Benutzername',
    email: 'E-Mail Adresse',
    password: 'Password',
    info: {
      street: '',
      postcode: '',
      city: '',
    },
  },

  UI: {
    autocomplete: {
      searchError: 'Suche konnte nicht ausgeführt werden.',
      searchEmpty: 'Keine Ergebnisse',
    },
    dropdown: {
      emptySelection: '--- bitte wählen ---',
    },
  },
}

module.exports = {
  translation,
  langKey: 'DE',
}
