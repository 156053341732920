const getWireLabel = (wire, respectSibling = true, childLabel = false) => {
  let label = `${respectSibling ? `BayRail® ${wire.name}` : ''} ${
    childLabel ? wire.anzahlverseilelemente : '...'
  }x${wire.verseilelement}x${wire.leiterdurchmesser || wire.leiterdurchmesserinmm}
      ${
        wire.betriebskapazittinnfkmbei800hz
          ? `(H${wire.betriebskapazittinnfkmbei800hz.split(' ')[1]})`
          : ''
      }
      `
  if (wire.sibling && respectSibling) {
    label += ` + ${getWireLabel(wire.sibling, false, childLabel)}`
  }
  return label
}

export default getWireLabel
