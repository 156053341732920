import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { list, remove as removeList } from '../../../interface/projectList'
import Layout from '../../UI/Layout/Layout'
import {
  StyledProjectLists,
  Lists,
  Headline,
  NoLists,
  HeroOverlay,
  TextWrapper,
  HeroHeadline,
  HeroText,
  SearchWrapper,
} from './ProjectLists.Styled'
import toast, { TYPE } from '../../../toast'
import { getUUID } from '../../../utility'
import Container from '../../UI/Container/Container'
import List from './List/List'
import Hero from '../../UI/Hero/Hero'
import heroImage from '../../../assets/image/home/hero.jpg'
import Search from '../../Search/Search'

const ProjectLists = () => {
  const token = useSelector((s) => s.token)
  const translation = useSelector((s) => s.translation)
  const [lists, setLists] = useState(null)

  useEffect(() => {
    const fetchLists = async () => {
      const lists = await list(token)
      if (lists) {
        setLists(lists)
      } else {
        toast(TYPE.ERROR, translation.profile.projectLists.error.loading)
      }
    }

    if (token && !lists) {
      fetchLists()
    }
  }, [token, lists, translation])

  const remove = (uuid) => async () => {
    const response = await removeList(token, uuid)
    if (response) {
      setLists(null)
    }
  }

  return (
    <Layout>
      <StyledProjectLists>
        <Hero img={heroImage}>
          <HeroOverlay>
            <TextWrapper>
              <HeroHeadline text={translation.home.hero.overlay.headline} />
              <HeroText text={translation.home.hero.overlay.text} />
            </TextWrapper>
          </HeroOverlay>
        </Hero>

        <Container>
          <SearchWrapper>
            <Search />
          </SearchWrapper>
          <Headline>{translation.profile.projectLists.headline}</Headline>
          {Array.isArray(lists) && lists.length > 0 ? (
            <Lists>
              {lists.map((l) => (
                <List key={getUUID()} data={l} remove={remove} />
              ))}
            </Lists>
          ) : (
            <NoLists>{translation.profile.projectLists.noLists}</NoLists>
          )}
        </Container>
      </StyledProjectLists>
    </Layout>
  )
}

export default ProjectLists
