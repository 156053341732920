import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Image from '../../../UI/Image/Image'
import Modal from '../../../UI/Modal/Modal'
import Dropdown from '../../../UI/Dropdown/Dropdown'
import {
  StyledWireView,
  Header,
  ImageWrapper,
  HeaderContent,
  ReselectAderzahl,
  Selected,
  Details,
  Column,
  CustomFlex,
  CustomImage,
  ButtonCountWrapper,
} from './WireView.Styled'
import Container from '../../../UI/Container/Container'
import Search from '../../../Search/Search'
import Text from '../../../UI/Text/Text'
import Button from '../../../UI/Button/Button'
import { addCartItem } from '../../../../reducer/action/cart'
import { setItemToAddToProjectList } from '../../../../reducer/action/projectListManager'
import Flex from '../../../UI/Flex/Flex'
import Input from '../../../UI/Input/Input'
import { ButtonWrapper, Headline } from '../SleeveView/SleeveView.Styled'
import WireData from './WireData/WireData'
import infoIcon from '../../../../assets/image/articleDetail/icon-info.png'
import arrowsIcon from '../../../../assets/image/articleDetail/icon-arrows.png'
import Properties from './Properties/Properties'
import SleeveProperties from './SleeveProperties/SleeveProperties'
import toast, { TYPE } from '../../../../toast'
import BreadCrumb from '../../../BreadCrumb/BreadCrunb'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { awsStorageBucketPrefix } from '../../../../utility'
import imageMapping from '../../../../assets/WireImageMapping'

const WireView = ({ data }) => {
  const [selectedWireUUID, setSelectedWireUUID] = useState('')
  const [selectedWire, setSelectedWire] = useState(null)
  const [selectedWirePreviousVersion, setSelectedWirePreviousVersion] = useState(null)
  const [selectedWireNewerVersion, setSelectedWireNewerVersion] = useState(null)
  const [matchingSleeve, setMatchingSleeve] = useState(null)
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)
  const dispatch = useDispatch()
  const [countWireInMeter, setCountWireInMeter] = useState('')
  const [countSleeve, setCountSleeve] = useState('')
  const [searchParams] = useSearchParams()
  const input = searchParams.get('input')
  const [siblingSleeve, setSiblingSleeve] = useState(null)

  const attributeSorting = {
    generic1: [
      'leiterdurchmesser',
      'leiterdurchmesserinmm',
      'leiterwiderstanddereinzeladerinkm',
      'leiterwiderstanddesadernpaarsinkmbei10c',
      'leiterwiderstanddereinzeladerinkmbei10c',
      'isolationswiderstandingxkm',
      'betriebskapazittinnfkmbei800hz',
    ],
    generic2: [
      'temperaturlegenundmontierenincmin',
      'temperaturlegenundmontierenincmax',
      'temperaturtransportlagerungundbetriebincmin',
      'temperaturtransportlagerungundbetriebincmax',
    ],
  }

  useEffect(() => {
    const changeSelectedWire = (uuid) => {
      setSelectedWireUUID(uuid)
      const wireIndex = data.family.wires.findIndex((w) => w.uuid === uuid)
      setSelectedWire(data.family.wires[wireIndex])
      if (data.family.sleeves[wireIndex]) {
        setMatchingSleeve(
          data.family.sleeves.find(
            (s) => s.matchingUUID === data.family.wires[wireIndex].matchingUUID,
          ),
        )
      }
      if (data.family.wires[wireIndex].sibling) {
        const siblingSleeve = data.family.sleeves.find(
          (el) => el.matchingUUID === data.family.wires[wireIndex].sibling.matchingUUID,
        )
        if (siblingSleeve) {
          setSiblingSleeve(siblingSleeve)
        }
      }
      if (data.previousVersionFamily && data.previousVersionFamily.wires[wireIndex]) {
        setSelectedWirePreviousVersion(data.previousVersionFamily.wires[wireIndex])
      }
      if (data.newerVersionFamily && data.newerVersionFamily.wires[wireIndex]) {
        setSelectedWireNewerVersion(data.newerVersionFamily.wires[wireIndex])
      }
    }

    const foundWires = data.family.wires.filter(
      (w) => w.sapmaterialnummerdb === input || w.artikelnummerbayka === input,
    )
    if (foundWires.length === 1) {
      changeSelectedWire(foundWires[0].uuid)
    }
  }, [
    data.family.wires,
    input,
    data.family.sleeves,
    data.newerVersionFamily,
    data.previousVersionFamily,
  ])

  const getLabel = (wire, respectSibling = true) => {
    let label = `${wire.anzahlverseilelemente}x${wire.verseilelement}x${
      wire.leiterdurchmesser || wire.leiterdurchmesserinmm
    }`
    if (wire.rkkennzahl) {
      label += ` (${translation.wire.rkkennzahl} ${wire.rkkennzahl})`
    }
    if (wire.sibling && respectSibling) {
      label += ` + ${getLabel(wire.sibling, false)}`
    }
    return label
  }

  const resetAderzahlSelection = () => {
    setSelectedWireUUID('')
    setSelectedWire(null)
    setMatchingSleeve(null)
    setSelectedWirePreviousVersion(null)
    setSelectedWireNewerVersion(null)
  }

  const changeSelectedWire = (uuid) => {
    setSelectedWireUUID(uuid)
    const wireIndex = data.family.wires.findIndex((w) => w.uuid === uuid)
    setSelectedWire(data.family.wires[wireIndex])
    if (data.family.sleeves[wireIndex]) {
      setMatchingSleeve(
        data.family.sleeves.find(
          (s) => s.matchingUUID === data.family.wires[wireIndex].matchingUUID,
        ),
      )
    }
    if (data.family.wires[wireIndex].sibling) {
      const siblingSleeve = data.family.sleeves.find(
        (el) => el.matchingUUID === data.family.wires[wireIndex].sibling.matchingUUID,
      )
      if (siblingSleeve) {
        setSiblingSleeve(siblingSleeve)
      }
    }
    if (data.previousVersionFamily && data.previousVersionFamily.wires[wireIndex]) {
      setSelectedWirePreviousVersion(data.previousVersionFamily.wires[wireIndex])
    }
    if (data.newerVersionFamily && data.newerVersionFamily.wires[wireIndex]) {
      setSelectedWireNewerVersion(data.newerVersionFamily.wires[wireIndex])
    }
  }

  const addToCart = () => {
    const payload = {
      uuid: `${selectedWireUUID}${countWireInMeter}${
        matchingSleeve ? matchingSleeve.uuid : null
      }${countSleeve}`,
      // wireUUID: selectedWireUUID,
      countWireInMeter,
      // sleeveUUID: matchingSleeve ? matchingSleeve.uuid : null,
      countSleeve,
      wireArticleNumber: selectedWire.artikelnummerbayka,
    }

    dispatch(addCartItem(payload))
    toast(TYPE.SUCCESS, translation.articleDetail.success.addToCart)
    // dispatch(addCartItem({ ...selectedWire, countWireInMeter }))
    // dispatch(addCartItem({ ...matchingSleeve, countSleeve }))
  }

  const addToProjectList = () => {
    const payload = {
      uuid: `${selectedWireUUID}${countWireInMeter}${
        matchingSleeve ? matchingSleeve.uuid : null
      }${countSleeve}`,
      wireUUID: selectedWireUUID,
      wireArticleNumber: selectedWire.artikelnummerbayka,
      countWireInMeter,
      sleeveUUID: matchingSleeve ? matchingSleeve.uuid : null,
      countSleeve,
    }
    dispatch(setItemToAddToProjectList(payload))
  }

  const getImage = () => {
    const foundMapping = imageMapping.find(
      (el) => el.TITYPN.toString() === selectedWire.artikelnummerbayka,
    )
    if (foundMapping) {
      const url = awsStorageBucketPrefix + 'product_pictures/' + foundMapping.Bild
      return url
    }
    return selectedWire.image
  }
  return (
    <StyledWireView>
      <Container>
        <Search />
      </Container>
      {selectedWire && (
        <Container>
          <BreadCrumb
            items={[
              { label: 'Homepage', link: '/' },
              { label: 'Suche', link: '/search' },
              { label: 'Detailseite' },
            ]}
          />
          <Header>
            <ImageWrapper>
              <Image src={getImage()} maxHeight={'100%'} maxWidth={'100%'} />
            </ImageWrapper>
            <HeaderContent>
              <Text color={theme.color.primary} text={selectedWire.name} />
              {/* XSM new theme size? */}
              <Text size={theme.size.xsm} text={selectedWire.description} margin="10px 0 0 0" />
              <Flex justify="unset" align="center" gap="20px">
                <ReselectAderzahl>
                  <Selected onClick={resetAderzahlSelection}>{getLabel(selectedWire)}</Selected>
                  <Text
                    color={theme.color.font.thirdary}
                    size={theme.size.sm}
                    text={translation.articleDetail.selectAderzahl}
                  />
                </ReselectAderzahl>
              </Flex>
              <Details>
                <Column>
                  <Headline
                    color={theme.color.primary}
                    text={translation.wireView.shortDescription}
                    margin="0 0 5px 0"
                  />
                  <Properties
                    data={selectedWire}
                    type="wire"
                    propWhitelist={[
                      'lastenheft',
                      'betriebskapazittinnfkmbei800hz',
                      'rkkennzahl',
                      'sapmaterialnummerdb',
                      'artikelnummerbayka',
                    ]}
                  />
                  <Button
                    margin="10px 0px"
                    text={translation.wireView.detailLink}
                    onClick={() =>
                      window
                        .open(
                          `https://www.bayka.de/productDocs/Produktinformation.${selectedWire.vaterid}.pdf`,
                          '_blank',
                        )
                        .focus()
                    }
                  />
                  <ButtonCountWrapper>
                    <Input
                      width="100%"
                      type="number"
                      value={countWireInMeter}
                      onChange={setCountWireInMeter}
                      placeholder={translation.wireView.countInM}
                    />
                  </ButtonCountWrapper>
                </Column>
                <CustomFlex width="20%" align="center">
                  <CustomImage src={arrowsIcon} height="100px" />
                </CustomFlex>
                {matchingSleeve && (
                  <Column>
                    <Headline
                      color={theme.color.primary}
                      text={translation.articleDetail.sleeveSectionTitle}
                    />
                    <SleeveProperties
                      data={matchingSleeve}
                      siblingSleeve={siblingSleeve}
                      type="sleeve"
                      propWhitelist={[
                        'schutzmuffe',
                        'mengeschutzmuffe',
                        'innenmuffe',
                        'mengeinnenmuffe',
                        'quetschverbinder',
                        'mengequetschverbinder',
                        'schirmverbindungsleitung',
                        'mengeschirmverbindungsleitung',
                        'thermoplastischesabdichtband',
                        'mengethermoplastischesabdichtband',
                        'schrumpfschlauch',
                        'mengeschrumpfschlauch',
                        'erdseil',
                        'mengeerdseil',
                        'bausatz',
                        'mengebausatz',
                      ]}
                    />
                    {/* {siblingSleeve && (
                      <>
                        [{selectedWire.sibling.leiterdurchmesser}]
                        <Properties
                          data={siblingSleeve}
                          type="sleeve"
                          propWhitelist={[
                            // 'innenmuffe',
                            // 'mengeinnenmuffe',
                            // // 'quetschverbinder',
                            'mengequetschverbinder',
                            // // 'schirmverbindungsleitung',
                            // 'mengeschirmverbindungsleitung',
                            // // 'thermoplastischesabdichtband',
                            // 'mengethermoplastischesabdichtband',
                            // // 'schrumpfschlauch',
                            // 'mengeschrumpfschlauch',
                            // // 'erdseil',
                            // 'mengeerdseil',
                            // // 'bausatz',
                            // 'mengebausatz',
                            // // 'schutzmuffe',
                            // 'mengeschutzmuffe',
                          ]}
                        />
                      </>
                    )} */}
                    <Flex wrap="nowrap" justify="unset" align="center" gap="5px">
                      <Image src={infoIcon} height="20px" />
                      <Text
                        margin="10px 0px 0px 0px"
                        size={'12px'}
                        text={translation.wireView.infoOldSleeves}
                      />
                    </Flex>
                    <ButtonCountWrapper>
                      <Input
                        width="100%"
                        type="number"
                        value={countSleeve}
                        onChange={setCountSleeve}
                        placeholder={translation.wireView.countInStk}
                      />
                    </ButtonCountWrapper>
                  </Column>
                )}
              </Details>
              <ButtonWrapper>
                <Button
                  text={translation.articleDetail.addToProjectList}
                  onClick={addToProjectList}
                  margin="0 25px 0 0 "
                />
                <Button text={translation.articleDetail.addToCart} onClick={addToCart} />
              </ButtonWrapper>
            </HeaderContent>
          </Header>
          <WireData
            attributeSorting={attributeSorting}
            wire={selectedWire}
            wireFamily={data.family}
            previousVersion={selectedWirePreviousVersion}
            newerVersion={selectedWireNewerVersion}
          />
        </Container>
      )}

      {!selectedWireUUID && (
        <Modal title={translation.articleDetail.selectAderzahl}>
          <Dropdown
            value={selectedWireUUID}
            onChange={changeSelectedWire}
            items={data.family.wires.map((wire) => ({
              label: getLabel(wire),
              value: wire.uuid,
            }))}
          />
        </Modal>
      )}
    </StyledWireView>
  )
}

export default WireView
