import { useSelector } from 'react-redux'
import {
  StyledSection,
  Title,
  PostcodeAreas,
  Split,
  Column,
  Line,
  List,
  Person,
  ImageContainer,
  Image,
  Data,
  Name,
  Area,
  Phone,
  Mobile,
  Fax,
  Mail,
  PostcodeArea,
} from './Section.Styled'
import { getUUID } from '../../../utility'

const Section = ({ data, hideLineColumn = false }) => {
  const translation = useSelector((s) => s.translation)

  const getImage = (name) => {
    const resolveLastname = () => {
      const lastname = name.split(' ')[1]
      switch (lastname) {
        case 'Großbeck':
          return 'Grossbeck'
        case 'Köpplinger':
          return 'Koepplinger'
        case 'Schäfer':
          return 'Schaefer'
        case 'Köbler':
          return 'Koebler'
        case 'Platzöder':
          return 'Platzoeder'

        case 'Glungler':
          return 'Mass'
        default:
          return lastname
      }
    }
    const image = require(`../../../assets/image/contact/people/${resolveLastname()}-300x300.jpg`)
    return image
  }

  return (
    <StyledSection>
      <Split>
        {!hideLineColumn && (
          <Column width="125px">
            <Line />
          </Column>
        )}
        <Column>
          <Title>{data.title}</Title>
          {data.postcodeAreas && (
            <PostcodeAreas>
              {translation.generic.postcodeArea}
              {data.postcodeAreas}
            </PostcodeAreas>
          )}
          <List>
            {data.people.map(({ name, area, phone, mobile, fax, mail, postcodeArea }) => (
              <Person key={getUUID()}>
                <ImageContainer>
                  <Image src={getImage(name)} />
                </ImageContainer>
                <Data>
                  <Name>{name}</Name>
                  <Area>{area}</Area>
                  {postcodeArea && (
                    <PostcodeArea>
                      {translation.generic.postcode}
                      {postcodeArea}
                    </PostcodeArea>
                  )}
                  {phone && (
                    <Phone>
                      {translation.generic.phone}
                      {phone}
                    </Phone>
                  )}
                  {mobile && (
                    <Mobile>
                      {translation.generic.mobile}
                      {mobile}
                    </Mobile>
                  )}
                  {fax && (
                    <Fax>
                      {translation.generic.fax}
                      {fax}
                    </Fax>
                  )}
                  {mail && (
                    <Mail>
                      {translation.generic.mail}
                      {mail}
                    </Mail>
                  )}
                </Data>
              </Person>
            ))}
          </List>
        </Column>
      </Split>
    </StyledSection>
  )
}

export default Section
