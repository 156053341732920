export const SET_RESPONSIVE_STATE = 'set_responsive_state'

export const SET_TRANSLATION = 'set_translation'

export const SET_ACCOUNT = 'set_account'

export const SET_THEME = 'set_theme'

export const SET_TOKEN = 'set_token'

export const SET_CART = 'set_cart'

export const ADD_CART_ITEM = 'add_cart_item'

export const REMOVE_CART_POSITION = 'remove_cart_position'

export const REMOVE_CART_ITEM = 'remove_cart_item'

export const SET_LOGIN_MODAL_CONTEXT = 'set_login_modal_context'

export const SET_ITEM_TO_ADD_TO_PROJECT_LIST = 'set_item_to_add_to_project_list'

export const UPDATE_CART_COUNT = 'UPDATE_CART_COUNT'

export const SET_CART_SLEEVE_PRODUCTS = 'SET_CART_SLEEVE_PRODUCTS'

export const SET_ITEMS_TO_ADD_TO_PROJECT_LIST = 'set_items_to_add_to_project_list'

export const UPDATE_WIRE_AND_SLEEVE_COUNT = 'UPDATE_WIRE_AND_SLEEVE_COUNT'

export const ADD_CART_ITEMS = 'ADD_CART_ITEMS'
