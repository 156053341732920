import { Route, Routes, Navigate } from 'react-router-dom'
import Dashboard from './Dashboard/Dashboard'
import UserManagement from './UserManagement/UserManagement'

const Admin = () => {
  return (
    <Routes>
      <Route index element={<UserManagement />} />
      <Route path={'user-management'} element={<UserManagement />} />
      <Route path={'dashboard'} element={<Dashboard />} />
      <Route path={'*'} element={<Navigate replace to="/admin" />} />
    </Routes>
  )
}

export default Admin
