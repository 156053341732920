import _ from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import usePrevious from '../../helper/usePrevious'
import { get, set } from '../../interface/cart'
import { setCart, setSleeveProducts } from '../../reducer/action/cart'
import customToast, { TYPE } from '../../toast'

const CartPersisit = () => {
  const cart = useSelector((state) => state.cart)
  const token = useSelector((state) => state.token)
  const prevCart = usePrevious(cart)
  const dispatch = useDispatch()
  const translation = useSelector((state) => state.translation)
  const cartSleeveProducts = useSelector((s) => s.cartSleeveProducts)

  useEffect(() => {
    const getDBCart = async () => {
      const { cart, sleeveProducts } = await get(token)
      if (Array.isArray(cart)) {
        dispatch(setCart(cart))
        dispatch(setSleeveProducts(sleeveProducts))
      }
    }

    const setNewCart = async () => {
      const status = await set(token, cart, cartSleeveProducts)
      if (!status) {
        customToast(TYPE.ERROR, translation.cart.error.set)
      }
    }

    if (
      Array.isArray(cart) &&
      cart.length === 0 &&
      (prevCart?.length === 0 || !prevCart) &&
      token
    ) {
      getDBCart()
    }

    if (Array.isArray(prevCart) && !_.isEqual(prevCart, cart) && token) {
      setNewCart()
    }
  }, [token, cart, prevCart, dispatch, translation, cartSleeveProducts])

  return <></>
}

export default CartPersisit
