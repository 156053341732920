import { StyledDropdown, Select, Option, Label } from './Dropdown.Styled'
import { getUUID } from '../../../utility'
import { useSelector } from 'react-redux'

const Button = ({
  label,
  onClick,
  className,
  margin,
  width,
  color,
  backgroundColor,
  onChange,
  value,
  items = [],
}) => {
  const translation = useSelector((s) => s.translation)
  return (
    <StyledDropdown onClick={onClick} className={className} width={width} margin={margin}>
      {label && <Label color={color}>{label}</Label>}
      <Select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        backgroundColor={backgroundColor}
      >
        <Option key={getUUID()} value={''} color={color}>
          {translation.UI.dropdown.emptySelection}
        </Option>
        {items.map((item) => (
          <Option key={getUUID()} value={item.value} color={color}>
            {item.label}
          </Option>
        ))}
      </Select>
    </StyledDropdown>
  )
}

export default Button
