import styled from 'styled-components'
import Flex from '../../../../UI/Flex/Flex'

export const Card = styled.div`
  width: 33%;
  padding: 10px;
  background-color: ${({ theme }) => theme.color.secondary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    /* flex-wrap: wrap; */
    /* display: block; */
    margin: 20px 0px;
    width: 100%;
  }
`

export const DataRow = styled.div`
  display: flex;
  gap: 10px;
  background-color: ${({ theme }) => theme.color.font.secondary};
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.color.secondary};
  }
`

export const Column = styled.div`
  width: ${({ width }) => width};
  font-size: ${({ theme }) => theme.size.xsm};
  color: ${({ theme }) => theme.color.font.thirdary};
`

export const CustomFlex = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    /* flex-wrap: wrap; */
    display: block;
  }
`
