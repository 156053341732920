export const prepareValues = (attributeProp, values) => {
  if (attributeProp === 'betriebskapazittinnfkmbei800hz') {
    return values
      .map((value) => ({
        value,
        label: parseInt(value.split(' ')[1]),
      }))
      .sort((a, b) => a.label - b.label)
      .map(({ value, label }) => ({
        value,
        label: `H${label}`,
      }))
  }
  if (attributeProp === 'verseilelement') {
    return values.map((value) => ({
      value,
      label: value === '1' ? 'adrig' : 'vierer',
    }))
  }
  if (attributeProp === 'lastenheft') {
    return values.map((value) => ({
      value,
      label: value.split(' ')[1],
    }))
  }
  if (attributeProp === 'rkkennzahl') {
    return [
      ...values
        .sort((a, b) => a - b)
        .map((value) => ({
          value,
          label: value,
        })),
      { value: 'without', label: 'ohne' },
    ]
  }
  return values.map((value) => ({
    value,
    label: value,
  }))
}
