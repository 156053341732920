import * as types from './type/type'
import * as translations from './translations'
import * as responsiveState from './responsiveState'
import * as account from './account'
import * as theme from './theme'
import * as token from './token'
import * as cart from './cart'
import * as loginModalContext from './loginModalContext'
import * as projectListManager from './projectListManager'
import defaultState from './defaultState'

function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.SET_TRANSLATION:
      return translations.set(state, action)
    case types.SET_RESPONSIVE_STATE:
      return responsiveState.set(state, action)
    case types.SET_ACCOUNT:
      return account.set(state, action)
    case types.SET_THEME:
      return theme.set(state, action)
    case types.SET_TOKEN:
      return token.set(state, action)
    case types.SET_CART:
      return cart.set(state, action)
    case types.ADD_CART_ITEM:
      return cart.add(state, action)
    case types.REMOVE_CART_ITEM:
      return cart.remove(state, action)
    case types.REMOVE_CART_POSITION:
      return cart.removePosition(state, action)
    case types.SET_LOGIN_MODAL_CONTEXT:
      return loginModalContext.set(state, action)
    case types.SET_ITEM_TO_ADD_TO_PROJECT_LIST:
      return projectListManager.setItemToAdd(state, action)
    case types.SET_ITEMS_TO_ADD_TO_PROJECT_LIST:
      return projectListManager.setItemsToAdd(state, action)
    case types.UPDATE_CART_COUNT:
      return cart.updateCount(state, action)
    case types.SET_CART_SLEEVE_PRODUCTS:
      return cart.setSleeveProducts(state, action)
    case types.UPDATE_WIRE_AND_SLEEVE_COUNT:
      return cart.updateWireAndSleeveCount(state, action)
    case types.ADD_CART_ITEMS:
      return cart.addItems(state, action)
    default:
      return state
  }
}

export default reducer
