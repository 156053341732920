import styled from 'styled-components'
import Text from '../UI/Text/Text'

export const StyledSearchList = styled.div``

export const Results = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`

export const HeroOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  width: 30%;
  height: inherit;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-right: 15px solid ${({ theme }) => theme.color.primary};
`

export const TextWrapper = styled.div`
  padding: 50px;
`

export const HeroHeadline = styled(Text)`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.size.lg};
  text-transform: uppercase;
  font-family: 'bold';
`

export const HeroText = styled(Text)`
  margin-top: 10px;
  color: ${({ theme }) => theme.color.font.secondary};
`

export const Split = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`
export const FilterWrapper = styled.div`
  width: 25%;
  padding: 0px 25px;
  border-right: thin solid ${({ theme }) => theme.color.primary};
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    border-right: none;
    border-bottom: thin solid ${({ theme }) => theme.color.primary};
    width: 100%;
    padding: 0;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
`
export const ResultsWrapper = styled.div`
  width: 75%;
  padding: 0px 25px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    padding: 0;
  }
`

export const NoResults = styled(Text)`
  /* text-align: center; */
  font-family: 'bold';
`

export const NoResultsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
`

export const Request = styled.div``

export const RequestLink = styled.a``

export const LinkWrapper = styled.div`
  display: flex;

  align-items: center;
`
