import { useSelector } from 'react-redux'
import { StyledArticles, Header, Body, Name, Count, Delete, Headline } from './Articles.Styled'
import Item from './Item/Item'

const Articles = ({ data, updateProp, remove, setList }) => {
  const translation = useSelector((s) => s.translation)

  return (
    <StyledArticles>
      <Header>
        <Name>{translation.profile.projectLists.list.header.name}</Name>
        <Count>{translation.profile.projectLists.list.header.count}</Count>
        <Delete>{translation.profile.projectLists.list.header.delete}</Delete>
      </Header>
      <Body>
        {Array.isArray(data) &&
          data
            .filter((item) => item?.article?.type !== 'sleeve')
            .map((d, i) => (
              <Item
                setList={setList}
                key={d.uuid}
                item={d}
                updateProp={updateProp(d.uuid)}
                remove={remove(d.uuid, i)}
              />
            ))}
      </Body>
      <Headline margin="50px 0 20px 0">Einzelteile</Headline>
      <Header>
        <Name>{translation.profile.projectLists.list.header.name}</Name>
        <Count>{translation.profile.projectLists.list.header.count}</Count>
        <Delete>{translation.profile.projectLists.list.header.delete}</Delete>
      </Header>
      <Body>
        {Array.isArray(data) &&
          data
            .filter((item) => item?.article?.type === 'sleeve')
            .map((d, i) => (
              <Item
                data={data}
                setList={setList}
                key={d.uuid}
                item={d}
                updateProp={updateProp(d.uuid)}
                remove={remove(d.uuid, i)}
              />
            ))}
      </Body>
    </StyledArticles>
  )
}

export default Articles
