import styled from 'styled-components'

export const StyledSleeveComponents = styled.div`
  margin-top: 20px;
`

export const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
`

export const Column = styled.div`
  width: ${({ width }) => width || '25%'};
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.size.sm};
`
