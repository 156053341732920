import { useSelector } from 'react-redux'
import {
  StyledLoginOrSignUp,
  Login,
  SignUp,
  Wrapper,
  Title,
  Strong,
  ButtonSwitchWrapper,
} from './LoginOrSignUp.Styled'
import LoginForm from './LoginForm/LoginForm'
import SignUpForm from './SignUpForm/SignUpForm'
import { useState } from 'react'
import Button from '../UI/Button/Button'

const LoginOrSignUp = ({ route = '/search/?type=wire' }) => {
  const token = useSelector((state) => state.token)
  const [isLogin, setIsLogin] = useState(true)
  const translation = useSelector((state) => state.translation)

  if (token) return <></>
  return (
    <StyledLoginOrSignUp>
      {isLogin && (
        <Login>
          <Wrapper>
            <Title>
              Bitte <Strong>anmelden</Strong>, um alle Informationen abzurufen!
            </Title>
            <LoginForm route={route} />
          </Wrapper>
        </Login>
      )}
      {!isLogin && (
        <SignUp>
          <Wrapper>
            <Title>
              Bitte <Strong>registrieren</Strong>, um alle Informationen abzurufen!
            </Title>
            <SignUpForm setIsLogin={setIsLogin} />
          </Wrapper>
        </SignUp>
      )}
      <ButtonSwitchWrapper>
        <Button
          text={
            isLogin
              ? translation.loginOrSignUp.switchToSignUp
              : translation.loginOrSignUp.switchToLogin
          }
          onClick={() => setIsLogin(!isLogin)}
        />
      </ButtonSwitchWrapper>
    </StyledLoginOrSignUp>
  )
}

export default LoginOrSignUp
