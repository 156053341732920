import { CustomFlex, Slash, CustomLink } from './BreadCrumb.Styled.js'
import houseIcon from '../../assets/image/house.png'
import Image from '../UI/Image/Image.js'
import { useNavigate } from 'react-router-dom'
import { getUUID } from '../../utility.js'

const BreadCrumb = ({ items }) => {
  const navigate = useNavigate()

  return (
    <CustomFlex>
      <Image
        src={houseIcon}
        width="15px"
        margin="0 5px 0 0"
        cursor="pointer"
        onClick={() => navigate('/')}
      />
      {Array.isArray(items) &&
        items.map((item, i) => (
          <CustomFlex
            align={'center'}
            padding={'unset'}
            bottom={'0px'}
            borderBottom={'unset'}
            key={getUUID()}
          >
            <CustomLink href={item.link} text={item.label} />
            {items.length - 1 !== i && <Slash>{' / '}</Slash>}
          </CustomFlex>
        ))}
    </CustomFlex>
  )
}

export default BreadCrumb
