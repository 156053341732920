import styled from 'styled-components'
import Text from '../../UI/Text/Text'
import Image from '../../UI/Image/Image'

export const StyledMinimized = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 15px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`

export const ImageWrapper = styled.div`
  width: 20%;
  height: 100px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    text-align: center;
  }
`

export const Content = styled.div`
  width: 80%;
  font-size: ${({ theme }) => theme.size.sm};

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const Headline = styled.a`
  color: ${({ theme }) => theme.color.primary};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
export const CustomText = styled(Text)`
  margin-top: 10px;
  color: ${({ theme }) => theme.color.font.thirdary};
`

export const CustomImage = styled(Image)`
  max-height: 100%;
  max-width: 150px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    max-width: 150px;
  }
`
