import styled from 'styled-components'

export const StyledFlex = styled.div`
  display: flex;
  gap: ${({ gap }) => gap || '0px'};
  justify-content: ${({ justify }) => justify || 'center'};
  align-items: ${({ align }) => align || 'unset'};
  flex-wrap: ${({ wrap }) => wrap || 'wrap'};
  margin: ${({ margin }) => margin || '0px'};
  flex: ${({ flex }) => flex || 'unset'};
  width: ${({ width }) => (width ? width : '100%')};
`
