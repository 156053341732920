import styled from 'styled-components'
import Image from '../../UI/Image/Image'

export const StyledCartItem = styled.div`
  margin: 5px;
  padding: 5px;
  /* border: 1px solid black; */
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  padding-bottom: 10px;
`

export const ItemLabel = styled.div`
  margin-top: 5px;
  color: ${({ theme }) => theme.color.font.primary};
  font-size: ${({ theme }) => theme.size.sm};
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
`

export const ImageWrapper = styled.div`
  flex: 1 1 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const CustomImage = styled(Image)`
  width: 50px;
  cursor: pointer;
`

export const NameColumn = styled.div``

export const StyledWire = styled.div`
  /* display: flex;
  gap: 5px; */
`

export const StyledSleeve = styled.div`
  /* display: flex;
  gap: 5px; */
  margin-top: 5px;
`

export const Name = styled.div`
  color: ${({ theme }) => theme.color.font.primary};
  font-size: ${({ theme }) => theme.size.sm};
`

export const Count = styled.div`
  color: ${({ theme }) => theme.color.font.primary};
  font-size: ${({ theme }) => theme.size.sm};
`

export const ItemLabelCount = styled(ItemLabel)`
  border-top: 1px dashed ${({ theme }) => theme.color.border};
  padding-top: 5px;
  font-size: ${({ theme }) => theme.size.sm};
`
