import { useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../UI/Button/Button'
import Flex from '../../UI/Flex/Flex'
import Checkbox from '../../UI/Checkbox/Checkbox'
import { CustomInput, StyledSignUpForm, CTAWrapper } from './SignUpForm.Styled'
import { signup } from '../../../interface/auth'
import toast, { TYPE } from '../../../toast'

const SignUpForm = ({ setIsLogin }) => {
  const translation = useSelector((state) => state.translation)

  const [payload, setPayload] = useState({
    lastname: '',
    username: '',
    functionWithinCompany: '',
    firstname: '',
    companyBranch: '',
    password: '',
    companyName: '',
    mail: '',
    dsgvo: false,
    newsletter: false,
  })

  const updateProp = (prop) => (value) => {
    setPayload({ ...payload, [prop]: value })
  }

  const submit = async () => {
    if (!functionToValidatePhoneNumber(payload.phone)) {
      toast(TYPE.ERROR, translation.signup.error.phone)
      return false
    }

    const status = await signup(payload)
    if (status) {
      toast(TYPE.SUCCESS, translation.signup.success.submit)
      setIsLogin(true)
    } else {
      toast(TYPE.ERROR, translation.signup.error.submit)
    }
  }

  const DSGVOCustomLabel = () => {
    return (
      <span>
        Ich stimme den{' '}
        <a
          style={{ color: 'black' }}
          rel="noreferrer"
          href="https://www.bayka.de/datenschutzerklaerung/"
          target={'_blank'}
        >
          Datenschutzbestimmungen
        </a>{' '}
        zu.
      </span>
    )
  }

  const functionToValidatePhoneNumber = (phoneNumber) => {
    const regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
    return regex.test(phoneNumber)
  }

  return (
    <StyledSignUpForm onSubmit={submit}>
      <Flex gap="15px" margin="10px 0 0 0">
        <CustomInput
          minimized
          value={payload.lastname}
          onChange={updateProp('lastname')}
          placeholder={translation.signup.name}
          required
        />
        <CustomInput
          minimized
          value={payload.functionWithinCompany}
          onChange={updateProp('functionWithinCompany')}
          placeholder={translation.signup.functionWithinCompany}
          required
        />
        {/* <CustomInput
          minimized
          autoComplete="username"
          value={payload.username}
          onChange={updateProp('username')}
          placeholder={translation.generic.username}
          required
        /> */}
        <CustomInput
          minimized
          value={payload.phone}
          onChange={updateProp('phone')}
          placeholder={translation.signup.phone}
          required
        />
        <CustomInput
          minimized
          value={payload.firstname}
          onChange={updateProp('firstname')}
          placeholder={translation.signup.firstname}
          required
        />
        <CustomInput
          minimized
          value={payload.companyBranch}
          onChange={updateProp('companyBranch')}
          placeholder={translation.signup.companyBranch}
          required
        />
        <CustomInput
          minimized
          type="password"
          autoComplete="new-password"
          value={payload.password}
          onChange={updateProp('password')}
          placeholder={translation.signup.enterPasswort}
          required
        />
        <CustomInput
          minimized
          type="email"
          value={payload.mail}
          onChange={updateProp('mail')}
          placeholder={translation.signup.mail}
          required
        />
        <CustomInput
          minimized
          value={payload.companyName}
          onChange={updateProp('companyName')}
          placeholder={translation.signup.companyName}
          required
        />

        <Checkbox
          required
          checked={payload.dsgvo}
          onChange={updateProp('dsgvo')}
          CustomLabel={DSGVOCustomLabel}
        />

        <Checkbox
          checked={payload.newsletter}
          onChange={updateProp('newsletter')}
          label={translation.signup.newsletter}
        />
        <CTAWrapper>
          <Button minimized type="submit" text={translation.signup.cta} />
        </CTAWrapper>
      </Flex>
    </StyledSignUpForm>
  )
}

export default SignUpForm
