import { StyledSuggestions } from './Suggestions.Styled'
import Suggestion from './Suggestion/Suggestion'
import { getUUID } from '../../../utility'

const Suggestions = ({ data, clickedCallback, input }) => {
  return (
    <StyledSuggestions>
      {data.map((item) => (
        <Suggestion input={input} key={getUUID()} data={item} clickedCallback={clickedCallback} />
      ))}
    </StyledSuggestions>
  )
}

export default Suggestions
