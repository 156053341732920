import styled from 'styled-components'
import dropdownIcon from '../../../../assets/image/articleDetail/dropdown.png'
import Text from '../../../UI/Text/Text'

export const StyledSleeveView = styled.div``
export const Header = styled.div`
  display: flex;
  gap: 25px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
  }
`

export const ImageWrapper = styled.div`
  width: 25%;
  display: flex;
  height: 250px;
  align-items: center;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const HeaderContent = styled.div`
  width: 75%;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const ReselectAderzahl = styled.div`
  margin: 25px 0;
  display: flex;
  align-items: center;
  gap: 50px;
`
export const Selected = styled.div`
  border: thin solid ${({ theme }) => theme.color.border};
  display: inline-block;
  padding: 3px 10px 3px 70px;
  cursor: pointer;
  background-image: url(${dropdownIcon});
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: 5px center;
`
export const Details = styled.div`
  margin-top: 50px;
  padding: 10px;
  background-color: ${({ theme }) => theme.color.secondary};
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
`
export const Headline = styled(Text)`
  border-bottom: 1px solid ${({ theme }) => theme.color.background};
  padding-bottom: 5px;
`
export const Column = styled.div`
  width: 50%;
`

export const Notice = styled(Text)`
  margin-top: 20px;
`

export const NoticeText = styled(Text)``

export const HeadlineSelect = styled(Text)`
  margin-top: 50px;
`

export const Select = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  background-color: ${({ theme }) => theme.color.secondary};

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 0;
  }
`

export const SelectHeader = styled.div`
  display: flex;
  gap: 20px;
  padding: 0px 5px 10px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`

export const SelectRow = styled.div`
  :nth-child(odd) {
    background-color: ${({ theme }) => theme.color.backgroundGray};
  }
  display: flex;
  gap: 20px;
  padding: 3px 5px;
  background-color: ${({ theme, isOdd }) => (isOdd ? 'transparent' : theme.color.background)};

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
    border-bottom: 2px dotted ${({ theme }) => theme.color.backgroundDark};
    padding: 15px 0px;

    :nth-child(odd) {
      background-color: ${({ theme }) => theme.color.background};
    }
  }
`

export const RowText = styled.div`
  text-align: ${({ align }) => align || 'unset'};
  width: ${({ width }) => width};
  font-size: ${({ theme, size }) => (size ? size : theme.size.sm)};

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const TextValue = styled.div`
  text-align: ${({ align }) => align || 'unset'};
  font-size: ${({ theme, size }) => (size ? size : theme.size.sm)};
`

export const HeaderValue = styled.div`
  display: none;
  text-align: ${({ align }) => align || 'unset'};
  font-size: ${({ theme, size }) => (size ? size : theme.size.md)};

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`

export const Value = styled.div`
  font-size: ${({ theme, size }) => (size ? size : theme.size.sm)};
`

export const ButtonWrapper = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 50px;
  }
`

export const StyledUL = styled.ul`
  li {
    font-size: ${({ theme, size }) => (size ? size : theme.size.sm)};
  }
  margin-left: 20px;
`
