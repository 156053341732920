import { useSelector } from 'react-redux'
import Layout from '../UI/Layout/Layout'
import {
  StyledHome,
  HeroOverlay,
  HeroHeadline,
  HeroText,
  TextWrapper,
  SearchWrapper,
} from './Home.Styled'
import Hero from '../UI/Hero/Hero'
import heroImage from '../../assets/image/home/hero.jpg'
import Search from '../Search/Search'
import Sample from './Sample/Sample'
import Container from '../UI/Container/Container'
import LoginOrSignUp from '../LoginOrSignUp/LoginOrSignUp'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Home = () => {
  const translation = useSelector((state) => state.translation)
  const token = useSelector((s) => s.token)
  const navigate = useNavigate()

  useEffect(() => {
    if (token) navigate('/search/?type=wire')
  })

  return (
    <Layout authRequired={false}>
      <StyledHome>
        <Hero img={heroImage}>
          <HeroOverlay>
            <TextWrapper>
              <HeroHeadline text={translation.home.hero.overlay.headline} />
              <HeroText text={translation.home.hero.overlay.text} />
            </TextWrapper>
          </HeroOverlay>
        </Hero>
        <Container>
          <SearchWrapper>
            <Search />
          </SearchWrapper>
          <Sample />
          <LoginOrSignUp />
        </Container>
      </StyledHome>
    </Layout>
  )
}

export default Home
