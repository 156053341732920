import fetch, { METHOD } from '../fetch'

export const sendMail = async (token, cart, sleeveProducts) => {
  const { status } = await fetch(METHOD.POST, '/checkout/send', {
    token,
    cart,
    sleeveProducts,
  })
  return status
}
