import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Layout from '../../UI/Layout/Layout'
import { StyledDetail } from './Detail.Styled'
import { get } from '../../../interface/article'
import toast, { TYPE } from '../../../toast'
import WireView from './WireView/WireView'
import usePrevious from '../../../hooks/usePrevious'
import SleeveView from './SleeveView/SleeveView'

const ArticleDetail = () => {
  const { uuid } = useParams()
  const [data, setData] = useState(null)
  const token = useSelector((s) => s.token)
  const translation = useSelector((s) => s.translation)
  const previousUUID = usePrevious(uuid)
  const navigate = useNavigate()

  useEffect(() => {
    const getData = async () => {
      const data = await get(token, uuid)
      if (data) {
        if (data.newerVersionFamily) {
          navigate(`/detail/${data.newerVersionFamily.wires[0].uuid}`)
        } else {
          setData(data)
        }
      } else {
        toast(TYPE.ERROR, translation.articleDetail.error.load)
      }
    }
    if (token && (!data || previousUUID !== uuid)) {
      setData(null)
      getData()
    }
  }, [token, data, uuid, translation, previousUUID, navigate])

  return (
    <Layout>
      <StyledDetail>
        {data && <>{data.type === 'wire' && <WireView data={data} />}</>}
        {data && <>{data.type === 'sleeve' && <SleeveView data={data} />}</>}
      </StyledDetail>
    </Layout>
  )
}

export default ArticleDetail
