import styled from 'styled-components'
import Image from '../../Image/Image'
import Link from '../../Link/Link'

export const StyledHeader = styled.div`
  width: 100vw;
  height: 100px;
  z-index: 999;
`

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px 15px;
`

export const Item = styled.div`
  /* flex: 1 1 0px; */
  gap: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row-reverse;
  margin: 0px 15px;
  color: ${({ theme }) => theme.color.font.secondary};
`

export const StyledToggle = styled.img`
  height: 20px;
  z-index: 999;
  position: relative;
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 30px;
  }
`

export const Navigation = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  box-sizing: border-box;
  box-shadow: ${({ fullscreen }) => (fullscreen ? '' : ' -4px 3px 16px -6px rgba(0, 0, 0, 0.48)')};
  -webkit-box-shadow: ${({ fullscreen }) =>
    fullscreen ? '' : ' -4px 3px 16px -6px rgba(0, 0, 0, 0.48)'};
  -moz-box-shadow: ${({ fullscreen }) =>
    fullscreen ? '' : ' -4px 3px 16px -6px rgba(0, 0, 0, 0.48)'};

  z-index: 998;
  position: absolute;
  width: 400px;
  height: 100vh;
  top: 0px;
  right: 0px;
  gap: 15px;
`

export const CustomLink = styled(Link)`
  margin: 10px 0px;
  display: block;
  color: ${({ theme }) => theme.color.font.thirdary};
  ${({ onClick }) => !onClick && 'cursor: default'};
  ${({ bold }) =>
    bold
      ? `
    font-family: 'bold';
    margin-top: 20px;
  `
      : 'margin-left: 20px;'};
  &:hover {
    ${({ onClick }) => onClick && 'text-decoration: underline;'};
  }
`

export const Logo = styled(Image)`
  height: 90px;
  cursor: pointer;
`

export const LinkWrapper = styled.div`
  margin-top: 50px;
`
