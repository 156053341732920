import LoginOrSignUp from '../../LoginOrSignUp/LoginOrSignUp'

import { useSelector, useDispatch } from 'react-redux'
import { setLoginModalContext } from '../../../reducer/action/loginModalContext'
import Modal from '../../UI/Modal/Modal'
import defaultState from '../../../reducer/defaultState'
import { useEffect } from 'react'

const LoginModal = () => {
  const loginModalContext = useSelector((state) => state.loginModalContext)
  const translation = useSelector((state) => state.translation)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setLoginModalContext(defaultState.loginModalContext))
  }

  useEffect(() => {
    if (loginModalContext.show) {
      return () => {
        dispatch(setLoginModalContext(defaultState.loginModalContext))
      }
    }
  })

  if (!loginModalContext.show || loginModalContext.route.length === 0) return <></>

  return (
    <Modal close={handleClose} title={translation.evalRedirect.modal.title}>
      <LoginOrSignUp route={loginModalContext.route} />
    </Modal>
  )
}

export default LoginModal
