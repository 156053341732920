import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import fetch, { METHOD, fileUploadBackend } from '../../../fetch'
import { getCountCompleted, getUserCount } from '../../../interface/dashboard'
import customToast, { TYPE } from '../../../toast'
import { triggerClientSideDownload } from '../../../utility'
import Button from '../../UI/Button/Button'
import Container from '../../UI/Container/Container'
import Flex from '../../UI/Flex/Flex'
import Layout from '../../UI/Layout/Layout'
import { Label, StyledLabel, Select, Wrapper, Headline } from './Dashboard.Styled'

const Dashboard = () => {
  const [countCompleted, setCountCompleted] = useState(null)
  const [userCount, setUserCount] = useState(null)
  const token = useSelector((state) => state.token)
  const translation = useSelector((state) => state.translation)

  useEffect(() => {
    const getCount = async () => {
      const count = await getCountCompleted(token)
      if (!isNaN(count)) setCountCompleted(count)
    }

    const getUser = async () => {
      const userCount = await getUserCount(token)
      if (!isNaN(userCount)) setUserCount(userCount)
    }

    if (countCompleted === null && token) getCount()
    if (userCount === null && token) getUser()
  }, [token, countCompleted, userCount])

  const exportData = async () => {
    const { status, data } = await fetch(METHOD.GET, '/export/sleeves', { token })

    if (status && data) {
      triggerClientSideDownload(data, 'export.csv')
    }
  }

  const upload = async (file) => {
    if (file) {
      const status = await fileUploadBackend(token, '/importer/wire', [file])
      if (status) customToast(TYPE.SUCCESS, 'Datei erfolgreich importiert')
    }
  }

  return (
    <Layout>
      <Container>
        <Flex gap="5px">
          <Label>{countCompleted}</Label>
          <Label>{translation.dashboard.labelCompletedCarts}</Label>
        </Flex>
        <Flex gap="5px">
          <Label>{userCount}</Label>
          <Label>{translation.dashboard.labelCountUser}</Label>
        </Flex>
        <Flex wrap="nowrap" gap="20px" margin="10px 0 0 0">
          <Wrapper>
            <Headline>Exportieren der Kabel/ Muffen Tabelle als CSV</Headline>
            <Button margin="10px 0 0 0" text="Datenexport" onClick={exportData} />
          </Wrapper>
          <Wrapper>
            <Headline>Hochladen einer Kabel/ Muffen Tabelle als CSV</Headline>
            <StyledLabel htmlFor="select">Hochladen</StyledLabel>
            <Select
              id="select"
              type="file"
              onChange={(e) => upload(e.target.files[0])}
              accept=".csv"
            />
          </Wrapper>
        </Flex>
      </Container>
    </Layout>
  )
}

export default Dashboard
