import SleeveImageMapping from '../../../assets/SleeveImageMapping'
import WireImageMapping from '../../../assets/WireImageMapping'
import { awsStorageBucketPrefix, getImage } from '../../../utility'
import EvalRedirect from '../../EvalRedirect/EvalRedirect'
import getWireLabel from '../../../helper/getWireLabel'

import {
  StyledMinimized,
  ImageWrapper,
  Content,
  Headline,
  CustomText,
  CustomImage,
} from './Minimized.Styled'

export const ArticleMinimizedWire = ({ data, input }) => {
  const getImage = () => {
    if (data.type === 'wire') {
      const foundMapping = WireImageMapping.find(
        (el) => el.TITYPN.toString() === data.artikelnummerbayka,
      )
      if (foundMapping) {
        const url = awsStorageBucketPrefix + 'product_pictures/' + foundMapping.Bild
        return url
      }
    } else if (data.type === 'sleeve') {
      const foundMapping = SleeveImageMapping.find((el) => el.id.toString() === data.id)
      if (foundMapping) {
        const url = awsStorageBucketPrefix + 'product_pictures/' + foundMapping.Bild
        return url
      }
    }
    return data.image
  }

  return (
    <StyledMinimized>
      <ImageWrapper>{data.image && <CustomImage src={getImage()} />}</ImageWrapper>
      <Content>
        <EvalRedirect route={`/detail/${data.uuid}?input=${input}`}>
          <Headline>{getWireLabel(data)}</Headline>
        </EvalRedirect>
        <CustomText text={data.description} />
      </Content>
    </StyledMinimized>
  )
}

export const ArticleMinimizedSleeve = ({ data }) => {
  return (
    <StyledMinimized>
      <ImageWrapper>{data.image && <CustomImage src={getImage(data)} />}</ImageWrapper>
      <Content>
        <EvalRedirect route={`/detail/sleeve/${data.uuid}`}>
          <Headline>
            BayRail&reg;
            {` ${data.name || data.product_name_shop}`}
          </Headline>
        </EvalRedirect>
        <CustomText text={data.description} />
      </Content>
    </StyledMinimized>
  )
}
