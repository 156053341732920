// import { useSelector } from 'react-redux'
import { StyledTraining } from './Training.Styled.js'

const Training = ({ handleClose }) => {
  //   const token = useSelector((state) => state.token)

  //   const send = async () => {
  //     const status = await send(token)
  //     if (status) handleClose()
  //   }

  return <StyledTraining></StyledTraining>
}

export default Training
