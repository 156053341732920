import Text from '../../../../UI/Text/Text'

const { default: styled } = require('styled-components')

export const StyledProperties = styled.div``

export const CustomText = styled(Text)`
  font-size: ${({ theme }) => theme.size.xsm};
`

export const Value = styled.div`
  font-size: ${({ theme }) => theme.size.xsm};
  width: 50%;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const ValueWrapper = styled.div`
  width: 50%;
`
