import { useSelector } from 'react-redux'
import { getUUID } from '../../../../../utility'
import { StyledProperties, CustomText, Value, Wrapper } from './Properties.Styled'

const Properties = ({ data, type, propWhitelist = null }) => {
  const translation = useSelector((s) => s.translation)

  const getValue = (attr) => {
    if (!data.sibling) return data[attr]
    return (
      <>
        {data[attr]} {data[attr] !== data.sibling[attr] && `[${data.leiterdurchmesser}er]`}
        <br />
        {data[attr] !== data.sibling[attr] && data.sibling[attr]}{' '}
        {data[attr] !== data.sibling[attr] && `[${data.sibling.leiterdurchmesser}er]`}
      </>
    )
  }

  return (
    <StyledProperties>
      {Object.keys(data)
        .filter((prop) => !propWhitelist || propWhitelist.includes(prop))
        .map((prop) => {
          if (!translation[type][prop] || prop === 'name') return null
          return (
            <Wrapper key={getUUID()}>
              <CustomText key={getUUID()} text={`- ${translation[type][prop]}: `} />
              <Value>{getValue(prop)}</Value>
            </Wrapper>
          )
        })
        .filter((item) => !!item)}
    </StyledProperties>
  )
}

export default Properties
