import { useDispatch, useSelector } from 'react-redux'
import { sendMail } from '../../interface/checkout'
import Button from '../UI/Button/Button'
import Layout from '../UI/Layout/Layout'
import Modal from '../UI/Modal/Modal'
import { useState } from 'react'
import Contact from './Contact/Contact'
import Training from './Training/Training.js'
import Container from '../UI/Container/Container'
import Flex from '../UI/Flex/Flex'
import {
  ButtonWrapper,
  HeroOverlay,
  TextWrapper,
  HeroHeadline,
  HeroText,
  SearchWrapper,
  ButtonWrapperWrapper,
} from './Checkout.Styled'
import Hero from '../UI/Hero/Hero'
import heroImage from '../../assets/image/home/hero.jpg'
import Search from '../Search/Search'
import FullscreenCart from '../FullscreenCart/FullscreenCart'
import customToast, { TYPE } from '../../toast'
import BreadCrumb from '../BreadCrumb/BreadCrunb'
import { getUUID } from '../../utility'
import { setItemsToAddToProjectList } from '../../reducer/action/projectListManager'

const Checkout = () => {
  const translation = useSelector((state) => state.translation)
  const cart = useSelector((state) => state.cart)
  const token = useSelector((state) => state.token)
  const [showTraining, setShowTraining] = useState(false)
  const [showContact, setShowContact] = useState(false)
  const cartSleeveProducts = useSelector((s) => s.cartSleeveProducts)
  const dispatch = useDispatch()

  const send = async () => {
    const status = await sendMail(
      token,
      cart,
      cartSleeveProducts.filter((s) => !!s.count),
    )
    if (status) {
      customToast(TYPE.SUCCESS, translation.checkout.success.send)
    } else {
      customToast(TYPE.ERROR, translation.checkout.error.send)
    }
  }
  const addToProjectList = () => {
    const itemsToAdd = []
    for (const item of cart) {
      itemsToAdd.push({
        ...item.article,
        uuid: item.article.uuid || getUUID(),
        wireArticleNumber: item.article.wireArticleNumber,
        countWireInMeter: item.article.countWireInMeter,
        countSleeve: item.article.countSleeve,
        amount: item.productCount,
      })
    }
    dispatch(setItemsToAddToProjectList(itemsToAdd))
  }

  return (
    <Layout authRequired>
      <Hero img={heroImage}>
        <HeroOverlay>
          <TextWrapper>
            <HeroHeadline text={translation.home.hero.overlay.headline} />
            <HeroText text={translation.home.hero.overlay.text} />
          </TextWrapper>
        </HeroOverlay>
      </Hero>
      <Container>
        <BreadCrumb
          items={[
            { label: 'Homepage', link: '/' },
            { label: 'Suche', link: '/search' },
            { label: 'Anfrageübersicht' },
          ]}
        />
      </Container>
      <Container>
        <SearchWrapper>
          <Search />
        </SearchWrapper>

        <FullscreenCart fullscreen={true} margin="10px 0" />
        <Flex wrap="wrap" justify="center" align="center" gap="20px">
          <ButtonWrapperWrapper>
            <ButtonWrapper>
              <Button
                text={translation.articleDetail.addToProjectList}
                onClick={addToProjectList}
                width="100%"
              />
              <Button text={translation.checkout.send} onClick={send} width="100%" />
            </ButtonWrapper>
          </ButtonWrapperWrapper>
        </Flex>
        {showTraining && (
          <Modal close={() => setShowTraining(false)}>
            <Training handleClose={() => setShowTraining(false)} />
          </Modal>
        )}
        {showContact && (
          <Modal close={() => setShowContact(false)}>
            <Contact handleClose={() => setShowContact(false)} />
          </Modal>
        )}
      </Container>
    </Layout>
  )
}

export default Checkout
