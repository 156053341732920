import styled from 'styled-components'

export const StyledMenu = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  gap: 25px;
`

export const Section = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundGray};
  display: flex;
  gap: 25px;
  padding: 15px;
  justify-content: space-between;
  &:first-child {
    flex: 1 0 0px;
  }
`

export const Item = styled.a`
  color: ${({ theme }) => theme.color.font.thirdary};
  display: block;
  text-decoration: none;
  font-size: ${({ theme }) => theme.size.sm};
  ${({ href, theme }) =>
    href
      ? `
    &:hover {
    color: ${theme.color.primary};
    cursor: pointer;
  }
  `
      : `
      color: ${theme.color.primary};
  font-family: "bold";
  `}
  &.active {
    color: ${({ theme }) => theme.color.primary};
    font-family: bold;
  }
`
