import styled from 'styled-components'

export const StyledWireBuildupAndCode = styled.div`
  margin-top: 50px;
`

export const Headline = styled.div`
  font-family: 'bold';
  color: ${({ theme, highlight }) => (highlight ? theme.color.primary : theme.color.font.thirdary)};
  font-size: ${({ theme }) => theme.size.lg};
  margin-top: 50px;
  margin-bottom: 25px;
`

export const Title = styled.div`
  font-family: 'bold';
  color: ${({ theme, highlight }) => (highlight ? theme.color.primary : theme.color.font.thirdary)};
  margin: 25px 0;
`

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.color.primary};
  margin-bottom: 25px;
`

export const StyledTable = styled.div`
  ${({ margin }) => margin && `margin: ${margin};`};
`

export const Line = styled.div`
  display: flex;
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.color.backgroundGray};
  }
`

export const Cell = styled.div`
  padding: 5px;
  flex: 1 1 0px;
  font-size: ${({ theme }) => theme.size.sm};
  ${({ highlight }) =>
    highlight &&
    `
    font-weight: bold;
  `}
`

export const CellArrayItem = styled.div`
  font-size: inherit;
`

export const Seperator = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.color.primary};
  margin: 50px 0;
`

export const Indent = styled.div`
  margin-left: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-left: 25px;
  }
`

export const Text = styled.div`
  font-size: ${({ theme }) => theme.size.xsm};
`
export const Hint = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  color: ${({ theme }) => theme.color.font.thirdary};
`

export const Split = styled.div`
  display: flex;
`

export const Column = styled.div`
  flex: 1 1 0px;
`

export const Bulletpoints = styled.ul`
  margin-left: 30px;
`

export const Bulletpoint = styled.li`
  font-size: ${({ theme }) => theme.size.sm};
`
export const StyledSection = styled.div`
  ${({ margin }) => margin && `margin: ${margin};`};
`
