import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../UI/Button/Button'
import Checkbox from '../../UI/Checkbox/Checkbox'
import Container from '../../UI/Container/Container'
import Flex from '../../UI/Flex/Flex'
import Layout from '../../UI/Layout/Layout'
import { StyledSettings, Headline, CustomInput, CTAWrapper } from './Settings.Styled'
import toast, { TYPE } from '../../../toast'
import { update, get } from '../../../interface/profile'

const Settings = () => {
  const translation = useSelector((s) => s.translation)
  const token = useSelector((state) => state.token)
  const [profile, setProfile] = useState(null)

  useEffect(() => {
    const getProfile = async () => {
      const profile = await get(token)
      if (profile) {
        setProfile(profile)
      } else {
        toast(TYPE.ERROR, translation.profile.settings.error.get)
      }
    }
    if (!profile && token) {
      getProfile()
    }
  }, [profile, token, translation])

  const updateProp = (prop) => (value) => setProfile({ ...profile, [prop]: value })

  const save = async () => {
    const status = await update(token, profile)
    if (status) {
      toast(TYPE.SUCCESS, translation.profile.settings.success.submit)
    } else {
      toast(TYPE.ERROR, translation.profile.settings.error.submit)
    }
  }

  return (
    <Layout>
      <StyledSettings onSubmit={save}>
        <Container>
          <Headline>{translation.profile.settings.headline}</Headline>
          {profile && (
            <Flex gap="15px" margin="10px 0 0 0">
              <CustomInput
                minimized
                value={profile.lastname}
                onChange={updateProp('lastname')}
                placeholder={translation.profile.settings.name}
                required
              />
              <CustomInput
                minimized
                value={profile.functionWithinCompany}
                onChange={updateProp('functionWithinCompany')}
                placeholder={translation.profile.settings.functionWithinCompany}
                required
              />
              <CustomInput
                minimized
                autoComplete="off"
                value={profile.phone}
                onChange={updateProp('phone')}
                placeholder={translation.generic.phone}
              />
              <CustomInput
                minimized
                value={profile.firstname}
                onChange={updateProp('firstname')}
                placeholder={translation.profile.settings.firstname}
                required
              />
              <CustomInput
                minimized
                value={profile.companyBranch}
                onChange={updateProp('companyBranch')}
                placeholder={translation.profile.settings.companyBranch}
                required
              />
              <CustomInput
                minimized
                type="password"
                autoComplete="off"
                value={profile.password}
                onChange={updateProp('password')}
                placeholder={translation.profile.settings.enterPasswort}
              />
              <CustomInput
                minimized
                type="email"
                value={profile.mail}
                onChange={updateProp('mail')}
                placeholder={translation.profile.settings.mail}
                required
              />
              <CustomInput
                minimized
                value={profile.companyName}
                onChange={updateProp('companyName')}
                placeholder={translation.profile.settings.companyName}
                required
              />
              <Checkbox
                checked={profile.newsletter}
                onChange={updateProp('newsletter')}
                label={translation.profile.settings.newsletter}
              />
              <CTAWrapper>
                <Button minimized type="submit" text={translation.profile.settings.saveCTA} />
              </CTAWrapper>
            </Flex>
          )}
        </Container>
      </StyledSettings>
    </Layout>
  )
}

export default Settings
