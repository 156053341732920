import { useSelector } from 'react-redux'
import { StyledNoResults, Title, Text } from './NoResults.Styled'

const NoResults = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <StyledNoResults>
      <Title>{translation.projectListManager.noResults.title}</Title>
      <Text>{translation.projectListManager.noResults.text}</Text>
    </StyledNoResults>
  )
}

export default NoResults
