import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Button from '../UI/Button/Button'
import {
  StyledSearch,
  Text,
  Strong,
  InputAndSuggestionWrapper,
  CustomForm,
  SuggestionsWrapper,
} from './Search.Styled'
import { suggest } from '../../interface/search'
import toast, { TYPE } from '../../toast'
import Input from '../UI/Input/Input'
import Suggestions from './Suggestions/Suggestions'
import { useDispatch, useSelector } from 'react-redux'
import { setLoginModalContext } from '../../reducer/action/loginModalContext'

const Search = ({ retrigger }) => {
  const token = useSelector((s) => s.token)
  const [searchParams] = useSearchParams()
  const [input, setInput] = useState(searchParams.get('searchString') || '')
  const [suggestions, setSuggestions] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const getSuggestions = async (input) => {
    setInput(input)
    if (input.trim().length > 0) {
      const suggestions = await suggest(input)
      if (suggestions) {
        setSuggestions(suggestions)
      } else {
        toast(TYPE.ERROR, 'Suche nicht möglich.')
      }
    } else {
      setSuggestions([])
    }
  }

  const submit = () => {
    const path = input?.length > 0 ? '/search/?searchString=' + input : '/search'

    if (token) {
      if (retrigger) {
        retrigger(null, input)
      } else {
        navigate(path)
      }
      setSuggestions([])
    } else {
      dispatch(setLoginModalContext({ show: true, route: path }))
    }
  }

  return (
    <StyledSearch>
      <Text>
        Suche nach <Strong>BayRail&reg;</Strong> Signalkabel und Muffen der Deutschen Bahn AG
      </Text>
      <CustomForm onSubmit={submit}>
        <InputAndSuggestionWrapper>
          <Input
            value={input}
            onChange={getSuggestions}
            placeholder={'Name, DB SAP-Nummer, Bayka Typennummer, Typennummer Muffe'}
          />
          {Array.isArray(suggestions) && suggestions.length > 0 && (
            <SuggestionsWrapper>
              <Suggestions
                input={input}
                data={suggestions}
                clickedCallback={() => setSuggestions([])}
              />
            </SuggestionsWrapper>
          )}
        </InputAndSuggestionWrapper>
        <Button text="Suche" type="submit" />
      </CustomForm>
    </StyledSearch>
  )
}

export default Search
