import { useSelector } from 'react-redux'
import { getUUID } from '../../utility'
import BreadCrumb from '../BreadCrumb/BreadCrunb'
import Search from '../Search/Search'
import Container from '../UI/Container/Container'
import Layout from '../UI/Layout/Layout'
import { StyledContact, SectionWrapper } from './Contact.Styled'
import Section from './Section/Section'

const Contact = () => {
  const translation = useSelector((state) => state.translation)

  return (
    <Layout authRequired={false}>
      <StyledContact>
        <Container>
          <Search />
          <BreadCrumb items={[{ label: 'Homepage', link: '/' }, { label: 'Kontakt' }]} />
        </Container>
        <SectionWrapper>
          {translation.contact.sections.map((section) => (
            <Section key={getUUID()} data={section} />
          ))}
        </SectionWrapper>
      </StyledContact>
    </Layout>
  )
}

export default Contact
