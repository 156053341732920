import { StyledFlex } from './Flex.Styled'

const Flex = ({ children, className, gap, margin, wrap, justify, align, flex, width }) => {
  return (
    <StyledFlex
      gap={gap}
      margin={margin}
      className={className}
      wrap={wrap}
      justify={justify}
      align={align}
      flex={flex}
      width={width}
    >
      {children}
    </StyledFlex>
  )
}

export default Flex
