import styled from 'styled-components'

export const StyledGenericInfo = styled.div`
  margin-top: 50px;
  /* width: 90%;
  margin: 0 auto;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: calc(100% - 30px);
  } */
`

export const Headline = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-family: 'bold';
  font-size: ${({ theme }) => theme.size.lg};
`
