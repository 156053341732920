import styled from 'styled-components'

export const StyledToolsAndDownloads = styled.div`
  margin-top: 50px;
`

export const Headline = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-family: 'bold';
`

export const CTASection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 25px;
  margin-top: 15px;
`

export const CTA = styled.div`
  width: calc(33% - 15px);
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: calc(50% - 7.5px);
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(100% - 7.5px);
    text-align: center;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    display: block;
  }
`

export const Title = styled.a`
  color: ${({ theme }) => theme.color.primary};
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: center;
  &:hover {
    text-decoration: underline;
  }
`

export const Image = styled.img`
  height: 75px;
`

export const Text = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.size.sm};
  color: ${({ theme }) => theme.color.font.thirdary};
`

export const Subheadline = styled.div`
  color: ${({ theme }) => theme.color.primary};
  margin: 50px 0px;
`

export const Line = styled.span`
  border-bottom: ${({ theme }) => `1.5px solid ${theme.color.primary}`};
  display: inline-block;
  width: 150px;
  margin-right: 5px;
`
