import styled from 'styled-components'

export const StyledButton = styled.button`
  color: ${({ theme, color }) => color || theme.color.button.font};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.color.button.background};
  padding: 5px 20px;
  width: ${({ width }) => width || 'auto'};
  margin: ${({ margin }) => margin || '0px'};
  border: thin solid ${({ theme }) => theme.color.border};
  display: inline-block;
  text-align: center;
  font-size: ${({ theme, minimized }) => (minimized ? theme.size.sm : theme.size.md)};
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.sm};
  }
`

export const StyledButtonAnchor = styled.a`
  color: ${({ theme, color }) => color || theme.color.button.font};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.color.button.background};
  padding: 5px 20px;
  margin: ${({ margin }) => margin || '0px'};
  border: thin solid ${({ theme }) => theme.color.border};
  text-decoration: none;
  display: inline-block;
  text-align: center;
  width: ${({ width }) => width || 'auto'};
  font-size: ${({ theme, minimized }) => (minimized ? theme.size.sm : theme.size.md)};
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.sm};
  }
`
