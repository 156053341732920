import styled from 'styled-components'
import Image from '../../../../UI/Image/Image'

export const StyledItem = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
`

// export const StyledWire = styled.div`
//   display: flex;
//   gap: 5px;
// `

// export const StyledSleeve = styled.div`
//   display: flex;
//   gap: 5px;
// `

// export const Name = styled.div``

// export const Count = styled.div``

// export const NameColumn = styled.div`
//   width: 60%;
// `
export const CountColumn = styled.div`
  width: 20%;
`
export const DeleteColumn = styled.div`
  width: 20%;
  text-align: center;
`

export const StyledCartItem = styled.div`
  /* border: 1px solid black; */
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  padding-bottom: 10px;
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    /* display: block; */
  }
`

export const ItemLabel = styled.div`
  color: ${({ theme, headline }) => (headline ? theme.color.primary : theme.color.font.primary)};
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 20%;
`

export const ImageWrapper = styled.div`
  flex: 1 1 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const CustomImage = styled(Image)`
  width: 30px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 10px;
  }
`

export const NameColumn = styled.div``

export const StyledWire = styled.div`
  /* display: flex;
  gap: 5px; */
`

export const StyledSleeve = styled.div`
  /* display: flex;
  gap: 5px; */
  margin-top: 5px;
`

export const Name = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.size.sm};
`

export const Count = styled.div`
  color: ${({ theme }) => theme.color.font.thirdary};
  font-size: ${({ theme }) => theme.size.sm};
`

export const ItemLabelCount = styled(ItemLabel)`
  border-top: 1px dashed ${({ theme }) => theme.color.border};
  padding-top: 5px;
`

export const WrapperTitle = styled.div`
  width: 60%;
`

export const ArticleImageWrapper = styled.div`
  width: 20%;
`

export const SingleItemWrapper = styled.div`
  display: flex;
  align-items: center;
  :last-child {
    margin-top: 10px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`

export const CustomButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.color.primary};
  text-decoration: underline;
  font-size: ${({ theme }) => theme.size.sm};
`

export const Number = styled.div`
  color: ${({ theme }) => theme.color.font.thirdary};
  font-size: ${({ theme }) => theme.size.sm};
`
