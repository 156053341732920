import styled from 'styled-components'

export const StyledDropdown = styled.div`
  width: ${({ width }) => width || 'auto'};
  border-radius: ${({ theme }) => theme.border.radius};
  margin: ${({ margin }) => margin || '0px'};
`

export const Select = styled.select`
  padding: 10px 10px;
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.color.black};
  outline: none;
  font-size: ${({ theme }) => theme.size.sm};
  color: ${({ theme, color }) => color || theme.color.white};
  border-radius: ${({ theme }) => theme.border.radius};
  border: thin solid ${({ theme }) => theme.color.white};
  display: block;
  width: 100%;
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.sm};
  }
`

export const Option = styled.option`
  font-size: ${({ theme }) => theme.size.sm};
`

export const Label = styled.div`
  color: ${({ theme, color }) => color || theme.color.black};
  font-size: ${({ theme }) => theme.size.md};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.sm};
  }
`
