import {
  StyledCartItem,
  ButtonWrapper,
  ImageWrapper,
  CustomImage,
  StyledWire,
  Name,
  Count,
  StyledSleeve,
  WrapperTitle,
  ArticleImageWrapper,
  SingleItemWrapper,
  CustomButton,
  Wrapper,
  Number,
} from './Item.Styled.js'
import PlusIcon from '../../../assets/image/plus.png'
import MinusIcon from '../../../assets/image/minus.png'
import { useDispatch, useSelector } from 'react-redux'
import {
  addCartItem,
  removeCartItem,
  removeCartPosition,
  updateCount,
  updateWireAndSleeveCount,
} from '../../../reducer/action/cart.js'
import { useEffect, useState } from 'react'
import { getSingleByArtikelnummer } from '../../../interface/article.js'
// import Image from '../../UI/Image/Image.js'
import deleteIcon from '../../../assets/image/delete.png'
import Input from '../../UI/Input/Input.js'
import SleeveProperties from '../../Article/Detail/WireView/SleeveProperties/SleeveProperties.js'
import Modal from '../../UI/Modal/Modal.js'
import getWireLabel from '../../../helper/getWireLabel.js'
import Flex from '../../UI/Flex/Flex.js'
import Text from '../../UI/Text/Text.js'
import Button from '../../UI/Button/Button.js'
import { setItemToAddToProjectList } from '../../../reducer/action/projectListManager.js'
import SleeveImageMapping from '../../../assets/SleeveImageMapping.js'
import { awsStorageBucketPrefix } from '../../../utility.js'
import Image from '../../UI/Image/Image.js'
import WireImageMapping from '../../../assets/WireImageMapping.js'

const Item = ({ item, overwriteCart }) => {
  const translation = useSelector((state) => state.translation)
  const token = useSelector((s) => s.token)
  const [wire, setWire] = useState(null)
  const [sleeve, setSleeve] = useState(null)
  const [siblingSleeve, setSiblingSleeve] = useState(null)
  const [family, setFamily] = useState(null)
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [countWire, setCountWire] = useState(item?.article?.countWireInMeter)
  const [countSleeve, setCountSleeve] = useState(item?.article?.countSleeve)
  const cart = useSelector((s) => s.cart)

  const wireUUID = item?.article?.wireUUID
  const sleeveUUID = item?.article?.sleeveUUID
  const artikelnummer = item?.article?.wireArticleNumber
  console.log(siblingSleeve)
  useEffect(() => {
    const fetchWireAndSleeve = async () => {
      const { wire, sleeve, family } = await getSingleByArtikelnummer(token, artikelnummer)

      if (wire) {
        setWire(wire)
      }
      if (sleeve) {
        setSleeve(sleeve)
      }
      if (family) {
        setFamily(family)

        const matchingUUID = wire.sibling?.matchingUUID
        console.log(matchingUUID)

        if (matchingUUID) {
          const siblingSleeve = family.sleeves.find((el) => el.matchingUUID === matchingUUID)
          if (siblingSleeve) {
            setSiblingSleeve(siblingSleeve)
          }
        } else {
          const wireIndex = family.wires.findIndex((w) => w.uuid === wire.uuid)
          if (family.wires[wireIndex]?.sibling) {
            const siblingSleeve = family.sleeves.find(
              (el) => el.matchingUUID === family.wires[wireIndex].sibling.matchingUUID,
            )

            if (siblingSleeve) {
              setSiblingSleeve(siblingSleeve)
            }
          }
        }
      }
    }

    if (token && !wire && !sleeve && artikelnummer) fetchWireAndSleeve()
  }, [token, wire, sleeve, wireUUID, sleeveUUID, artikelnummer])

  const removePosition = (item) => () => dispatch(removeCartPosition(item))

  const decrement = (item) => () => dispatch(removeCartItem(item))

  const increment = (item) => () => dispatch(addCartItem(item))

  const changeCount = (count) => {
    dispatch(updateCount({ item, count }))
  }

  const getLabel = (wire, respectSibling = true) => {
    let label = `${wire.anzahlverseilelemente}x${wire.verseilelement}x${
      wire.leiterdurchmesser || wire.leiterdurchmesserinmm
    }`
    if (wire.rkkennzahl) {
      label += ` (${translation.wire.rkkennzahl} ${wire.rkkennzahl})`
    }
    if (wire.sibling) {
      label += ` + ${getLabel(wire.sibling, false)}`
    } else if (family) {
      const wireIndex = family.wires.findIndex((w) => w.uuid === wire.uuid)

      const sibling = family.wires[wireIndex]?.sibling

      if (sibling && respectSibling) {
        label += ` + ${getLabel(sibling, false)}`
      }
    }
    return label
  }

  const saveCountChange = () => {
    const cartIndex = cart.findIndex((el) => el.article.uuid === item.article.uuid)

    dispatch(updateWireAndSleeveCount(cartIndex, countWire, countSleeve))

    setShowModal(false)
  }

  const addToProjectList = () => {
    const payload = {
      ...item.article,
      amount: item.productCount,
    }
    dispatch(setItemToAddToProjectList(payload))
  }

  const getImage = () => {
    if (wire) {
      const foundMapping = WireImageMapping.find(
        (el) => el.TITYPN.toString() === wire.artikelnummerbayka,
      )

      if (foundMapping) {
        const url = awsStorageBucketPrefix + 'product_pictures/' + foundMapping.Bild
        return url
      }
    }
    if (!wire && !sleeve) {
      const foundMapping = SleeveImageMapping.find(
        (el) => el.id.toString() === item.article.parent_id,
      )

      if (foundMapping) {
        const url = awsStorageBucketPrefix + 'product_pictures/' + foundMapping.Bild
        return url
      }

      return item?.article?.image || wire?.image
    }
  }

  return (
    <StyledCartItem key={item.article.uuid}>
      <WrapperTitle>
        {!wire && !sleeve && (
          <SingleItemWrapper>
            <ArticleImageWrapper>
              <Image margin="0 15px 0 0" maxWidth="80%" height="70px" src={getImage()} />
            </ArticleImageWrapper>
            <div>
              <Name headline>
                {item?.article?.TIBEZ1
                  ? `${item.article.TIBEZ1} ${item.article.TIBEZ2}`
                  : item.article.name}
              </Name>
              {item?.article?.TITYPN && (
                <Number>{`${translation.cart.id} ${item.article.TITYPN}`}</Number>
              )}
            </div>
          </SingleItemWrapper>
        )}
        {wire && (
          <SingleItemWrapper>
            <ArticleImageWrapper>
              <Image margin="0 15px 0 0" height="70px" src={getImage()} />
            </ArticleImageWrapper>

            <StyledWire>
              <Name>{`${translation.profile.projectLists.list.item.labelWire} ${
                wire.name
              } ${getLabel(wire)}`}</Name>
              <Number>{`${translation.profile.projectLists.list.item.labelNumber} ${wire.artikelnummerbayka}`}</Number>
              <Count>{`${item?.article?.countWireInMeter} ${translation.profile.projectLists.list.item.countWire}`}</Count>
            </StyledWire>
          </SingleItemWrapper>
        )}
        {sleeve && (
          <SingleItemWrapper>
            <ArticleImageWrapper>
              <Image
                margin="0 15px 0 0"
                height="70px"
                src={item?.article?.image || sleeve?.image}
              />
            </ArticleImageWrapper>

            <StyledSleeve>
              <Name>{`${translation.profile.projectLists.list.item.labelSleeve}`}</Name>
              <Count>{`${item?.article?.countSleeve || 0} ${
                translation.profile.projectLists.list.item.countSleeve
              }`}</Count>
              <SleeveProperties
                data={sleeve}
                type="sleeve"
                siblingSleeve={siblingSleeve}
                propWhitelist={[
                  'schutzmuffe',
                  'mengeschutzmuffe',
                  'TITYPN',
                  'innenmuffe',
                  'mengeinnenmuffe',
                  'quetschverbinder',
                  'mengequetschverbinder',
                  'schirmverbindungsleitung',
                  'mengeschirmverbindungsleitung',
                  'thermoplastischesabdichtband',
                  'mengethermoplastischesabdichtband',
                  'schrumpfschlauch',
                  'mengeschrumpfschlauch',
                  'erdseil',
                  'mengeerdseil',
                  'bausatz',
                  'mengebausatz',
                ]}
              />
            </StyledSleeve>
          </SingleItemWrapper>
        )}
        <SingleItemWrapper>
          <ArticleImageWrapper></ArticleImageWrapper>
          <Wrapper>
            {!overwriteCart && wire && (
              <CustomButton onClick={() => setShowModal(true)}>Ändern</CustomButton>
            )}
            <Button onClick={addToProjectList} text={'zu Projektliste hinzufügen'}></Button>
          </Wrapper>
        </SingleItemWrapper>
      </WrapperTitle>
      <ButtonWrapper>
        {!overwriteCart && (
          <ImageWrapper>
            <CustomImage src={MinusIcon} alt="minus" onClick={decrement(item)} />
          </ImageWrapper>
        )}

        <Input
          disabled={!!overwriteCart}
          type="number"
          value={item.productCount}
          onChange={changeCount}
          width="50%"
        />
        {!overwriteCart && (
          <ImageWrapper>
            <CustomImage src={PlusIcon} alt="plus" onClick={increment(item)} />
          </ImageWrapper>
        )}
      </ButtonWrapper>
      {!overwriteCart && (
        <ButtonWrapper>
          <CustomImage src={deleteIcon} cursor="pointer" onClick={removePosition(item)} />
        </ButtonWrapper>
      )}

      {showModal && (
        <Modal
          close={() => setShowModal(false)}
          title={getWireLabel(wire)}
          footerContent={
            <Flex justify="flex-end">
              <Button text="Speichern" onClick={saveCountChange} />
            </Flex>
          }
        >
          <Flex wrap="nowrap" margin="0 0 10px 0">
            <Text text={translation.checkout.wireCount} />
            <Input type="number" value={countWire} onChange={(value) => setCountWire(value)} />
          </Flex>
          <Flex wrap="nowrap">
            <Text text={translation.checkout.sleeveCount} />
            <Input type="number" value={countSleeve} onChange={(value) => setCountSleeve(value)} />
          </Flex>
        </Modal>
      )}
    </StyledCartItem>
  )
}

export default Item
