import styled, { keyframes } from 'styled-components'
import Image from '../Image/Image'
import Text from '../Text/Text'
import Container from '../Container/Container'
import { pulse } from 'react-animations'

const incomingAnimation = keyframes`${pulse}`

export const StyledModal = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 997;
`

export const Background = styled.div`
  z-index: 996;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  /* background: rgba(0, 0, 0, 0.1); */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
`

export const Core = styled(Container)`
  position: absolute;
  z-index: 998;
  background-color: ${({ theme }) => theme.color.backgroundGray};
  box-shadow: ${({ theme }) => theme.shadow};
  margin-left: auto;
  margin-right: auto;
  animation: 0.25s ${incomingAnimation};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(100vw - 60px);
    margin: 0px;
  }
`
export const Header = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.color.white};
  align-items: center;
  height: 70px;
  width: inherit;
  border-bottom: thin solid ${({ theme }) => theme.color.primary};
`
export const Title = styled(Text)`
  flex: 1 1 0px;
  font-weight: bold;
`
export const Close = styled(Image)`
  height: 20px;
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 15px;
  }
`
export const Content = styled.div`
  padding: 15px 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 70vh;
`

export const Footer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.color.backgroundGray};
  width: inherit;
  border-top: thin solid ${({ theme }) => theme.color.primary};
  padding: 15px 0px;
`
