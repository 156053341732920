import styled from 'styled-components'

export const StyledList = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
`

export const Name = styled.div`
  font-family: 'bold';
  /* cursor: pointer; */
  /* &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.color.primary};
  } */
  color: ${({ theme }) => theme.color.primary};
  margin-bottom: 10px;
`

export const Comment = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  color: ${({ theme }) => theme.color.font.thirdary};
`

export const NameWrapper = styled.div`
  width: 60%;
`

export const ButtonWrapper = styled.div`
  width: 40%;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
`
