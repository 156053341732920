import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import fetch, { METHOD } from '../../../fetch'
import { getPrettyDate } from '../../../utility'
import Button from '../../UI/Button/Button'
import Container from '../../UI/Container/Container'
import Hero from '../../UI/Hero/Hero'
import Layout from '../../UI/Layout/Layout'
import heroImage from '../../../assets/image/home/hero.jpg'
import {
  StyledCarts,
  StyledCart,
  Name,
  Positions,
  NameWrapper,
  ButtonWrapper,
  HeroOverlay,
  Headline,
  TextWrapper,
  HeroHeadline,
  HeroText,
  SearchWrapper,
} from './Carts.Styled.js'
import Search from '../../Search/Search'

const Carts = () => {
  const [carts, setCarts] = useState(null)
  const token = useSelector((state) => state.token)
  const translation = useSelector((s) => s.translation)

  const navigate = useNavigate()

  useEffect(() => {
    const fetchCarts = async () => {
      const { status, carts } = await fetch(METHOD.GET, '/cart/list', { token })
      if (status && carts) {
        setCarts(carts)
      }
    }

    if (token && !carts) fetchCarts()
  }, [carts, token])

  return (
    <Layout>
      <Hero img={heroImage}>
        <HeroOverlay>
          <TextWrapper>
            <HeroHeadline text={translation.home.hero.overlay.headline} />
            <HeroText text={translation.home.hero.overlay.text} />
          </TextWrapper>
        </HeroOverlay>
      </Hero>
      <Container>
        <StyledCarts>
          <SearchWrapper>
            <Search />
          </SearchWrapper>
          <Headline>{translation.cart.headline}</Headline>
          {Array.isArray(carts) &&
            carts.map((cart) => (
              <StyledCart key={cart.uuid}>
                <NameWrapper>
                  <Name>{getPrettyDate(new Date(cart.createdOn))}</Name>
                  <Positions>{`${cart.cart.length} ${
                    cart.cart.length > 1 ? translation.cart.positions : translation.cart.position
                  }`}</Positions>
                </NameWrapper>
                <ButtonWrapper>
                  <Button
                    text={translation.profile.projectLists.list.show}
                    onClick={() => navigate(`/profile/carts/${cart.uuid}`)}
                  />
                </ButtonWrapper>
              </StyledCart>
            ))}
        </StyledCarts>
      </Container>
    </Layout>
  )
}

export default Carts
