import styled from 'styled-components'

export const StyledLoginOrSignUp = styled.div`
  gap: 15px;
  text-align: center;
`
export const Login = styled.div`
  width: 100%;
`
export const SignUp = styled.div`
  width: 100%;
`

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundGray};
  padding: 20px;
`

export const Title = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
`

export const Strong = styled.span`
  font-size: ${({ theme }) => theme.size.sm};
  font-family: 'bold';
`

export const ButtonSwitchWrapper = styled.div`
  margin-top: 10px;
`
