import { useSelector } from 'react-redux'
import { getUUID } from '../../../utility'
import Image from '../../UI/Image/Image'
import {
  StyledWireBuildupAndCode,
  Headline,
  Title,
  StyledTable,
  Line,
  Cell,
  CellArrayItem,
  Seperator,
  Text,
  Subtitle,
  Indent,
  Hint,
  Split,
  Column,
  Bulletpoints,
  Bulletpoint,
  StyledSection,
} from './WireBuildupAndCode.Styled'
import twoWiresAsIllustration from '../../../assets/image/genericInfo/two-wires-as-illustration.jpg'
import wireInsight from '../../../assets/image/genericInfo/wire-insight.jpg'
import threeWireInsight from '../../../assets/image/genericInfo/three-wire-insight.jpg'
import fourWireAsIllustration from '../../../assets/image/genericInfo/four-wires-as-illustration.jpg'
import multipleCurves from '../../../assets/image/genericInfo/multiple-curves.jpg'
import right from '../../../assets/image/genericInfo/right.jpg'
import wrong from '../../../assets/image/genericInfo/wrong.jpg'
import openWire from '../../../assets/image/genericInfo/open-wire.jpg'
import openWire2 from '../../../assets/image/genericInfo/open-wire-2.jpg'
import wireGray from '../../../assets/image/genericInfo/wire-gray.jpg'
import splitImage from '../../../assets/image/genericInfo/split-image.jpg'

const Section = ({ entries, margin }) => {
  return (
    <StyledSection margin={margin}>
      {entries.map((e) => {
        if (Array.isArray(e)) {
          return (
            <Bulletpoints key={getUUID()}>
              {e.map((item) => (
                <Bulletpoint key={getUUID()}>{item}</Bulletpoint>
              ))}
            </Bulletpoints>
          )
        }
        return <Text key={getUUID()}>{e}</Text>
      })}
    </StyledSection>
  )
}

const Table = ({ data, margin, highlightFirstRow }) => {
  return (
    <StyledTable margin={margin}>
      {data.map((dataCells, i) => (
        <Line key={getUUID()}>
          {dataCells.map((cell) => (
            <Cell highlight={highlightFirstRow && i === 0} key={getUUID()}>
              {Array.isArray(cell)
                ? cell.map((cI) => (
                    <CellArrayItem key={getUUID()}>
                      {cI}
                      <br />
                    </CellArrayItem>
                  ))
                : cell}
            </Cell>
          ))}
        </Line>
      ))}
    </StyledTable>
  )
}

const WireBuildupAndCode = () => {
  const translation = useSelector((s) => s.translation)

  const tableDataWireBuildupAndCode = [
    ['A', 'Außenkabel'],
    ['AJ', 'Außenkabel mit Induktionsschutz'],
    ['2Y', 'Isolierhülle der Leiter, Schutzhülle, bzw. Mantel aus PE'],
    ['OF', 'Füllung der Kabelseele mit kapazitätsarmer Füllmasse'],
    ['(L)2Y', 'Schichtenmantel aus PE'],
    ['V', 'Mantel mit erhöhter Wanddicke'],
    ['D', 'konzentrischer Schirm aus Kupferdrähten'],
    ['B', 'Bewehrung aus Stahlband'],
    ['(SR)', 'Bewehrung aus Stahlrill-Mantel'],
    ['S', 'Signalkabel'],
    ['(H45), (H...)', 'Höchstwert der Betriebskapazität'],
    ['rK', 'Reduktionsfaktor-Kennzahl'],
    ['BayRail®', 'verbesserte Eigenschaften nach Werksnorm Bayka'],
  ]

  const tableDataSleevesGeneric = [
    ['Signalkabel bis 2012', 'was wurde angepasst', 'Signalkabel heute (ab 2012)'],
    ['A-2Y2YV', 'wurde ersetzt durch', 'A-2YOF(L)2YB2Y'],
    ['A-2Y2YB2Y', 'wurde ersetzt durch', 'A-2YOF(L)2YB2Y'],
    ['A-2Y(L)2YV', 'wurde ersetzt durch', 'A-2Y(L)2YB2Y'],
    ['A-2Y(L)2YB2Y', 'bleibt (unverändert)', 'A-2Y(L)2YB2Y'],
    ['AJ-2Y2YDB2Y', 'wurde ersetzt durch', 'AJ-2YOF(L)2YDB2Y'],
    ['AJ-2Y(L)2YDB2Y', 'bleibt (unverändert)', 'AJ-2Y(L)2YDB2Y'],
  ]

  const tableDataVasssvsm = [
    ['Innenmuffe SVSM', 'Schutzmuffe VASS'],
    ['SVSM 1', 'VASS-75/15-830'],
    ['SVSM 2', 'VASS-75/15-830'],
    ['SVSM 3', 'VASS-100/25-890'],
    ['SVSM 4/1', 'VASS-125/30-1080'],
    ['SVSM 5', 'VASS-140/34-1130'],
  ]

  const tableDataVasssvsm1 = [
    [
      'Größe',
      'L1 Länge der Manschette ungeschrumpft',
      'L2 Länge der Schutzeinlage',
      'B Max. Absetzmaß Spleißbereich',
      'Max. Maße über Schutzeinlage',
      '',
    ],
    ['', '', '', '', 'Durchm.', 'Umfang'],
    ['SVSM 1', '460 mm', '260 mm', '200 mm', '43 mm', '135 mm'],
    ['SVSM 2', '500 mm', '285 mm', '220 mm', '55 mm', '172 mm'],
    ['SVSM 3', '560 mm', '360 mm', '300 mm', '75 mm', '235 mm'],
    ['SVSM 4/1', '750 mm', '540 mm', '480 mm', '100 mm', '315 mm'],
    ['SVSM 5', '800 mm', '560 mm', '500 mm', '125 mm', '393 mm'],
  ]

  const tableDataVasssvsm2 = [
    [
      'Größe',
      'L1 Länge der Manschette ungeschrumpft',
      'A Absetzmaße der äußeren Schutzhülle',
      'L2 Länge der Schutzeinlage',
      'B Max. Absetzmaß Spleißbereich',
      'Max. Maße über Schutzeinlage',
      '',
    ],
    ['', '', '', '', '', 'Durchm.', 'Umfang'],
    ['SVSM 1', '460 mm', '400 mm', '260 mm', '200 mm', '43 mm', '135 mm'],
    ['SVSM 2', '500 mm', '440 mm', '285 mm', '220 mm', '55 mm', '172 mm'],
    ['SVSM 3', '560 mm', '500 mm', '360 mm', '300 mm', '75 mm', '235 mm'],
    ['SVSM 4/1', '750 mm', '690 mm', '540 mm', '480 mm', '100 mm', '315 mm'],
    ['SVSM 5', '800 mm', '740 mm', '560 mm', '500 mm', '125 mm', '393 mm'],
  ]
  const tableDataVasssvsm3 = [
    [
      'Größe',
      'L1 Länge der Manschette ungeschrumpft',
      'A Absetzmaße der äußeren Schutzhülle',
      'B Absetzmaß Induktionsschutz- Bewehrung',
      'L2 Länge der Schutzeinlage',
      'C Max. Absetzmaß Spleißbereich',
      'Max. Maße über Schutzeinlage',
      '',
    ],
    ['', '', '', '', '', 'Durchm.', 'Umfang'],
    ['SVSM 1', '460 mm', '760 mm', '620 mm', '260 mm', '200 mm', '43 mm', '135 mm'],
    ['SVSM 2', '500 mm', '760 mm', '620 mm', '285 mm', '220 mm', '55 mm', '172 mm'],
    ['SVSM 3', '560 mm', '820 mm', '680 mm', '360 mm', '300 mm', '75 mm', '235 mm'],
    ['SVSM 4/1', '750 mm', '1010 mm', '870 mm', '540 mm', '480 mm', '100 mm', '315 mm'],
    ['SVSM 5', '800 mm', '1060 mm', '920 mm', '560 mm', '500 mm', '125 mm', '393 mm'],
  ]
  const tableDataVasssvsm4 = [
    ['Größenbezeichnungen', 'A-2Y2YV', 'A-2Y(L)2YV', 'B Max. Absetzmaß'],
    [
      '',
      'Anzahl Kabeladern Leiter 0.9 bis 1.8 mm',
      'Anzahl Sternvierer Leiter 0.9 bis 1.8 mm',
      'Kabelmantel (mm)',
    ],

    ['SVSM 1', 'bis 20', 'bis 5', '200'],
    ['SVSM 2', '24 bis 40', '7 bis 10', '220'],
    ['SVSM 3', '50 bis 100', '14 bis 20', '300'],
    ['SVSM 4/1', '120 bis 160', '30 bis 40', '480'],
    ['SVSM 5', '180 bis 200', '', '500'],
  ]
  const tableDataVasssvsm5 = [
    [
      'Größenbezeichnungen',
      'A-2Y2YB2Y',
      'A-2YOF(L)2YB2Y',
      'A-2Y(L)2YB2Y',
      'A Absetzmaß',
      'B Max. Absetzmaß',
    ],
    [
      '',
      'Anzahl Kabeladern Leiter 0.9 bis 1.8 mm',
      'Anzahl Kabeladern Leiter 0.9 bis 1.8 mm',
      'Anzahl Sternvierer Leiter 0.9 bis 1.4 mm',
      'äußere Schutzhülle (mm)',
      'Kabelmantel (mm)',
    ],
    ['SVSM 1', 'bis 20', 'bis 20', 'bis 5', '400', '200'],
    ['SVSM 2', '24 bis 40', '24 bis 40', '7 bis 10', '440', '220'],
    ['SVSM 3', '50 bis 100', '50 bis 100', '14 bis 20', '500', '300'],
    ['SVSM 4/1', '120 bis 160', '120 bis 160', '30 bis 40', '690', '480'],
    ['SVSM 5', '180 bis 200', '180 bis 200', '', '740', '500'],
  ]
  const tableDataVasssvsm6 = [
    [
      'Größenbezeichnungen',
      'AJ-2Y2YDB2Y',
      'AJ-2YOF(L)2YDB2Y',
      'AJ-2Y(L)2YDB2Y',
      'A Absetzmaß',
      'B Absetzmaß',
      'C Max. Absetzmaß',
    ],
    [
      '',
      'Anzahl Kabeladern Leiter 0.9 bis 1.8 mm',
      'Anzahl Kabeladern Leiter 0.9 bis 1.8 mm',
      'Anzahl Sternvierer Leiter 0.9 bis 1.4 mm',
      'äußere Schutzhülle (mm)',
      'Stahlbandbewehrung (mm)',
      'Kabelmantel / Schirm (mm)',
    ],
    ['SVSM 1', 'bis 20', 'bis 20', 'bis 5', '640', '500', '200'],
    ['SVSM 2', '24 bis 40', '24 bis 40', '7 bis 10', '675', '535', '220'],
    ['SVSM 3', '50 bis 100', '50 bis 100', '14 bis 20', '730', '590', '300'],
    ['SVSM 4/1', '120 bis 160', '120 bis 160', '30 bis 40', '910', '770', '480'],
    ['SVSM 5', '180 bis 200', '180 bis 200', '', '970', '830', '500'],
  ]
  const tableDataVasssvsm7 = [
    ['Produkt', 'Bezeichnung', 'Maße', 'Verwendung/Bemerkung'],

    [
      'Zubehör für Abzweige Quetschverbinder DURASEAL®',
      ['D-406-0001CS100 (rot)', 'D-406-0002CS100 (blau)'],
      ['für Adern Ø 0,9 mm', 'für Adern Ø 1,4/1,8 mm'],
      'Zur Herstellung wasserdichter isolierter Verbindung von Adern',
    ],
    [
      'Schirmanschlussleitung, Farbe schwarz',
      ['SHCO-CRO/CRO-2,5-ST-00-0300', 'SHCO-CRO/CRO-2,5-ST-00-0500', 'SHCO-CRO/CRO-2,5-ST-00-0700'],
      ['300 mm', '500 mm', '700 mm'],
      'Zur Durchverbindung des statischen Schirmes im Schichtenmantel',
    ],
    [
      'Bausatz LK (2 St Rollfedern, 1 St Rolle verzinntes, Kupfergewebeband, 2 St Kontaktbleche)',
      ['', 'LK 0', 'LK 1', 'LK 2', 'LK 3', 'LK 4'],
      [
        'für Schirm/Bewehrung Ø:',
        '12 - 20 mm',
        '17 - 29 mm',
        '30 - 40mm',
        '40 - 60 mm',
        '50 - 75 mm',
      ],
      'Zur lötfreien Kontaktierung des Erdseiles mit dem konzentrischen Schirm/der Induktionsschutzbewehrung sowie Bewehrung und Metallmantel. Für jeden Kabelabgang an der VASM-B wird 1 Bausatz LK benötigt.',
    ],
    [
      'Thermoplastisches Abdichtband',
      'KL-FSTW-DE01',
      '1,80 m',
      'Zur Herstellung des Schutzwickels',
    ],
    [
      'Kabelbinder',
      'E7207-3687-DE01',
      '7,8 x 368 UV Resist',
      'Zur Endbefestigung des thermoplastischen Abdichtbandes',
    ],
    [
      'Erdseil (Kupferflachlitze)',
      ['', 'EPPA-013-1-A', 'EPPA-013-2-A', 'EPPA-013-4-A', 'EPPA-013-3-A', 'EPPA-013-5-A'],
      ['Querschnitte:', '7,0 mm ²', '12,5 mm ²', '16,0 mm ²', '25,0 mm ²', '35,0 mm ²'],
      'Zur Durchverbindung des konzentrischen Schirmes/der Induktionsschutz-Bewehrung',
    ],
    [
      'Schrumpfschlauch',
      ['LSTT 9.5-0-SP', 'LSTT 12.7-0-SP', 'LSTT 19.07-0-SP'],
      ['für EPPA-013-1-A', 'für EPPA-013-2-A und 013-4-A', 'für EPPA-013-3-A und 013-5-A'],
      'Zur Isolierung der Erdseile (Kupferflachlitze)',
    ],
  ]

  return (
    <StyledWireBuildupAndCode>
      <Headline highlight>{translation.genericInfo.wire.headline}</Headline>
      <Headline id="wire-buildup-and-code">
        {translation.genericInfo.wireBuildupAndCode.headline}
      </Headline>
      <Title>{translation.genericInfo.wireBuildupAndCode.code.title}</Title>
      <Table data={tableDataWireBuildupAndCode} />
      <Seperator />
      <Title>{translation.genericInfo.wireBuildupAndCode.testLeads.title}</Title>
      <Text>{translation.genericInfo.wireBuildupAndCode.testLeads.text}</Text>
      <Seperator />
      <Title>{translation.genericInfo.wireBuildupAndCode.counting.title}</Title>
      <Split>
        <Column>
          <Subtitle>
            {translation.genericInfo.wireBuildupAndCode.counting.coreStranding.title}
          </Subtitle>
          <Indent>
            <Title>{translation.genericInfo.wireBuildupAndCode.counting.coreStranding.code}</Title>
            <Image src={twoWiresAsIllustration} maxWidth="100%" />
            <Hint>{translation.genericInfo.wireBuildupAndCode.counting.coreStranding.hint}</Hint>
            <Title>{translation.genericInfo.wireBuildupAndCode.counting.layerBuild.title}</Title>
            <Image src={wireInsight} maxWidth="100%" />
            <Hint>{translation.genericInfo.wireBuildupAndCode.counting.layerBuild.hint}</Hint>
          </Indent>
        </Column>
        <Column>
          <Subtitle>
            {translation.genericInfo.wireBuildupAndCode.counting.quadrupleStranding.title}
          </Subtitle>
          <Indent>
            <Title>{translation.genericInfo.wireBuildupAndCode.counting.coreStranding.code}</Title>
            <Image src={fourWireAsIllustration} maxWidth="100%" />
            <Hint>{translation.genericInfo.wireBuildupAndCode.counting.coreStranding.hint}</Hint>
            <Title>{translation.genericInfo.wireBuildupAndCode.counting.layerBuild.title}</Title>
            <Image src={threeWireInsight} maxWidth="100%" />
            <Hint>
              {translation.genericInfo.wireBuildupAndCode.counting.quadrupleStranding.hint}
            </Hint>
          </Indent>
        </Column>
      </Split>
      <Seperator />
      <Title>
        {translation.genericInfo.wireBuildupAndCode.overviewOfReductionFactorRanges.title}
      </Title>
      <Text>
        {translation.genericInfo.wireBuildupAndCode.overviewOfReductionFactorRanges.text1}
      </Text>
      <Text>
        {translation.genericInfo.wireBuildupAndCode.overviewOfReductionFactorRanges.text2}
      </Text>
      <Bulletpoints>
        {translation.genericInfo.wireBuildupAndCode.overviewOfReductionFactorRanges.bulletpoints.map(
          (b) => (
            <Bulletpoint key={getUUID()}>{b}</Bulletpoint>
          ),
        )}
      </Bulletpoints>
      <Text>
        {translation.genericInfo.wireBuildupAndCode.overviewOfReductionFactorRanges.text3}
      </Text>
      <Image src={multipleCurves} maxWidth="100%" />
      <Seperator />
      <Headline id="guidelines">{translation.genericInfo.guidelines.headline}</Headline>
      <Section entries={translation.genericInfo.guidelines.section1} />
      <Section margin="50px 0 25px 0" entries={translation.genericInfo.guidelines.section2} />
      <Section entries={translation.genericInfo.guidelines.section3} />
      <Seperator />
      <Headline id="transportation-and-storage">
        {translation.genericInfo.transportationAndStorage.headline}
      </Headline>
      <Section entries={translation.genericInfo.transportationAndStorage.section} />
      <Split>
        <Column>
          <Image src={right} maxWidth="100%" />
        </Column>
        <Column>
          <Image src={wrong} maxWidth="100%" />
        </Column>
      </Split>
      <Seperator />
      <Headline highlight>{translation.genericInfo.sleeves.headline}</Headline>
      <Headline id="generic">{translation.genericInfo.sleeves.generic.title}</Headline>
      <Section entries={translation.genericInfo.sleeves.generic.section} />
      <Table margin={'25px 0'} data={tableDataSleevesGeneric} highlightFirstRow />
      <Text>{translation.genericInfo.sleeves.generic.text}</Text>
      <Seperator />
      <Split>
        <Column>
          <Title>{translation.genericInfo.sleeves.svsm.title}</Title>
          <Section entries={translation.genericInfo.sleeves.svsm.section} margin="0 0 25px 0" />
          <Image src={openWire} maxWidth="100%" />
        </Column>
        <Column>
          <Title>{translation.genericInfo.sleeves.vass.title}</Title>
          <Section entries={translation.genericInfo.sleeves.vass.section} margin="0 0 25px 0" />
          <Image src={wireGray} maxWidth="100%" />
          <Image src={openWire2} maxWidth="100%" />
        </Column>
      </Split>
      <Seperator />
      <Headline id="mapping-table">{translation.genericInfo.sleeves.vasssvsm.headline}</Headline>
      <Table highlightFirstRow data={tableDataVasssvsm} />
      <Title highlight>{translation.genericInfo.sleeves.vasssvsm.title1}</Title>
      <Table highlightFirstRow data={tableDataVasssvsm1} />
      <Title highlight>{translation.genericInfo.sleeves.vasssvsm.title2}</Title>
      <Table highlightFirstRow data={tableDataVasssvsm2} />
      <Title highlight>{translation.genericInfo.sleeves.vasssvsm.title3}</Title>
      <Table highlightFirstRow data={tableDataVasssvsm3} />
      <Title highlight>{translation.genericInfo.sleeves.vasssvsm.title4}</Title>
      <Table highlightFirstRow data={tableDataVasssvsm4} />
      <Table margin="25px 0 0 0" highlightFirstRow data={tableDataVasssvsm5} />
      <Table margin="25px 0 25px 0" highlightFirstRow data={tableDataVasssvsm6} />
      <Image src={splitImage} maxWidth="100%" />
      <Title>{translation.genericInfo.sleeves.vasssvsm.title7}</Title>
      <Table highlightFirstRow data={tableDataVasssvsm7} />
    </StyledWireBuildupAndCode>
  )
}

export default WireBuildupAndCode
