import styled from 'styled-components'
import Form from '../../UI/Form/Form'
import Input from '../../UI/Input/Input'

export const StyledSignUpForm = styled(Form)``

export const CustomInput = styled(Input)`
  min-width: 30%;
  flex: 1 1 0px;
`
export const CTAWrapper = styled.div`
  /* flex: 0 0 31.9%; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
