import styled from 'styled-components'
import Text from '../../UI/Text/Text'

export const StyledCarts = styled.div``

export const StyledCart = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
`

export const Headline = styled.div`
  margin-top: 50px;
  color: ${({ theme }) => theme.color.primary};
  font-family: 'bold';
  font-size: ${({ theme }) => theme.size.lg};
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
`

export const Name = styled.div`
  font-family: 'bold';
  /* cursor: pointer; */
  /* &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.color.primary};
  } */
  color: ${({ theme }) => theme.color.primary};
  margin-bottom: 10px;
`

export const Positions = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  color: ${({ theme }) => theme.color.font.thirdary};
`

export const NameWrapper = styled.div`
  width: 60%;
`

export const ButtonWrapper = styled.div`
  width: 40%;
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: row-reverse;
`

export const HeroOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  width: 30%;
  height: inherit;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-right: 15px solid ${({ theme }) => theme.color.primary};
`

export const TextWrapper = styled.div`
  padding: 50px;
`

export const HeroHeadline = styled(Text)`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.size.lg};
  text-transform: uppercase;
  font-family: 'bold';
`

export const HeroText = styled(Text)`
  margin-top: 10px;
  color: ${({ theme }) => theme.color.font.secondary};
`

export const SearchWrapper = styled.div`
  width: 70%;
  margin-left: 15%;
`
