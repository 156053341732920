import { useSelector } from 'react-redux'
import Modal from '../UI/Modal/Modal'
import { useDispatch } from 'react-redux'
import {
  setItemsToAddToProjectList,
  setItemToAddToProjectList,
} from '../../reducer/action/projectListManager'
import { useEffect, useState } from 'react'
import { list } from '../../interface/projectList'
import toast, { TYPE } from '../../toast'
import { StyledProjectListManager, CTAList } from './ProjectListManager.Styled'
import Button from '../UI/Button/Button'
import Lists from './Lists/Lists'
import NoResults from './NoResults/NoResults'
import NewListView from './NewListView/NewListView'

const ProjectListManager = () => {
  const token = useSelector((s) => s.token)
  const { itemToAdd, itemsToAdd } = useSelector((s) => s.projectListManager)
  const translation = useSelector((s) => s.translation)
  const dispatch = useDispatch()
  const [lists, setLists] = useState(null)
  const [showNewListView, setShowNewListView] = useState(false)

  useEffect(() => {
    const fetchLists = async () => {
      const projectLists = await list(token)
      if (projectLists) {
        setLists(projectLists)
      } else {
        toast(TYPE.ERROR, translation.projectListManager.error.loading)
      }
    }
    if (!lists && token && (itemToAdd || itemsToAdd)) {
      fetchLists()
    }
  }, [itemToAdd, token, lists, translation, itemsToAdd])

  const close = () => {
    dispatch(setItemToAddToProjectList(null))
    dispatch(setItemsToAddToProjectList(null))
    setLists(null)
  }

  const closeNewView = (newProjectList) => {
    if (newProjectList) {
      setLists([newProjectList, ...lists])
    }
    setShowNewListView(false)
  }

  if (!lists) return <></>
  return (
    <Modal close={close} title={translation.projectListManager.title}>
      <StyledProjectListManager>
        {showNewListView && <NewListView close={closeNewView} />}
        {!showNewListView && lists.length === 0 && <NoResults />}
        {!showNewListView && lists.length > 0 && (
          <Lists itemToAdd={itemToAdd} itemsToAdd={itemsToAdd} lists={lists} close={close} />
        )}
        <CTAList>
          {!showNewListView && (
            <Button
              text={translation.projectListManager.newCTA}
              onClick={() => setShowNewListView(true)}
            />
          )}
        </CTAList>
      </StyledProjectListManager>
    </Modal>
  )
}

export default ProjectListManager
