import fetch, { METHOD } from '../fetch'

export const get = async (token, uuid) => {
  const { status, projectList } = await fetch(METHOD.GET, '/project-list/get', {
    token,
    uuid,
  })
  if (status && projectList) {
    return projectList
  }
  return false
}

export const update = async (token, list) => {
  const { status } = await fetch(METHOD.PUT, '/project-list/update-list', {
    token,
    list,
  })

  return status
}

export const list = async (token) => {
  const { status, projectLists } = await fetch(METHOD.GET, '/project-list/list', {
    token,
  })
  if (status && projectLists) {
    return projectLists
  }
  return false
}

export const create = async (token, name, comment) => {
  const { status, projectList } = await fetch(METHOD.POST, '/project-list/create', {
    token,
    name,
    comment,
  })
  if (status && projectList) return projectList
  return false
}

export const addItem = async (token, projectListUUID, article) => {
  const { status } = await fetch(METHOD.POST, '/project-list/add-item', {
    token,
    projectListUUID,
    article,
  })
  return status
}

export const remove = async (token, uuid) => {
  const { status } = await fetch(METHOD.DELETE, '/project-list/remove', {
    token,
    uuid,
  })
  return status
}

export const removeItem = async (token, uuid) => {
  const { status } = await fetch(METHOD.DELETE, '/project-list/remove-item', {
    token,
    uuid,
  })
  return status
}
