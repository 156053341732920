import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getSingleByArtikelnummer } from '../../../../../interface/article.js'
import Image from '../../../../UI/Image/Image.js'
import Input from '../../../../UI/Input/Input.js'
import {
  StyledWire,
  StyledSleeve,
  Name,
  Count,
  StyledCartItem,
  ButtonWrapper,
  ImageWrapper,
  CustomImage,
  WrapperTitle,
  ArticleImageWrapper,
  SingleItemWrapper,
  CustomButton,
  Number,
} from './Item.Styled.js'
import deleteIcon from '../../../../../assets/image/delete.png'
import SleeveProperties from '../../../../Article/Detail/WireView/SleeveProperties/SleeveProperties.js'
import PlusIcon from '../../../../../assets/image/plus.png'
import MinusIcon from '../../../../../assets/image/minus.png'
import Modal from '../../../../UI/Modal/Modal.js'
import getWireLabel from '../../../../../helper/getWireLabel.js'
import Flex from '../../../../UI/Flex/Flex.js'
import Button from '../../../../UI/Button/Button.js'
import Text from '../../../../UI/Text/Text.js'

const Item = ({ item, updateProp, remove, setList }) => {
  const [wire, setWire] = useState(null)
  const [sleeve, setSleeve] = useState(null)
  const token = useSelector((s) => s.token)
  const translation = useSelector((s) => s.translation)

  const wireUUID = item?.article?.wireUUID
  const sleeveUUID = item?.article?.sleeveUUID
  const artikelnummer = item?.article?.wireArticleNumber

  const [showModal, setShowModal] = useState(false)
  const [countWire, setCountWire] = useState(item?.article?.countWireInMeter)
  const [countSleeve, setCountSleeve] = useState(item?.article?.countSleeve)

  const [siblingSleeve, setSiblingSleeve] = useState(null)

  useEffect(() => {
    const fetchWireAndSleeve = async () => {
      const { wire, sleeve } = await getSingleByArtikelnummer(token, artikelnummer)

      if (wire) {
        setWire(wire)
      }
      if (sleeve) {
        setSleeve(sleeve)
      }

      if (wire?.sibling) {
        const { sleeve } = await getSingleByArtikelnummer(token, wire.sibling.artikelnummerbayka)
        setSiblingSleeve(sleeve)
      }
    }

    if (token && !wire && !sleeve && artikelnummer) fetchWireAndSleeve()
  }, [token, wire, sleeve, wireUUID, sleeveUUID, artikelnummer])

  const removePosition = (item) => () => remove()

  const decrement = (item) => () =>
    updateProp('amount')(
      parseInt(item.amount) > 1 ? parseInt(item.amount) - 1 : parseInt(item.amount),
    )

  const increment = (item) => () => updateProp('amount')(parseInt(item.amount) + 1)

  const getLabel = (wire, respectSibling = true) => {
    let label = `${wire.anzahlverseilelemente}x${wire.verseilelement}x${
      wire.leiterdurchmesser || wire.leiterdurchmesserinmm
    }`
    if (wire.rkkennzahl) {
      label += ` (${translation.wire.rkkennzahl} ${wire.rkkennzahl})`
    }
    if (wire.sibling && respectSibling) {
      label += ` + ${getLabel(wire.sibling, false)}`
    }
    return label
  }

  const saveCountChange = () => {
    setList((prev) => {
      const newList = [...prev.articles]

      const index = newList.findIndex((el) => el.article.uuid === item.article.uuid)

      newList[index].article.countWireInMeter = countWire
      newList[index].article.countSleeve = countSleeve
      return { ...prev, articles: newList }
    })

    setShowModal(false)
  }

  return (
    <StyledCartItem key={item.article.uuid}>
      <WrapperTitle>
        {!wire && !sleeve && (
          <SingleItemWrapper>
            <ArticleImageWrapper>
              <Image margin="0 15px 0 0" height="70px" src={item?.article?.image || wire?.image} />
            </ArticleImageWrapper>
            <div>
              <Name headline>
                {item?.article?.TIBEZ1
                  ? `${item.article.TIBEZ1} ${item.article.TIBEZ2}`
                  : item.article.name}
              </Name>
              {item?.article?.id && <Number>{`${translation.cart.id} ${item.article.id}`}</Number>}
            </div>
          </SingleItemWrapper>
        )}
        {wire && (
          <SingleItemWrapper>
            <ArticleImageWrapper>
              <Image margin="0 15px 0 0" height="70px" src={item?.article?.image || wire?.image} />
            </ArticleImageWrapper>

            <StyledWire>
              <Name>{`${translation.profile.projectLists.list.item.labelWire} ${
                wire.name
              } ${getLabel(wire)}`}</Name>
              <Number>{`${translation.profile.projectLists.list.item.labelNumber} ${wire.artikelnummerbayka}`}</Number>
              <Number>{`${translation.profile.projectLists.list.item.labelSAP} ${wire.sapmaterialnummerdb}`}</Number>
              <Count>{`${item?.article?.countWireInMeter} ${translation.profile.projectLists.list.item.countWire}`}</Count>
            </StyledWire>
          </SingleItemWrapper>
        )}
        {sleeve && (
          <SingleItemWrapper>
            <ArticleImageWrapper>
              <Image
                margin="0 15px 0 0"
                height="70px"
                src={item?.article?.image || sleeve?.image}
              />
            </ArticleImageWrapper>

            <StyledSleeve>
              <Name>{translation.profile.projectLists.list.item.labelSleeve}</Name>
              <Count>{`${item?.article?.countSleeve} ${translation.profile.projectLists.list.item.countSleeve}`}</Count>
              <SleeveProperties
                data={sleeve}
                type="sleeve"
                siblingSleeve={siblingSleeve}
                propWhitelist={[
                  'TITYPN',
                  'innenmuffe',
                  'mengeinnenmuffe',
                  'quetschverbinder',
                  'mengequetschverbinder',
                  'schirmverbindungsleitung',
                  'mengeschirmverbindungsleitung',
                  'thermoplastischesabdichtband',
                  'mengethermoplastischesabdichtband',
                  'schrumpfschlauch',
                  'mengeschrumpfschlauch',
                  'erdseil',
                  'mengeerdseil',
                  'bausatz',
                  'mengebausatz',
                  'schutzmuffe',
                  'mengeschutzmuffe',
                ]}
              />
            </StyledSleeve>
          </SingleItemWrapper>
        )}
        <SingleItemWrapper>
          <ArticleImageWrapper></ArticleImageWrapper>

          {(!!wire || !!sleeve) && (
            <CustomButton onClick={() => setShowModal(true)}>Ändern</CustomButton>
          )}
        </SingleItemWrapper>
      </WrapperTitle>
      <ButtonWrapper>
        <ImageWrapper>
          <CustomImage src={MinusIcon} alt="minus" onClick={decrement(item)} />
        </ImageWrapper>

        {/* <ItemLabel>{item.productCount}</ItemLabel> */}
        <Input
          type="number"
          value={item.amount}
          onChange={(value) => updateProp('amount')(parseInt(value))}
          width="50%"
        />

        <ImageWrapper>
          <CustomImage src={PlusIcon} alt="plus" onClick={increment(item)} />
        </ImageWrapper>
      </ButtonWrapper>

      <ButtonWrapper>
        <CustomImage src={deleteIcon} cursor="pointer" onClick={removePosition(item)} />
      </ButtonWrapper>
      {showModal && (
        <Modal
          close={() => setShowModal(false)}
          title={getWireLabel(wire)}
          footerContent={
            <Flex justify="flex-end">
              <Button text="Speichern" onClick={saveCountChange} />
            </Flex>
          }
        >
          <Flex wrap="nowrap" margin="0 0 10px 0">
            <Text text={translation.checkout.wireCount} />
            <Input type="number" value={countWire} onChange={(value) => setCountWire(value)} />
          </Flex>
          <Flex wrap="nowrap">
            <Text text={translation.checkout.sleeveCount} />
            <Input type="number" value={countSleeve} onChange={(value) => setCountSleeve(value)} />
          </Flex>
        </Modal>
      )}
    </StyledCartItem>
  )
}

export default Item
