import styled from 'styled-components'
import Link from '../UI/Link/Link'

export const CustomFlex = styled.div`
  display: flex;
  align-items: ${({ align }) => align || 'center'};
  padding: ${({ padding }) => padding || '10px 0px'};
  margin-bottom: ${({ bottom }) => bottom || '20px'};
  border-bottom: ${({ borderBottom }) => borderBottom || '1px solid gray'};
`

export const Slash = styled.div`
  margin: 0px 10px;
`

export const CustomLink = styled(Link)`
  font-size: ${({ theme }) => theme.size.sm};
`
