import { useEffect } from 'react'
import { ArticleMinimizedWire } from '../../Article/Minimized/Minimized'
import { sample } from '../../../interface/search'
import { useState } from 'react'
import styled from 'styled-components'

const StyledSample = styled.div`
  margin-bottom: 50px;
`

const Sample = () => {
  const [data, setData] = useState(null)

  useEffect(() => {
    const getSample = async () => {
      const data = await sample()
      if (data) {
        setData(data)
      }
    }
    if (!data) {
      getSample()
    }
  })
  if (!data) return <></>
  return (
    <StyledSample>
      <ArticleMinimizedWire data={data} />
    </StyledSample>
  )
}

export default Sample
