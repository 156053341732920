import styled from 'styled-components'
import Form from '../../UI/Form/Form'
import Input from '../../UI/Input/Input'

export const StyledSettings = styled(Form)``

export const Headline = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-family: 'bold';
  font-size: ${({ theme }) => theme.size.lg};
  margin: 50px 0;
`

export const CustomInput = styled(Input)`
  min-width: 30%;
  flex: 1 1 0px;
`

export const CTAWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
