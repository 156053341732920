import * as types from '../type/type'

export const setCart = (cart) => ({
  type: types.SET_CART,
  payload: cart,
})

export const addCartItem = (item, productCount) => ({
  type: types.ADD_CART_ITEM,
  payload: productCount ? { productCount, item } : item,
})

export const addCartItems = (items) => ({
  type: types.ADD_CART_ITEMS,
  payload: items,
})

export const removeCartItem = (item) => ({
  type: types.REMOVE_CART_ITEM,
  payload: item,
})

export const removeCartPosition = (item) => ({
  type: types.REMOVE_CART_POSITION,
  payload: item,
})

export const updateWireAndSleeveCount = (index, wireCount, sleeveCount) => ({
  type: types.UPDATE_WIRE_AND_SLEEVE_COUNT,
  payload: { index, wireCount, sleeveCount },
})

export const updateCount = (payload) => ({
  type: types.UPDATE_CART_COUNT,
  payload,
})

export const setSleeveProducts = (payload) => ({ type: types.SET_CART_SLEEVE_PRODUCTS, payload })
