import React from 'react'
import {
  CheckboxContainer,
  HiddenCheckbox,
  Icon,
  StyledCheckbox,
  StyledSpan,
  Wrapper,
} from './Checkbox.Styled'

const Checkbox = ({
  className,
  checked,
  label,
  margin,
  width,
  onChange,
  CustomLabel,
  ...props
}) => (
  <Wrapper width={width} margin={margin}>
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} onChange={() => {}} {...props} />

      <StyledCheckbox checked={checked} onClick={() => onChange(!checked)}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      {label && <StyledSpan onClick={() => onChange(!checked)}>{label}</StyledSpan>}
      {CustomLabel && (
        <StyledSpan onClick={() => onChange(!checked)}>
          <CustomLabel />
        </StyledSpan>
      )}
    </CheckboxContainer>
  </Wrapper>
)

export default Checkbox
