import { backendDomain, presignedUploadURLEndpoint } from './utility'
import axios from 'axios'

export const METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
}

export const TYPE = {}

const fetch = async (method, uriSuffix, body = {}, overwriteURL = false) => {
  try {
    if (!uriSuffix) return false
    const result = await axios({
      method,
      url: overwriteURL ? uriSuffix : `${backendDomain}${uriSuffix}`,
      [method === METHOD.GET ? 'params' : 'data']: body,
    })
    return result.data
  } catch (err) {
    return false
  }
}

export const fileDownload = async (method, uriSuffix, body = {}, overwriteURL = false) => {
  const result = await axios({
    method,
    url: overwriteURL ? uriSuffix : `${backendDomain}${uriSuffix}`,
    [method === METHOD.GET ? 'params' : 'data']: body,
    headers: {
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
      },
    },
  })
  return result.data
}

export const fileUploadS3 = async (token, file, type, progressCallback) => {
  const fileMeta = {
    name: file.name,
    type: file.type,
    size: file.size,
  }
  const { status, presignedURL, key } = await fetch(METHOD.POST, presignedUploadURLEndpoint, {
    token,
    fileMeta,
    type,
  })
  if (status && presignedURL && key) {
    const uploadResult = await axios.put(presignedURL, file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress: (e) => {
        if (typeof progressCallback !== 'function') return
        const progress = parseInt(Math.round((e.loaded / e.total) * 100))
        progressCallback(progress)
      },
    })
    return uploadResult.status === 200 ? key : false
  } else {
    return false
  }
}

export const fileUploadBackend = async (token, uriSuffix, files, body, progressCallback) => {
  const formData = new FormData()
  if (Array.isArray(files)) {
    files.forEach((file) => formData.append('files', file))
  }
  if (typeof body === 'object') {
    Object.keys(body).forEach((key) => formData.append(key, body[key]))
  }
  const uploadResult = await axios.post(`${backendDomain}${uriSuffix}?token=${token}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (e) => {
      if (typeof progressCallback !== 'function') return
      const progress = parseInt(Math.round((e.loaded / e.total) * 100))
      progressCallback(progress)
    },
  })
  return uploadResult && uploadResult.data && uploadResult.data.status === true
}

export default fetch
