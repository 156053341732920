import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyle from './Global.Styled'
import { ThemeProvider } from 'styled-components'
import Imprint from './components/Imprint/Imprint'
import DataProtection from './components/DataProtection/DataProtection'
import getTheme from './shared-resources/theme'
import Admin from './components/Admin/Admin'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTheme } from './reducer/action/theme'
import Home from './components/Home/Home'
import PasswordLost from './components/PasswordLost/PasswordLost'
import SearchList from './components/SearchList/SearchList'
import Contact from './components/Contact/Contact'
import Checkout from './components/Checkout/Checkout'
import Confirmation from './components/Confirmation/Confirmation'
import Importer from './components/Importer/Importer'
import ArticleDetail from './components/Article/Detail/Detail'
// import WorkshopsAndTrainings from './components/WorkshopsAndTrainings/WorkshopsAndTrainings'
import ToolsAndDownloads from './components/ToolsAndDownloads/ToolsAndDownloads'
import GenericInfo from './components/GenericInfo/GenericInfo'
import Profile from './components/Profile/Profile'
import ImporterSleeve from './components/ImporterSleeve/ImporterSleeve.js'
import ImporterUnit from './components/ImporterUnit/ImporterUnit.js'
import SleeveDetail from './components/Article/Detail/SleeveView/SleeveView'
import FAQ from './components/FAQ/FAQ'
import Export from './Export/Export.js'

function App() {
  const theme = useSelector((s) => s.theme)
  const dispatch = useDispatch()

  useEffect(() => {
    const pullOriginTheme = async () => {
      dispatch(setTheme(getTheme()))
    }
    if (!theme) {
      pullOriginTheme()
    }
  })

  if (!theme) return <></>

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Routes>
            <Route index element={<Home />} />
            <Route path="password-lost/:uuid" element={<PasswordLost />} />
            <Route path={'search'} element={<SearchList />} />
            <Route path={'detail/:uuid'} element={<ArticleDetail />} />
            <Route path={'detail/sleeve/:uuid'} element={<SleeveDetail />} />
            <Route path={'confirmation/:uuid'} element={<Confirmation />} />
            <Route path={'imprint'} element={<Imprint />} />
            <Route path={'data-protection'} element={<DataProtection />} />
            <Route path={'admin/*'} element={<Admin />} />
            <Route path={'profile/*'} element={<Profile />} />
            <Route path="generic-info" element={<GenericInfo />} />
            <Route path="tools-and-download" element={<ToolsAndDownloads />} />
            {/* <Route path="workshops-and-trainings" element={<WorkshopsAndTrainings />} /> */}
            <Route path="contact" element={<Contact />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="importer" element={<Importer />} />
            <Route path="importer-sleeve" element={<ImporterSleeve />} />
            <Route path="importer-unit" element={<ImporterUnit />} />
            <Route path="export" element={<Export />} />
            <Route path={'*'} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </div>
  )
}

export default App
