import RobotoThin from './assets/font/Roboto/Roboto-Thin.ttf'
import RobotoRegular from './assets/font/Roboto/Roboto-Regular.ttf'
import RobotoBold from './assets/font/Roboto/Roboto-Bold.ttf'
import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: "thin";
  src: url(${RobotoThin}) format("truetype");
}

@font-face {
  font-family: "regular";
  src: url(${RobotoRegular}) format("truetype");
}

@font-face {
  font-family: "bold";
  src: url(${RobotoBold}) format("truetype");
}

* {
  padding: 0px;
  margin: 0px;
  font-family: "regular", sans-serif;
  font-size: ${({ theme }) => theme.size.md};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.sm};
  }
}

html,
body {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}

.Toastify {
  * {
  font-size: ${({ theme }) => theme.size.sm};
  }
}

`

export default GlobalStyles
