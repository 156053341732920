import { useSelector } from 'react-redux'
import { StyledMenu, Section, Item } from './Menu.Styled'
import { getUUID } from '../../../utility'

const Menu = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <StyledMenu>
      {translation.genericInfo.menu.map((section) => (
        <Section key={getUUID()}>
          {section.map(({ name, href }) => (
            <Item key={getUUID()} href={href}>
              {name}
            </Item>
          ))}
        </Section>
      ))}
    </StyledMenu>
  )
}

export default Menu
