import styled from "styled-components";
import Hero from "../UI/Hero/Hero";
import Image from "../UI/Image/Image";

export const StyledDataProtection = styled.div`
  p,
  .headline,
  li {
    font-size: ${({ theme }) => theme.size.sm};
    margin-top: 15px;
  }
  .headline {
    font-family: "bold";
    margin-top: 50px;
  }
  .underline {
    text-decoration: underline;
  }
`;

export const CustomHero = styled(Hero)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const CustomImage = styled(Image)`
  height: 250px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 150px;
  }
`;
