import fetch, { METHOD } from '../fetch'

export const suggest = async (input) => {
  const { status, suggestions } = await fetch(METHOD.GET, '/search/suggest', { input })
  if (status && Array.isArray(suggestions)) {
    return suggestions
  }
  return false
}

export const search = async (token, input, filter) => {
  const { status, results } = await fetch(METHOD.POST, '/search', { token, input, filter })
  if (status && Array.isArray(results)) {
    return results
  }
  return false
}
export const sample = async () => {
  const { status, data } = await fetch(METHOD.GET, '/search/suggest/sample')
  if (status && data) {
    return data
  }
  return false
}
