import styled from 'styled-components'

export const StyledDashboard = styled.div``

export const Label = styled.div``

export const StyledLabel = styled.label`
  color: ${({ theme, color }) => color || theme.color.button.font};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.color.button.background};
  padding: 5px 20px;
  width: ${({ width }) => width || 'auto'};
  margin: ${({ margin }) => margin || '0px'};
  border: thin solid ${({ theme }) => theme.color.border};
  display: inline-block;
  text-align: center;
  font-size: ${({ theme, minimized }) => theme.size.sm};
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.sm};
  }
  margin-top: 10px;
`

export const Select = styled.input`
  display: none;
`

export const Wrapper = styled.div`
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
  width: 50%;
`

export const Headline = styled.div``
