import { useSelector } from 'react-redux'
import BreadCrumb from '../BreadCrumb/BreadCrunb'
import Search from '../Search/Search'
import Container from '../UI/Container/Container'
import Layout from '../UI/Layout/Layout'
import { StyledGenericInfo, Headline } from './GenericInfo.Styled'
import Menu from './Menu/Menu'
import WireBuildupAndCode from './WireBuildupAndCode/WireBuildupAndCode'

const GenericInfo = () => {
  const translation = useSelector((s) => s.translation)

  const section = window.location.href.split('#')[1]

  setTimeout(() => {
    document.querySelector(`#${section}`)?.scrollIntoView()
  }, 100)

  return (
    <Layout authRequired={false}>
      <StyledGenericInfo>
        <Container>
          <BreadCrumb items={[{ label: 'Homepage', link: '/' }, { label: 'Allgemeine Angaben' }]} />
          <Search />
          <Headline>{translation.genericInfo.headline}</Headline>
          <Menu />
          <WireBuildupAndCode />
        </Container>
      </StyledGenericInfo>
    </Layout>
  )
}

export default GenericInfo
