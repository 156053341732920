import { useDispatch, useSelector } from 'react-redux'
import { setLoginModalContext } from '../../reducer/action/loginModalContext'
import { Link } from './EvalRedirect.Styled.js'

const EvalRedirect = ({ route, children, clickedCallback }) => {
  const token = useSelector((s) => s.token)
  const dispatch = useDispatch()

  const clicked = () => {
    if (token) {
      window.location = route
      if (clickedCallback) clickedCallback()
    } else {
      dispatch(setLoginModalContext({ show: true, route }))
    }
  }

  return (
    <Link href={route} onClick={clicked}>
      <div>{children}</div>
    </Link>
  )
}

export default EvalRedirect
