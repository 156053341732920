import styled from 'styled-components'

export const StyledConfirmation = styled.div`
  margin-top: 50px;
`

export const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundGray};
  padding: 20px;
`

export const Headline = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.size.lg};
  margin-bottom: 25px;
`

export const Hint = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  text-align: center;
`
