import {
  StyledModal,
  Core,
  Header,
  Title,
  Close,
  Content,
  Background,
  Footer,
} from './Modal.Styled'
import closeIcon from '../../../assets/image/close.png'
import { useEffect, useCallback } from 'react'

const Modal = ({
  title,
  close,
  children,
  footerContent,
  onEnter,
  noBackground = false,
  preventEnter = false,
}) => {
  const escFunction = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        close()
      } else if (event.key === 'Enter' && onEnter) {
        onEnter()
        event.preventDefault()
      } else if (event.key === 'Enter' && preventEnter) {
        event.preventDefault()
      }
    },
    [close, onEnter, preventEnter],
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction)
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
      document.removeEventListener('keydown', escFunction)
    }
  }, [escFunction])

  return (
    <StyledModal>
      <Core>
        <Header>
          <Title text={title} />
          {close && <Close src={closeIcon} onClick={close} />}
        </Header>
        <Content>{children}</Content>
        {footerContent && <Footer>{footerContent}</Footer>}
      </Core>
      {!noBackground && <Background onClick={close} />}
    </StyledModal>
  )
}

export default Modal
