import styled from 'styled-components'
import Form from '../../UI/Form/Form'

export const StyledLoginForm = styled(Form)``

export const PasswordLost = styled.div`
  color: ${({ theme }) => theme.color.font.thirdary};
  font-size: ${({ theme }) => theme.size.sm};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
