const _ = require('lodash')

const theme = {
  size: {
    xsm: '14px',
    sm: '16px',
    md: '22px',
    lg: '30px',
    xlg: '38px',
  },
  color: {
    font: {
      primary: 'black',
      secondary: 'white',
      thirdary: '#777777',
    },
    button: {
      background: '#118bdc',
      font: '#ffffff',
    },
    input: {
      background: '#ffffff',
      font: '#000000',
      border: '#ffffff',
    },
    background: 'white',
    backgroundGray: 'rgb(236,237,242)',
    backgroundDark: '#222222',
    border: '#000000',
    primary: '#118bdc',
    secondary: 'rgb(236,237,242)',
    danger: '#E74C3C',
  },
  breakpoint: {
    sm: '768px',
    md: '992px',
    lg: '1300px',
  },
  border: {
    radius: '0px',
  },
  shadow: '2px 2px 16px -1px rgba(0,0,0,0.48)',
}

const getTheme = (originTheme = {}) => _.merge({}, theme, originTheme)

module.exports = getTheme
