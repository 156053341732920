import { useSelector } from 'react-redux'
import Section from '../Contact/Section/Section'
import Container from '../UI/Container/Container'
import Layout from '../UI/Layout/Layout'
import {
  CTA,
  CTASection,
  StyledToolsAndDownloads,
  Headline,
  Title,
  Image,
  Text,
  Subheadline,
  Line,
} from './ToolsAndDownloads.Styled'
import bulb from '../../assets/image/toolsAndDownloads/bulb.jpg'
import dop from '../../assets/image/toolsAndDownloads/dop.jpg'
import download from '../../assets/image/toolsAndDownloads/download.jpg'
import faq from '../../assets/image/toolsAndDownloads/faq.jpg'
import toolAndPaper from '../../assets/image/toolsAndDownloads/tool-and-paper.jpg'
import wireHand from '../../assets/image/toolsAndDownloads/wire-hand.jpg'
import wireRoll from '../../assets/image/toolsAndDownloads/wire-roll.jpg'
import { getUUID } from '../../utility'
import BreadCrumb from '../BreadCrumb/BreadCrunb'

const ToolsAndDownloads = () => {
  const translation = useSelector((s) => s.translation)
  const toolImages = [wireHand, wireRoll, dop, toolAndPaper, bulb, faq]
  const downloadImages = [download, download, download]
  return (
    <Layout authRequired={false}>
      <StyledToolsAndDownloads>
        <Container>
          <BreadCrumb items={[{ label: 'Homepage', link: '/' }, { label: 'Tools & Downloads' }]} />
          <Headline>{translation.toolsAndDownloads.headline}</Headline>
          <Subheadline>
            <Line />
            {translation.toolsAndDownloads.toolsSubheadline}
          </Subheadline>
          <CTASection>
            {translation.toolsAndDownloads.toolCTAs.map(({ title, href, text }, i) => {
              return (
                <CTA key={getUUID()}>
                  <Image src={toolImages[i]} />
                  <Title href={href}>{title}</Title>
                  <Text>{text}</Text>
                </CTA>
              )
            })}
          </CTASection>
          <Subheadline>
            <Line />
            {translation.toolsAndDownloads.downloadsSubheadline}
          </Subheadline>
          <CTASection>
            {translation.toolsAndDownloads.downloadCTAs.map(({ title, href, text }, i) => {
              return (
                <CTA key={getUUID()}>
                  <Image src={downloadImages[i]} />
                  <Title href={href}>{title}</Title>
                  <Text>{text}</Text>
                </CTA>
              )
            })}
          </CTASection>
          <Section data={translation.contact.sections[0]} hideLineColumn />
        </Container>
      </StyledToolsAndDownloads>
    </Layout>
  )
}

export default ToolsAndDownloads
