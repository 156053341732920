import styled from 'styled-components'

export const StyledContact = styled.div`
  padding: 50px 0px;
`

export const SectionWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: calc(100% - 30px);
  }
`
