import Container from '../UI/Container/Container'
import Hero from '../UI/Hero/Hero'
import Layout from '../UI/Layout/Layout'
import Textarea from '../UI/Textarea/Textarea'
import {
  StyledFAQ,
  HeroOverlay,
  TextWrapper,
  HeroHeadline,
  HeroText,
  Title,
  Hint,
  CTAWrapper,
} from './FAQ.Styled'
import heroImage from '../../assets/image/home/hero.jpg'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import Form from '../UI/Form/Form'
import Input from '../UI/Input/Input'
import Button from '../UI/Button/Button'
import { sendMessage } from '../../interface/faq'
import toast, { TYPE } from '../../toast'
import BreadCrumb from '../BreadCrumb/BreadCrunb'

const FAQ = () => {
  const translation = useSelector((s) => s.translation)
  const [payload, setPayload] = useState({})

  const submit = async () => {
    const status = await sendMessage(payload)
    if (status) {
      toast(TYPE.SUCCESS, translation.faq.success.submit)
      setPayload({})
    } else {
      toast(TYPE.ERROR, translation.faq.error.submit)
    }
  }

  const updateProp = (prop) => (value) => setPayload({ ...payload, [prop]: value })

  return (
    <Layout authRequired={false}>
      <StyledFAQ>
        <Hero img={heroImage}>
          <HeroOverlay>
            <TextWrapper>
              <HeroHeadline text={translation.home.hero.overlay.headline} />
              <HeroText text={translation.home.hero.overlay.text} />
            </TextWrapper>
          </HeroOverlay>
        </Hero>
        <Container>
          <BreadCrumb items={[{ label: 'Homepage', link: '/' }, { label: 'FAQ' }]} />
          <Title>{translation.faq.title}</Title>
          <Hint>{translation.faq.hint}</Hint>
          <Form onSubmit={submit}>
            <Input
              value={payload.name}
              onChange={updateProp('name')}
              label={translation.faq.name}
              placeholder={translation.faq.name}
              margin="25px 0"
              required
            />
            <Input
              value={payload.mail}
              onChange={updateProp('mail')}
              label={translation.faq.mail}
              placeholder={translation.faq.mail}
              type="email"
              margin="25px 0"
              required
            />
            <Textarea
              placeholder={translation.faq.message}
              label={translation.faq.message}
              value={payload.message}
              onChange={updateProp('message')}
              required
            />
            <CTAWrapper>
              <Button type="submit" text={translation.faq.submitCTA} />
            </CTAWrapper>
          </Form>
        </Container>
      </StyledFAQ>
    </Layout>
  )
}

export default FAQ
