import { StyledContact } from './Contact.Styled'
import Input from '../../UI/Input/Input'
import Textarea from '../../UI/Textarea/Textarea'
import { useState } from 'react'
// import { useSelector } from 'react-redux'

const Contact = (handleClose) => {
  const [plz, setPlz] = useState('')
  const [message, setMessage] = useState('')
  //   const token = useSelector((state) => state.token)

  //   const send = async () => {
  //     const status = await send(token, plz, message)
  //     if (status) handleClose()
  //   }

  return (
    <StyledContact>
      <Input value={plz} onChange={(value) => setPlz(value)} />
      <Textarea value={message} onChange={(value) => setMessage(value)} />
    </StyledContact>
  )
}

export default Contact
