import styled from 'styled-components'

export const StyledNoResults = styled.div``

export const Title = styled.div`
  margin: 25px 0;
`
export const Text = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
`
