import styled from 'styled-components'
import Text from '../../../../../UI/Text/Text'

export const StyledTLSuggestions = styled.div`
  width: 33%;
  padding: 10px;
  background-color: ${({ theme }) => theme.color.secondary};
  position: relative;
  /* padding-bottom: 60px; */
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin: 20px 0px;
    width: 100%;
  }
`

export const Item = styled.div`
  margin-top: 5px;
  color: ${({ theme }) => theme.color.font.thirdary};
  cursor: pointer;
  font-size: ${({ theme }) => theme.size.sm};
  &:hover {
    text-decoration: underline;
  }
`

export const Headline = styled(Text)`
  border-bottom: 1px solid ${({ theme }) => theme.color.background};
  padding-bottom: 5px;
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.size.sm};
`

export const CTAWrapper = styled.div`
  display: flex;
  gap: 15px;
  /* bottom: 15px; */
  margin-bottom: 15px;
  margin-top: 15px;
  /* position: absolute; */
  /* width: calc(100% - 30px); */
  width: 100%;
`

export const CTA = styled.a`
  width: 33%;
  background-color: ${({ theme }) => theme.color.background};
  padding: 40px 0 5px 0;
  text-align: center;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.size.sm};
  font-family: 'bold';
  display: block;
  color: ${({ theme }) => theme.color.font.thirdary};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const SuggestionWrapper = styled.div``
