import styled from 'styled-components'

export const StyledArticles = styled.div`
  margin-top: 50px;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
`

export const Body = styled.div``

export const Name = styled.div`
  width: 60%;
  color: ${({ theme }) => theme.color.primary};
`

export const Count = styled.div`
  width: 20%;
  color: ${({ theme }) => theme.color.primary};
  text-align: center;
`

export const Delete = styled.div`
  width: 20%;
  color: ${({ theme }) => theme.color.primary};
  text-align: center;
`

export const Headline = styled.div`
  margin: ${({ margin }) => margin || '0'};
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.size.md};
`
