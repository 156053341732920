import Layout from '../components/UI/Layout/Layout'
import Button from '../components/UI/Button/Button'
import fetch, { METHOD } from '../fetch'
import { useSelector } from 'react-redux'
import { triggerClientSideDownload } from '../utility'

const Export = () => {
  const token = useSelector((s) => s.token)

  const exportData = async () => {
    const { status, data } = await fetch(METHOD.GET, '/export/sleeves', { token })

    if (status && data) {
      triggerClientSideDownload(data, 'export.csv')
    }
  }

  return (
    <Layout>
      <Button text="Exportieren" onClick={exportData} />
    </Layout>
  )
}

export default Export
