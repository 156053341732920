import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import fetch, { METHOD } from '../../../fetch.js'
import { StyledSleeveComponents, Header, Column } from './SleeveComponents.Styled.js'
import Row from './Row/Row.js'
import usePrevious from '../../../helper/usePrevious.js'
import _ from 'lodash'
import { setSleeveProducts } from '../../../reducer/action/cart.js'

const SleeveComponents = ({ overwriteCart, overwriteSleeveProducts }) => {
  const cart = useSelector((state) => state.cart)
  const token = useSelector((s) => s.token)
  const [sleeves, setSleeves] = useState(null)
  const [sums, setSums] = useState(null)
  const dispatch = useDispatch()
  const prevCart = usePrevious(cart)
  const prevOverwriteCart = usePrevious(overwriteCart)

  const sleeveUUIDs = [...(overwriteCart || cart)].reduce((acc, el) => {
    if (el?.article?.wireArticleNumber) acc.push(el.article?.wireArticleNumber)
    return acc
  }, [])
  // console.log(cart)
  useEffect(() => {
    const fetchSleeves = async () => {
      const { status, results } = await fetch(METHOD.GET, '/sleeve/resolve', { token, sleeveUUIDs })
      if (status && results) setSleeves(results)
    }

    const resolveSum = () => {
      const propWhitelist = [
        'innenmuffe',
        'mengeinnenmuffe',
        'quetschverbinder',
        'mengequetschverbinder',
        'schirmverbindungsleitung',
        'mengeschirmverbindungsleitung',
        'thermoplastischesabdichtband',
        'mengethermoplastischesabdichtband',
        'schrumpfschlauch',
        'mengeschrumpfschlauch',
        'erdseil',
        'mengeerdseil',
        'bausatz',
        'mengebausatz',
        'schutzmuffe',
        'mengeschutzmuffe',
      ]

      const filledCard = []
      let cartIndex = 0
      for (const item of [...(overwriteCart || cart)]) {
        const foundSleeve = sleeves.find(
          (el) => el.wire.artikelnummerbayka === item.article?.wireArticleNumber,
        )

        if (foundSleeve && item.article?.countSleeve)
          filledCard.push({ ...item, ...foundSleeve.sleeve, wire: foundSleeve.wire, cartIndex })
        cartIndex++
      }
      console.log(filledCard)
      //cardPosition for every Item in filledCard to check if 2 items that matches the conditions are siblings or new positions
      for (const sleeve of sleeves) {
        if (!filledCard.find((e) => e.matchingUUID === sleeve.sleeve.matchingUUID)) {
          const cardItem = [...(overwriteCart || cart)]
            .map((el, i) => ({ ...el, cartIndex: i }))
            .find(
              (el) =>
                el.article?.wireArticleNumber === sleeve.wire.artikelnummerbayka &&
                filledCard.filter(
                  (e) =>
                    // e.article.countSleeve === el.article.countSleeve &&
                    // e.article?.wireArticleNumber === sleeve.wire.artikelnummerbayka,
                    e.cartIndex === el.cartIndex,
                ).length < 2,
            )
          if (cardItem)
            filledCard.push({
              ...cardItem,
              mengequetschverbinder: sleeve.sleeve.mengequetschverbinder,
              quetschverbinder: sleeve.sleeve.quetschverbinder,
              wire: sleeve.wire,
            })
        }
      }

      console.log(filledCard)

      const sumOfSleeveComponents = filledCard.reduce((acc, el) => {
        let accToReturn = [...acc]
        for (const prop of propWhitelist) {
          if (prop.startsWith('menge') && el[prop]) {
            const foundItem = accToReturn.find(
              (e) =>
                e.prop === prop.replace('menge', '') && e.value === el[prop.replace('menge', '')],
            )

            if (foundItem)
              accToReturn = [
                ...accToReturn.filter(
                  (i) =>
                    i.prop !== prop.replace('menge', '') ||
                    i.value !== el[prop.replace('menge', '')],
                ),
                {
                  ...foundItem,
                  count:
                    foundItem.count +
                    parseFloat(el[prop].replace(',', '.')) *
                      parseInt(el.article.countSleeve || 0) *
                      el.productCount,
                },
              ]
            else
              accToReturn.push({
                prop: prop.replace('menge', ''),
                value: el[prop.replace('menge', '')],
                count:
                  parseFloat(el[prop].replace(',', '.')) *
                  parseInt(el.article.countSleeve || 0) *
                  el.productCount,
              })
          }
        }
        return accToReturn
      }, [])
      console.log(sumOfSleeveComponents)
      setSums(sumOfSleeveComponents)
    }

    const cleanUp = () => {
      setSleeves(null)
      setSums(null)
      dispatch(setSleeveProducts([]))
    }

    if (cart && !sleeves && sleeveUUIDs) fetchSleeves()
    if ((cart || overwriteCart) && Array.isArray(sleeves) && sleeves.length > 0 && !sums)
      resolveSum()
    if (!_.isEqual(cart, prevCart) || !_.isEqual(overwriteCart, prevOverwriteCart)) cleanUp()
  }, [
    token,
    sleeveUUIDs,
    cart,
    sleeves,
    sums,
    overwriteCart,
    prevCart,
    prevOverwriteCart,
    dispatch,
  ])

  return (
    <StyledSleeveComponents>
      <Header>
        <Column width="40%">Bestandteile von Muffen-Sets</Column>
        <Column width="20%">Artikelnummer</Column>
        <Column width="20%">benötigt für Sets</Column>
        <Column width="20%">Auswahl Menge</Column>
      </Header>
      {Array.isArray(sums) &&
        sums
          .sort((a, b) => a.prop.localeCompare(b.prop))
          .filter((s) => !!s.count)
          .map((s) => (
            <Row overwriteSleeveProducts={overwriteSleeveProducts} key={s.value} item={s} />
          ))}
    </StyledSleeveComponents>
  )
}

export default SleeveComponents
