import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { confirmUser } from '../../interface/confirmation'
import Button from '../UI/Button/Button'
import Container from '../UI/Container/Container'
import Flex from '../UI/Flex/Flex'

import Layout from '../UI/Layout/Layout'
import { StyledConfirmation, ContentWrapper, Headline, Hint } from './Confirmation.Styled'

const Confirmation = () => {
  const translation = useSelector((s) => s.translation)
  const [success, setSuccess] = useState(false)
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const confirm = async () => {
      const status = await confirmUser(params.uuid)
      setSuccess(status)
    }

    if (!success && params) confirm()
  }, [params, success])

  return (
    <Layout authRequired={false}>
      <StyledConfirmation>
        <Container>
          <ContentWrapper>
            {success && (
              <>
                <Headline>{translation.signup.confirm.success.headline}</Headline>
                <Flex justify={'center'}>
                  <Button
                    onClick={() => navigate('/')}
                    text={translation.signup.confirm.success.redirect}
                  />
                </Flex>
              </>
            )}
            {!success && (
              <>
                <Headline>{translation.signup.confirm.failure.headline}</Headline>
                <Hint>{translation.signup.confirm.failure.hint}</Hint>
              </>
            )}
          </ContentWrapper>
        </Container>
      </StyledConfirmation>
    </Layout>
  )
}

export default Confirmation
