import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Image from '../../../UI/Image/Image'
import {
  StyledSleeveView,
  Header,
  ImageWrapper,
  HeaderContent,
  Details,
  // Headline,
  Column,
  Notice,
  NoticeText,
  Select,
  HeadlineSelect,
  SelectHeader,
  SelectRow,
  RowText,
  Value,
  StyledUL,
  TextValue,
  HeaderValue,
} from './SleeveView.Styled'
import Container from '../../../UI/Container/Container'
import Search from '../../../Search/Search'
import Text from '../../../UI/Text/Text'
import { awsStorageBucketPrefix, getUUID } from '../../../../utility'
import { addCartItem } from '../../../../reducer/action/cart'
import Layout from '../../../UI/Layout/Layout'
import { useParams } from 'react-router-dom'
import { getStandaloneSleeve, getStandaloneChilds } from '../../../../interface/article'
import customToast, { TYPE } from '../../../../toast'
import _ from 'lodash'
import Input from '../../../UI/Input/Input'
import toCartIcon from '../../../../assets/image/toCart.png'
import BreadCrumb from '../../../BreadCrumb/BreadCrunb'
import SleeveImageMapping from '../../../../assets/SleeveImageMapping'
import Flex from '../../../UI/Flex/Flex'

const SleeveView = () => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)
  const token = useSelector((s) => s.token)
  const dispatch = useDispatch()
  const { uuid } = useParams()
  const [data, setData] = useState(null)
  const [childs, setChilds] = useState(null)

  useEffect(() => {
    const fetchSleeve = async () => {
      const response = await getStandaloneSleeve(token, uuid)
      if (response) setData(response)
      else customToast(TYPE.ERROR, translation.sleeveView.error.fetch)
    }

    const fetchChilds = async () => {
      const response = await getStandaloneChilds(token, data.uuid)
      if (Array.isArray(response)) setChilds(response)
      else customToast(TYPE.ERROR, translation.sleeveView.error.fetch)
    }

    if (token && uuid && !data) fetchSleeve()
    if (token && data && !childs) fetchChilds()
  }, [uuid, token, data, translation, childs])

  const addToCart = (c) => () => {
    if (c.count > 0) {
      for (let i = 0; i < c.count; i++) {
        dispatch(addCartItem(c))
      }
      customToast(TYPE.SUCCESS, translation.sleeveView.success.addToCart)
    } else customToast(TYPE.WARNING, translation.sleeveView.warning.count)
  }

  const updateCount = (uuid) => (count) => {
    const newChilds = _.cloneDeep(childs)
    const child = newChilds.find((c) => c.uuid === uuid)
    child.count = count
    setChilds(newChilds)
  }

  const getImage = () => {
    const foundMapping = SleeveImageMapping.find((el) => el.id.toString() === data.id)
    if (foundMapping) {
      const url = awsStorageBucketPrefix + 'product_pictures/' + foundMapping.Bild
      return url
    }

    return data.image
  }

  const getHeaders = (childs) => {
    let headers = []
    if (childs) {
      for (const child of childs) {
        for (const int_caption of child.int_caption) {
          if (headers.includes(int_caption)) continue
          headers.push(int_caption)
        }
      }
    }

    return headers
  }
  return (
    <Layout>
      {data && (
        <StyledSleeveView>
          <Container>
            <BreadCrumb
              items={[
                { label: 'Homepage', link: '/' },
                { label: 'Suche', link: '/search' },
                { label: 'DetailSeite' },
              ]}
            />
            <Search />
          </Container>
          <Container>
            <Header>
              <ImageWrapper>
                <Image src={getImage()} maxHeight={'100%'} maxWidth={'100%'} />
              </ImageWrapper>
              <HeaderContent>
                <Text color={theme.color.primary} text={data.name || data.product_name_shop} />
                <Text size={theme.size.sm} text={data.description} margin="10px 0 0 0" />
                <Details>
                  <Column>
                    {/* <Headline
                      color={theme.color.primary}
                      text={translation.sleeveView.shortDescription}
                      margin="0 0 5px 0"
                    /> */}
                    <StyledUL>
                      {data.int_caption.map((int) => (
                        <li key={getUUID()}>{int}</li>
                      ))}
                    </StyledUL>
                    {(data.name?.toLowerCase().includes('svsm') ||
                      data.product_name_shop?.toLowerCase().includes('svsm')) && (
                      <>
                        <Notice text={translation.sleeveView.notice} />
                        <NoticeText
                          color={theme.color.font.thirdary}
                          text={translation.sleeveView.noticeText}
                          size={theme.size.sm}
                        />
                      </>
                    )}
                  </Column>
                  <Column>
                    {Object.keys(data)
                      .map((prop) => {
                        if (
                          prop.includes('innenmuffe') ||
                          prop.includes('quetschverbinder') ||
                          prop.includes('schirmverbindungsleitung')
                        ) {
                          return (
                            <Text
                              key={getUUID()}
                              size={theme.size.sm}
                              text={`- ${translation.sleeve[prop]}: ${data[prop]}`}
                            />
                          )
                        }
                        return null
                      })
                      .filter((item) => !!item)}
                  </Column>
                </Details>
              </HeaderContent>
            </Header>
            <HeadlineSelect
              text={`${translation.sleeveView.select} ${data.name}`}
              color={theme.color.primary}
            />
            <Select>
              <SelectHeader>
                <Text
                  size="18px"
                  width="10%"
                  text={translation.sleeveView.table.header.articlenr}
                />
                <Text
                  size="18px"
                  width="20%"
                  text={translation.sleeveView.table.header.description}
                />
                {getHeaders(childs).map((header) => (
                  <Text size="18px" width={`${40 / getHeaders(childs).length}%`} text={header} />
                ))}
                <Text
                  // align="center"
                  size="18px"
                  width="30%"
                  text={translation.sleeveView.table.header.count}
                />
              </SelectHeader>
              {Array.isArray(childs) &&
                childs.map((c) => (
                  <SelectRow key={c.uuid}>
                    <RowText size={theme.size.sm} width="10%">
                      <HeaderValue>{translation.sleeveView.table.header.articlenr}</HeaderValue>
                      <TextValue size={theme.size.sm}>{c.TITYPN}</TextValue>
                    </RowText>
                    <RowText size={theme.size.sm} width="20%">
                      <HeaderValue>{translation.sleeveView.table.header.description}</HeaderValue>
                      <TextValue size={theme.size.sm}>{c.name || c.TIBEZ1}</TextValue>
                    </RowText>
                    {getHeaders(childs).map((header, i) => (
                      <RowText
                        size={theme.size.sm}
                        width={`${40 / getHeaders(childs).length}%`}
                        text="1"
                      >
                        {/* <HeaderValue>{translation.sleeveView.table.header.details}</HeaderValue> */}
                        <Value key={getUUID()}>{`${c.DE?.[i]}`}</Value>
                      </RowText>
                    ))}
                    {/* <RowText size={theme.size.sm} width="30%" text="1">
                      <HeaderValue>{translation.sleeveView.table.header.details}</HeaderValue>
                      {c.int_caption.map((int_c, i) => (
                        <Value key={getUUID()}>{`${int_c} ${c.DE?.[i]}`}</Value>
                      ))}
                    </RowText> */}
                    <RowText size={theme.size.sm} width="30%" text="1">
                      <HeaderValue>{translation.sleeveView.table.header.count}</HeaderValue>
                      <Flex wrap="no-wrap" width="100%" justify="space-between">
                        <Input
                          width="50%"
                          type="number"
                          value={c.count || '0'}
                          onChange={updateCount(c.uuid)}
                        />
                        <Image
                          cursor="pointer"
                          src={toCartIcon}
                          width="40px"
                          onClick={addToCart(c)}
                        />
                      </Flex>
                    </RowText>
                    {/* <RowText align="right" width="10%">
                      <Image
                        cursor="pointer"
                        src={toCartIcon}
                        width="40px"
                        onClick={addToCart(c)}
                      />
                    </RowText> */}
                  </SelectRow>
                ))}
            </Select>
          </Container>
        </StyledSleeveView>
      )}
    </Layout>
  )
}

export default SleeveView
