import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  StyledTLSuggestions,
  Item,
  Headline,
  CTAWrapper,
  CTA,
  SuggestionWrapper,
} from './TLSuggestions.Styled'
import { getTLSuggestions } from '../../../../../../interface/article'
import { useNavigate } from 'react-router-dom'
import { getUUID } from '../../../../../../utility'
import getWireLabel from '../../../../../../helper/getWireLabel'

const TLSuggestions = ({ lastenheft }) => {
  const token = useSelector((state) => state.token)
  const translation = useSelector((state) => state.translation)
  const [suggestions, setSuggestions] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    const getSuggestions = async () => {
      const suggestions = await getTLSuggestions(token, lastenheft)
      if (suggestions) {
        setSuggestions(suggestions)
      }
    }
    if (token && !suggestions) {
      getSuggestions()
    }
  })

  const goTo = (uuid) => navigate(`/detail/${uuid}`)

  // const getLabel = (suggestion) => {
  //   const label = `${suggestion.name} ...x${suggestion.verseilelement}x${
  //     suggestion.leiterdurchmesser || suggestion.leiterdurchmesserinmm
  //   }`
  //   return label
  // }

  const toBaykaPlus = () => {
    window.open('https://www.baykaplus.de/')
  }

  if (!suggestions) return <></>
  return (
    <StyledTLSuggestions>
      <Headline text={`${translation.wireView.wireData.more}${lastenheft}`} />
      <SuggestionWrapper>
        {suggestions.map((suggestion) => (
          <Item key={getUUID()} onClick={() => goTo(suggestion.uuid)}>
            {getWireLabel(suggestion)}
          </Item>
        ))}
      </SuggestionWrapper>
      <CTAWrapper>
        <CTA href="/contact">{translation.wireView.wireData.cta.contact}</CTA>
        <CTA onClick={toBaykaPlus}>{translation.wireView.wireData.cta.baykaplus}</CTA>
        <CTA href="/faq">{translation.wireView.wireData.cta.faq}</CTA>
      </CTAWrapper>
    </StyledTLSuggestions>
  )
}

export default TLSuggestions
