import styled from 'styled-components'

export const StyledHero = styled.div`
  height: 60vh;
  width: 100vw;
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: center center;
  position: relative;
`
