import styled from 'styled-components'

export const StyledFilter = styled.div``

export const Section = styled.div``

export const Toggle = styled.div`
  font-family: 'bold';
  color: ${({ theme }) => theme.color.font.thirdary};
  padding-left: 35px;
  background-image: ${({ activeImg, inactiveImage, isActive }) =>
    `url(${isActive ? activeImg : inactiveImage})`};
  height: 30px;
  background-size: 30px 30px;
  background-position: left center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
`

export const Content = styled.div``
