import * as types from '../type/type'

export const setItemToAddToProjectList = (payload) => ({
  type: types.SET_ITEM_TO_ADD_TO_PROJECT_LIST,
  payload,
})

export const setItemsToAddToProjectList = (payload) => ({
  type: types.SET_ITEMS_TO_ADD_TO_PROJECT_LIST,
  payload,
})
