import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import toast, { TYPE } from '../../toast'
import { resetPassword } from '../../interface/auth'
import { useSelector } from 'react-redux'
import Input from '../UI/Input/Input'
import { StyledPasswordLost, Headline } from './PasswordLost.Styled'
import Flex from '../UI/Flex/Flex'
import Button from '../UI/Button/Button'
import Container from '../UI/Container/Container'

const PasswordLost = () => {
  const translation = useSelector((s) => s.translation)
  const { uuid } = useParams()
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const submit = async () => {
    const status = await resetPassword(uuid, password)
    if (status) {
      navigate('/')
    } else {
      toast(TYPE.ERROR, translation.passwordLost.error.lostPassword)
    }
  }

  return (
    <Layout authRequired={false}>
      <StyledPasswordLost onSubmit={submit}>
        <Container>
          <Headline>{translation.passwordLost.headline}</Headline>
          <Input
            minimized
            type="password"
            value={password}
            onChange={setPassword}
            placeholder={translation.generic.password}
            margin="10px 0"
            required
          />
          <Flex wrap="no-wrap" justify={'right'} align="center" margin="20px 0 0 0">
            <Button minimized type="submit" text={translation.passwordLost.cta} />
          </Flex>
        </Container>
      </StyledPasswordLost>
    </Layout>
  )
}

export default PasswordLost
