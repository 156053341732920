import { useEffect, useState } from 'react'
import { StyledFilter, Section, Toggle, Content } from './Filter.Styled'
import dropdownActive from '../../../assets/image/searchList/settings/dropdownActive.png'
import dropdownInactive from '../../../assets/image/searchList/settings/dropdownInactive.png'
import { useSelector } from 'react-redux'
import Setting from './Setting/Setting'
import { getUUID } from '../../../utility'
import Button from '../../UI/Button/Button'
import { get } from '../../../interface/filter'
import toast, { TYPE } from '../../../toast'
import { prepareValues } from './helper'
import { useSearchParams } from 'react-router-dom'

const Filter = ({ callback }) => {
  const [filter, setFilter] = useState({ type: 'wire' })
  const translation = useSelector((s) => s.translation)
  const [filterAttributes, setFilterAttributes] = useState(null)
  const token = useSelector((s) => s.token)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const getSettingsFromParams = () => {
      const params = new URLSearchParams(searchParams)
      const filter = {}
      params.forEach((value, key) => {
        if (key !== 'searchString') {
          filter[key] = value
        }
      })

      setFilter(filter)
    }

    if (!filter) {
      getSettingsFromParams()
    }
  }, [searchParams, setSearchParams, filter])

  useEffect(() => {
    const fetchAttributes = async () => {
      const attributes = await get(token)
      if (attributes) {
        setFilterAttributes(attributes)
      } else {
        toast(TYPE.ERROR, translation.searchList.filter.error.attributes)
      }
    }
    if (!filterAttributes) {
      fetchAttributes()
    }
  })

  const apply = () => callback(filter)

  const setOption = (key) => (value) => {
    if (filter[key] === value) {
      const filterCopy = { ...filter }
      delete filterCopy[key]
      setFilter(filterCopy)
    } else {
      setFilter({ ...filter, [key]: value })
    }
  }
  const setType = (type) => () => {
    // if (filter.type === type) {
    //   setFilter({})
    // } else {
    //   setFilter({ type })
    // }
  }

  if (!filterAttributes) return <></>
  return (
    <StyledFilter>
      {Object.keys(filterAttributes).map((type) => {
        return (
          <Section key={getUUID()}>
            <Toggle
              onClick={setType(type)}
              isActive={filter.type === type}
              activeImg={dropdownActive}
              inactiveImage={dropdownInactive}
            >
              {translation[type].label}
            </Toggle>
            {filter.type === type && (
              <Content>
                {Object.keys(filterAttributes[type]).map((attributeProp) => (
                  <Setting
                    state={filter[attributeProp]}
                    title={
                      translation[type].filter[filterAttributes[type][attributeProp].translationKey]
                    }
                    options={prepareValues(
                      attributeProp,
                      filterAttributes[type][attributeProp].values,
                    )}
                    callback={setOption(attributeProp)}
                    key={getUUID()}
                  />
                ))}
              </Content>
            )}
          </Section>
        )
      })}
      <Button onClick={apply} text={translation.searchList.filter.cta} />
    </StyledFilter>
  )
}

export default Filter
