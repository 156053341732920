import styled from 'styled-components'

export const StyledUser = styled.div`
  padding: 10px;
  margin: 10px;
  background-color: ${({ theme }) => theme.color.backgroundGray}; ;
`

export const NameWrapper = styled.div`
  width: 80%;
`
export const ButtonWrapper = styled.div`
  width: calc(20%-5px);
`

export const EditPropWrapper = styled.div``

export const Label = styled.div``
