import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import fetch, { METHOD } from '../../../../../fetch'
import { getUUID } from '../../../../../utility'
import {
  StyledProperties,
  CustomText,
  Wrapper,
  ValueWrapper,
} from '../Properties/Properties.Styled'

const SleeveProperties = ({ data, siblingSleeve, type, propWhitelist = null }) => {
  const translation = useSelector((s) => s.translation)
  const token = useSelector((s) => s.token)
  const [units, setUnits] = useState()

  useEffect(() => {
    const fetchUnit = async () => {
      const response = await fetch(METHOD.GET, '/unit/get-all', { token })

      if (response.status && response.results) {
        setUnits(response.results)
      }
    }

    if (token && !units) fetchUnit()
  }, [token, units, data])

  const propsToMap = propWhitelist.reduce((acc, el) => {
    if (!el.startsWith('menge')) acc.push(el)
    return acc
  }, [])

  const getMatchingUnit = (foundProp) => {
    if (units)
      return units?.find((el) => el.BezKabelMuffen === data[foundProp.replace('menge', '')])
        ?.Einheit
    return null
  }

  const getUnit = (prop, data) => {
    const foundProp = propWhitelist.find((el) => el === `menge${prop}`)

    if (foundProp) return `(je ${data[foundProp]} ${getMatchingUnit(foundProp)})`
    else return ''
  }

  return (
    <StyledProperties>
      {Object.keys(data)
        .filter((prop) => !propsToMap || propsToMap.includes(prop))
        .map((prop) => {
          if (!translation[type][prop] || prop === 'name') return null
          return (
            <Wrapper key={getUUID()}>
              <CustomText width="50%" key={getUUID()} text={`- ${translation[type][prop]}:`} />
              <ValueWrapper>
                <CustomText key={getUUID()} text={`${data[prop]} ${getUnit(prop, data)}`} />
                {siblingSleeve && prop === 'quetschverbinder' && (
                  <CustomText
                    key={getUUID()}
                    text={`${siblingSleeve[prop]} ${getUnit(prop, siblingSleeve)}`}
                  />
                )}
              </ValueWrapper>
            </Wrapper>
          )
        })
        .filter((item) => !!item)}
    </StyledProperties>
  )
}

export default SleeveProperties
