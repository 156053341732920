import { useState } from 'react'
import { useSelector } from 'react-redux'
import Input from '../../UI/Input/Input'
import { StyledNewListView, CTAList } from './NewListView.Styled'
import Button from '../../UI/Button/Button'
import { create } from '../../../interface/projectList'
import toast, { TYPE } from '../../../toast'

const NewListView = ({ close }) => {
  const translation = useSelector((s) => s.translation)
  const token = useSelector((s) => s.token)
  const [name, setName] = useState('')
  const [comment, setComment] = useState('')

  const save = async () => {
    if (!name) {
      toast(TYPE.ERROR, translation.projectListManager.new.error.create)
      return
    }
    const projectList = await create(token, name, comment)
    if (projectList) {
      close(projectList)
    } else {
      toast(TYPE.ERROR, translation.projectListManager.new.error.create)
    }
  }

  return (
    <StyledNewListView>
      <Input label={translation.projectListManager.new.name} value={name} onChange={setName} />
      <Input
        label={translation.projectListManager.new.comment}
        value={comment}
        onChange={setComment}
        margin="25px 0 0 0"
      />
      <CTAList>
        <Button text={translation.projectListManager.new.cancelCTA} onClick={() => close()} />
        <Button text={translation.projectListManager.new.saveCTA} onClick={save} />
      </CTAList>
    </StyledNewListView>
  )
}

export default NewListView
