import Layout from '../UI/Layout/Layout'
import { StyledImprint, CustomHero, CustomImage } from './Imprint.Styled'
import heroImage from '../../assets/image/imprint/hero.jpg'
import logo from '../../assets/image/logo.png'
import Container from '../UI/Container/Container'
import Headline from '../UI/Headline/Headline'
import { useSelector } from 'react-redux'
import BreadCrumb from '../BreadCrumb/BreadCrunb'

const Imprint = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <Layout>
      <CustomHero img={heroImage}>
        <Container>
          <CustomImage src={logo} />
        </Container>
      </CustomHero>
      <StyledImprint>
        <Container>
          <BreadCrumb items={[{ label: 'Homepage', link: '/' }, { label: 'Impressum' }]} />
          <Headline text={translation.imprint.headline} margin="50px 0px" />
          <p>
            Hiermit erfüllen wir die Impressumspflicht im Internet nach § 5 TMG (Telemediengesetz)
            (früher § 6 TDG und § 10 MDStV) vom 01.03.2007. Es folgen die einzelnen Pflichtangaben:
          </p>

          <div className="headline "></div>
        </Container>
      </StyledImprint>
    </Layout>
  )
}

export default Imprint
