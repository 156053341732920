import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import fetch, { METHOD } from '../../../fetch'
import FullscreenCart from '../../FullscreenCart/FullscreenCart'
import Container from '../../UI/Container/Container'
import Hero from '../../UI/Hero/Hero'
import Layout from '../../UI/Layout/Layout'
import {
  HeroOverlay,
  TextWrapper,
  HeroHeadline,
  HeroText,
  SearchWrapper,
  ButtonWrapperWrapper,
  ButtonWrapper,
} from './SingleCart.Styled.js'
import heroImage from '../../../assets/image/home/hero.jpg'
import Search from '../../Search/Search'
import { setItemsToAddToProjectList } from '../../../reducer/action/projectListManager'
import { getUUID } from '../../../utility'
import Button from '../../UI/Button/Button'

const SingleCart = () => {
  const [cart, setCart] = useState(null)
  const [sleeveProducts, setSleeveProducts] = useState(null)
  const token = useSelector((s) => s.token)
  const translation = useSelector((s) => s.translation)
  const dispatch = useDispatch()

  const { uuid } = useParams()

  useEffect(() => {
    const fetchCarts = async () => {
      const { status, cart, sleeveProducts } = await fetch(METHOD.GET, '/cart/get-completed', {
        token,
        uuid,
      })
      if (status && cart && sleeveProducts) {
        setCart(cart)
        setSleeveProducts(sleeveProducts)
      }
    }

    if (token && !cart) fetchCarts()
  }, [cart, token, uuid])

  const addToProjectList = () => {
    const itemsToAdd = []
    for (const item of cart) {
      itemsToAdd.push({
        uuid: getUUID(),
        wireArticleNumber: item.article.wireArticleNumber,
        countWireInMeter: item.article.countWireInMeter,
        countSleeve: item.article.countSleeve,
      })
    }
    dispatch(setItemsToAddToProjectList(itemsToAdd))
  }

  return (
    <Layout>
      <Hero img={heroImage}>
        <HeroOverlay>
          <TextWrapper>
            <HeroHeadline text={translation.home.hero.overlay.headline} />
            <HeroText text={translation.home.hero.overlay.text} />
          </TextWrapper>
        </HeroOverlay>
      </Hero>
      <Container>
        <SearchWrapper>
          <Search />
        </SearchWrapper>
        {Array.isArray(cart) && (
          <FullscreenCart
            fullscreen={true}
            overwriteCart={cart}
            overwriteSleeveProducts={sleeveProducts}
          />
        )}
        <ButtonWrapperWrapper>
          <ButtonWrapper>
            <Button
              text={translation.articleDetail.addToProjectList}
              onClick={addToProjectList}
              width="100%"
            />
          </ButtonWrapper>
        </ButtonWrapperWrapper>
      </Container>
    </Layout>
  )
}

export default SingleCart
