import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import {
  StyledSearchList,
  Results,
  HeroOverlay,
  TextWrapper,
  HeroHeadline,
  HeroText,
  Split,
  FilterWrapper,
  ResultsWrapper,
  NoResults,
  NoResultsWrapper,
  Request,
  RequestLink,
  LinkWrapper,
} from './SearchList.Styled'
import { search } from '../../interface/search'
import toast, { TYPE } from '../../toast'
import { ArticleMinimizedWire, ArticleMinimizedSleeve } from '../Article/Minimized/Minimized'
import Hero from '../UI/Hero/Hero'
import Container from '../UI/Container/Container'
import Search from '../Search/Search'
import { getUUID } from '../../utility'
import heroImage from '../../assets/image/searchList/hero.jpg'
import Filter from './Filter/Filter'
import BreadCrumb from '../BreadCrumb/BreadCrunb'
import getWireLabel from '../../helper/getWireLabel'

const SearchList = () => {
  const [searchParams] = useSearchParams()
  const [results, setResults] = useState(null)
  const token = useSelector((s) => s.token)
  const translation = useSelector((s) => s.translation)
  const navigate = useNavigate()

  const searchString = searchParams.get('searchString')

  useEffect(() => {
    const getFilter = () => {
      const params = new URLSearchParams(searchParams)
      const filter = {}
      params.forEach((value, key) => {
        if (key !== 'searchString') {
          filter[key] = value
        }
      })
      return filter
    }

    const getGroupResults = (results) => {
      let labelLog = []
      let groupedResults = []

      for (const result of results) {
        const label = getWireLabel(result)
        if (!labelLog.includes(label)) {
          labelLog.push(label)
          groupedResults.push(result)
        }
      }
      return groupedResults
    }

    const getResults = async () => {
      const results = await search(
        token,
        searchString === 'null' ? '' : searchString || '',
        getFilter(),
      )

      if (results) {
        setResults(getGroupResults(results))
      } else {
        toast(TYPE.ERROR, translation.searchList.error.load)
      }
    }

    if (token && results === null) {
      getResults()
    }
  }, [searchParams, results, token, translation, searchString])

  const retrigger = (filter, searchStringOverwrite = false) => {
    const searchString = searchParams.get('searchString')
    let url = `/search/?searchString=${
      searchStringOverwrite === false ? searchString : searchStringOverwrite
    }`

    if (filter) {
      url += Object.keys(filter).reduce((acc, key) => {
        acc += `&${key}=${filter[key]}`
        return acc
      }, '')
    }

    navigate(url)

    setResults(null)
  }

  return (
    <Layout>
      <StyledSearchList>
        <Hero img={heroImage}>
          <HeroOverlay>
            <TextWrapper>
              <HeroHeadline text={translation.home.hero.overlay.headline} />
              <HeroText text={translation.home.hero.overlay.text} />
            </TextWrapper>
          </HeroOverlay>
        </Hero>
        <Container>
          <BreadCrumb
            items={[
              { label: 'Homepage', link: '/' },
              { label: 'Suche', link: '/search?type=wire' },
            ]}
          />
        </Container>
        <Container>
          <Search retrigger={retrigger} />
        </Container>
        <Container>
          <Split>
            <FilterWrapper>
              <Filter callback={retrigger} />
            </FilterWrapper>
            <ResultsWrapper>
              {results && (
                <Results>
                  {results.length === 0 && (
                    <>
                      <NoResults text={translation.searchList.noResults}></NoResults>
                      <NoResultsWrapper>
                        <Request>{translation.searchList.noResults2}</Request>
                        <LinkWrapper>
                          <RequestLink href="/contact">
                            {translation.searchList.noResults3}
                          </RequestLink>
                          <Request>{translation.searchList.noResults4}</Request>
                        </LinkWrapper>
                      </NoResultsWrapper>
                    </>
                  )}
                  {results.map((data) =>
                    data.type === 'wire' ? (
                      <ArticleMinimizedWire key={getUUID()} data={data} input={searchString} />
                    ) : (
                      <ArticleMinimizedSleeve key={getUUID()} data={data} />
                    ),
                  )}
                </Results>
              )}
            </ResultsWrapper>
          </Split>
        </Container>
      </StyledSearchList>
    </Layout>
  )
}

export default SearchList
