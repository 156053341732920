import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { listUser } from '../../../interface/userManagement'
import Layout from '../../UI/Layout/Layout'
import User from './User/User'
import { StyledUserManagement } from './UserManagement.Styled'

const UserManagement = () => {
  const [userList, setUserList] = useState(null)
  const token = useSelector((state) => state.token)

  useEffect(() => {
    const getUsers = async () => {
      const userList = await listUser(token)
      if (userList) setUserList(userList)
    }

    if (!Array.isArray(userList) && token) getUsers()
  }, [token, userList])

  return (
    <Layout>
      <StyledUserManagement>
        {Array.isArray(userList) && userList.map((user) => <User key={user.uuid} user={user} />)}
      </StyledUserManagement>
    </Layout>
  )
}

export default UserManagement
