import {
  StyledHeader,
  StyledToggle,
  Navigation,
  CustomLink,
  Item,
  ItemWrapper,
  Logo,
  LinkWrapper,
} from './Header.Styled'
import burger from '../../../../assets/image/burger.png'
import logoImage from '../../../../assets/image/logo.png'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { setAccount } from '../../../../reducer/action/account'
import { setToken } from '../../../../reducer/action/token'
import Cart from '../../../Cart/Cart'
import Container from '../../Container/Container'
import { frontendDomain } from '../../../../utility'

const Header = () => {
  const translation = useSelector((s) => s.translation)
  const token = useSelector((s) => s.token)
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const account = useSelector((s) => s.account)

  const logout = () => {
    const cookies = new Cookies()
    cookies.remove('token', { path: '/' })
    dispatch(setAccount(null))
    dispatch(setToken(null))
    setOpen(false)
    navigate('/')
  }

  const goToGeneric = (path) => {
    setOpen(false)
    window.location = `${frontendDomain}/generic-info${path ? `#${path}` : ''}`
  }

  useEffect(() => {
    document.body.style = `overflow: ${open ? 'hidden' : 'auto'}`
  }, [open])

  return (
    <StyledHeader>
      <ItemWrapper>
        <Logo src={logoImage} onClick={() => navigate('/search/?type=wire')} />

        <Item>
          <StyledToggle src={burger} alt="Menu" onClick={() => setOpen(!open)} />
          {!location.pathname.includes('checkout') && <Cart />}
        </Item>
      </ItemWrapper>
      {open && (
        <Navigation>
          <Container>
            <LinkWrapper>
              {account?.user?.isAdmin && (
                <CustomLink
                  bold
                  text={translation.header.nav.adminDashboard}
                  onClick={() => navigate('/admin/dashboard')}
                />
              )}
              {token && (
                <CustomLink
                  bold
                  text={translation.header.nav.profile}
                  onClick={() => navigate('/profile')}
                />
              )}
              {token && (
                <>
                  <CustomLink
                    text={translation.header.nav.projectLists}
                    onClick={() => navigate('/profile/project-lists')}
                  />
                  <CustomLink
                    text={translation.header.nav.carts}
                    onClick={() => navigate('/profile/carts')}
                  />
                  <CustomLink
                    text={translation.header.nav.cart}
                    onClick={() => navigate('/checkout')}
                  />
                </>
              )}
              <CustomLink
                bold
                text={translation.header.nav.search}
                onClick={() => navigate('/search/?type=wire')}
              />
              <CustomLink
                bold
                text={translation.header.nav.generalInformation}
                onClick={() => goToGeneric()}
              />
              <CustomLink text={translation.header.nav.wires} onClick={() => goToGeneric()} />
              <CustomLink
                text={translation.header.nav.wireBuildAndCode}
                onClick={() => goToGeneric('wire-buildup-and-code')}
              />
              <CustomLink
                text={translation.header.nav.guidelines}
                onClick={() => goToGeneric('guidelines')}
              />
              <CustomLink
                text={translation.header.nav.transportationStorage}
                onClick={() => goToGeneric('transportation-and-storage')}
              />
              <CustomLink
                text={translation.header.nav.sleeves}
                onClick={() => goToGeneric('generic')}
              />
              <CustomLink
                text={translation.header.nav.translationTable}
                onClick={() => goToGeneric('mapping-table')}
              />
              <CustomLink
                bold
                text={translation.header.nav.toolsanddownload}
                onClick={() => navigate('/tools-and-download')}
              />

              {/* <CustomLink
              bold
              text={translation.header.nav.workshopsandtrainings}
              onClick={() => navigate('/workshops-and-trainings')}
            /> */}
              <CustomLink bold text={translation.header.nav.faq} onClick={() => navigate('/faq')} />
              <CustomLink
                bold
                text={translation.header.nav.contact}
                onClick={() => navigate('/contact')}
              />
              {token && <CustomLink bold text={translation.header.nav.logout} onClick={logout} />}
            </LinkWrapper>
          </Container>
        </Navigation>
      )}
    </StyledHeader>
  )
}

export default Header
