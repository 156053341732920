import { Route, Routes } from 'react-router-dom'
import ProjectLists from './ProjectLists/ProjectLists'
import SingleProjectList from './SingleProjectList/SingleProjectList'
import Settings from './Settings/Settings'
import Carts from './Carts/Carts.js'
import SingleCart from './SingleCart/SingleCart.js'

const Profile = () => {
  return (
    <Routes>
      <Route path={'/'} element={<Settings />} />
      <Route path={'project-lists'} element={<ProjectLists />} />
      <Route path={'project-lists/:uuid'} element={<SingleProjectList />} />
      <Route path={'carts/'} element={<Carts />} />
      <Route path={'carts/:uuid'} element={<SingleCart />} />
    </Routes>
  )
}

export default Profile
