import styled from 'styled-components'
import Image from '../UI/Image/Image'
import Text from '../UI/Text/Text'

export const StyledCart = styled.div`
  position: ${({ fullscreen }) => (fullscreen ? 'relative' : 'fixed')};
  ${({ fullscreen }) => !fullscreen && 'height: calc(100vh - 0px)'};
  width: ${({ fullscreen }) => (fullscreen ? '100%' : '400px')};
  /* margin-top: 100px; */
  top: 0;
  right: 0;
  z-index: 1000;
  /* display: ${({ show }) => (show ? 'block' : 'none')}; */
  background-color: ${({ theme }) => theme.color.background};
  padding: 20px;
  box-sizing: border-box;
  box-shadow: ${({ fullscreen }) => (fullscreen ? '' : ' -4px 3px 16px -6px rgba(0, 0, 0, 0.48)')};
  -webkit-box-shadow: ${({ fullscreen }) =>
    fullscreen ? '' : ' -4px 3px 16px -6px rgba(0, 0, 0, 0.48)'};
  -moz-box-shadow: ${({ fullscreen }) =>
    fullscreen ? '' : ' -4px 3px 16px -6px rgba(0, 0, 0, 0.48)'};

  border: ${({ theme, fullscreen }) => (fullscreen ? `1px solid ${theme.color.border}` : '')};
`

export const StyledCartItem = styled.div`
  margin: 5px;
  padding: 5px;
  /* border: 1px solid black; */
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  padding-bottom: 10px;
`

export const ItemLabel = styled.div`
  color: ${({ theme }) => theme.color.font.primary};
`

export const Title = styled.div`
  color: ${({ theme }) => theme.color.font.primary};
  font-size: ${({ theme }) => theme.size.lg};
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
`

export const ImageWrapper = styled.div`
  flex: 1 1 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const CustomImage = styled(Image)`
  width: 50px;
  cursor: pointer;
`

export const Wrapper = styled.div`
  margin: ${({ margin }) => margin || '0'};
  display: flex;
`

export const StyledCloseWrapper = styled.div`
  display: flex;
  width: 100%;
  /* flex-direction: row-reverse; */
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  padding-bottom: 10px;
  margin-bottom: 10px;
`
export const StyledClose = styled.div`
  cursor: pointer;
  color: black;
`

export const ButtonSubmitWrapper = styled.div`
  position: absolute;
  width: calc(100% - 40px);
  bottom: 20px;
  height: 30px;
`

export const CartItemWrapper = styled.div`
  overflow-y: scroll;
  ${({ fullscreen }) => !fullscreen && 'overflow-y: scroll'};
  ${({ fullscreen }) => !fullscreen && 'height: calc(100% - 100px)'};
`

export const HeroHeadline = styled(Text)`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.size.lg};
  text-transform: uppercase;
  font-family: 'bold';
`

export const HeroText = styled(Text)`
  margin-top: 10px;
  color: ${({ theme }) => theme.color.font.secondary};
`

export const SearchWrapper = styled.div`
  width: 70%;
  margin-left: 15%;
`

export const HeroOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  width: 30%;
  height: inherit;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-right: 15px solid ${({ theme }) => theme.color.primary};
`

export const TextWrapper = styled.div`
  padding: 50px;
`
