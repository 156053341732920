import fetch, { METHOD } from '../fetch'

export const login = async (mail, password) => {
  const { status, token } = await fetch(METHOD.POST, '/auth/login', {
    mail,
    password,
  })
  if (status && token) {
    return token
  }
  return false
}

export const lostPassword = async (mail) => {
  const { status } = await fetch(METHOD.POST, '/auth/lost-password', {
    mail,
  })
  return status
}

export const resetPassword = async (uuid, password) => {
  const { status } = await fetch(METHOD.POST, '/auth/lost-password-set', {
    uuid,
    password,
  })
  return status
}

export const resolveToken = async (token) => {
  const { status, account } = await fetch(METHOD.POST, '/auth/resolve-token', {
    token,
  })
  return status ? account : false
}

export const signup = async (payload) => {
  const { status } = await fetch(METHOD.POST, '/auth/signup', payload)
  return status
}
