import fetch, { METHOD } from '../fetch'

export const get = async (token, uuid) => {
  const { status, data } = await fetch(METHOD.GET, '/article/get', { token, uuid })
  if (status && data) {
    return data
  }
  return false
}

export const getSingleByArtikelnummer = async (token, artikelnummer) => {
  const { status, data } = await fetch(METHOD.GET, '/article/get-singe-by-artikelnummer', {
    token,
    artikelnummer,
  })
  if (status && data) {
    return data
  }
  return false
}

export const getSingle = async (token, uuid) => {
  const { status, data } = await fetch(METHOD.GET, '/article/get-single', { token, uuid })
  if (status && data) {
    return data
  }
  return false
}

export const getTLSuggestions = async (token, lastenheft) => {
  const { status, suggestions } = await fetch(METHOD.GET, '/article/get-tl-suggestions', {
    token,
    lastenheft,
  })
  if (status && Array.isArray(suggestions)) {
    return suggestions
  }
  return false
}

export const getStandaloneSleeve = async (token, uuid) => {
  const { status, data } = await fetch(METHOD.GET, '/article/get-standalone-sleeve', {
    token,
    uuid,
  })
  if (status && data) return data
  return false
}

export const getStandaloneChilds = async (token, parentUUID) => {
  const { status, data } = await fetch(METHOD.GET, '/article/get-standalone-childs', {
    token,
    parentUUID,
  })
  if (status && data) return data
  return false
}
