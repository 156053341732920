import fetch, { METHOD } from '../fetch'

export const get = async (token) => {
  const { status, cart, sleeveProducts } = await fetch(METHOD.GET, '/cart/get', {
    token,
  })
  if (status && cart && sleeveProducts) {
    return { cart, sleeveProducts }
  }
  return false
}

export const set = async (token, cart, sleeveProducts) => {
  const { status } = await fetch(METHOD.POST, '/cart/set', {
    token,
    cart,
    sleeveProducts,
  })
  return status
}
