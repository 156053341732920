import styled from 'styled-components'

export const StyledHeadline = styled.div`
  text-align: center;
  color: ${({ color, theme }) => color || theme.color.font.primary};
  font-family: 'bold';
  font-size: ${({ theme }) => theme.size.lg};
  text-transform: uppercase;
  margin: ${({ margin }) => margin || '0px'};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.md};
  }
`
