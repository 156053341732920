import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../UI/Button/Button'
import Image from '../UI/Image/Image'
import {
  ButtonSubmitWrapper,
  CartItemWrapper,
  ItemLabel,
  StyledCart,
  StyledCloseWrapper,
  Title,
  Wrapper,
} from './Cart.Styled'
import CloseIcon from '../../assets/image/close.png'
import CartIcon from '../../assets/image/cart.png'
import Item from './Item/Item'

const Cart = ({ fullscreen = false, margin }) => {
  const [showCart, setShowCart] = useState(false)
  const cart = useSelector((state) => state.cart)
  const token = useSelector((state) => state.token)
  const translation = useSelector((state) => state.translation)
  const navigate = useNavigate()

  const submit = () => navigate('/checkout')

  return (
    <Wrapper margin={margin}>
      {!fullscreen && (
        <Image
          cursor="pointer"
          src={CartIcon}
          alt="Cart"
          width="25px"
          onClick={() => setShowCart(true)}
        />
      )}
      {(showCart || fullscreen) && (
        <StyledCart fullscreen={fullscreen}>
          {!fullscreen && (
            <StyledCloseWrapper>
              <Title>{translation.header.nav.cart}</Title>
              <Image
                cursor="pointer"
                width="30px"
                margin="10px 0"
                src={CloseIcon}
                alt="close"
                onClick={() => setShowCart(false)}
              />
            </StyledCloseWrapper>
          )}
          <CartItemWrapper fullscreen={fullscreen}>
            {Array.isArray(cart) && cart.length > 0 ? (
              cart.map((item, i) => <Item item={item} key={i} />)
            ) : (
              <ItemLabel>{token ? translation.cart.empty : translation.cart.blocked}</ItemLabel>
            )}
          </CartItemWrapper>

          {Array.isArray(cart) && cart.length > 0 && !fullscreen && (
            <ButtonSubmitWrapper>
              <Button text={translation.cart.submit} onClick={submit} width="100%" />
            </ButtonSubmitWrapper>
          )}
        </StyledCart>
      )}
    </Wrapper>
  )
}

export default Cart
