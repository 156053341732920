import styled from 'styled-components'

export const StyledSection = styled.div`
  margin-top: 50px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    text-align: center;
  }
`
export const Title = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.size.md};
`
export const PostcodeAreas = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  color: ${({ theme }) => theme.color.font.thirdary};
`

export const Split = styled.div`
  display: flex;
  gap: 10px;
`

export const Column = styled.div`
  width: ${({ width }) => width || '100%'};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    ${({ width }) => width && `display: none;`}
  }
`

export const Line = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.color.primary};
  margin-top: 20px;
`

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
  margin-top: 15px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    text-align: center;
  }
`

export const Person = styled.div`
  width: calc(33% - 7.5px);
  display: flex;
  gap: 10px;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: calc(50% - 7.5px);
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(100% - 7.5px);
    text-align: center;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    display: block;
  }
`

export const ImageContainer = styled.div`
  width: 200px;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const Data = styled.div`
  flex: 1 1 0px;
`

export const Name = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  font-family: 'bold';
`
export const Area = styled.div`
  font-size: ${({ theme }) => theme.size.xsm};
  margin-bottom: 25px;
`
export const Phone = styled.div`
  font-size: ${({ theme }) => theme.size.xsm};
`
export const Mobile = styled.div`
  font-size: ${({ theme }) => theme.size.xsm};
`
export const Fax = styled.div`
  font-size: ${({ theme }) => theme.size.xsm};
`
export const Mail = styled.div`
  font-size: ${({ theme }) => theme.size.xsm};
`
export const PostcodeArea = styled.div`
  font-size: ${({ theme }) => theme.size.xsm};
`
