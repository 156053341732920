import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { get } from '../../../../interface/projectList'
import { getSingle } from '../../../../interface/article'
import { addCartItem } from '../../../../reducer/action/cart'
import Button from '../../../UI/Button/Button'
import { StyledList, Name, Comment, NameWrapper, ButtonWrapper } from './List.Styled'
import toast, { TYPE } from '../../../../toast'

const List = ({ data, remove }) => {
  const translation = useSelector((s) => s.translation)
  const token = useSelector((s) => s.token)
  const theme = useSelector((s) => s.theme)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const toCart = async () => {
    const projectList = await get(token, data.uuid)
    if (projectList && Array.isArray(projectList.articles)) {
      for (const listArticle of projectList.articles) {
        const wire = await getSingle(token, listArticle.article.wireUUID)
        if (wire) {
          dispatch(addCartItem(wire))
        }

        const sleeve = await getSingle(token, listArticle.article.sleeveUUID)
        if (sleeve) {
          dispatch(addCartItem(sleeve))
        }
        toast(TYPE.SUCCESS, translation.projectListManager.results.success.addProjectList)
      }
    }
  }

  return (
    <StyledList>
      <NameWrapper>
        <Name>{data.name}</Name>
        <Comment>{data.comment}</Comment>
      </NameWrapper>
      <ButtonWrapper>
        <Button
          backgroundColor={theme.color.danger}
          text={translation.profile.projectLists.list.toCart}
          onClick={toCart}
        />
        <Button
          text={translation.profile.projectLists.list.show}
          onClick={() => navigate(`/profile/project-lists/${data.uuid}`)}
        />
        <Button text={translation.profile.projectLists.list.delete} onClick={remove(data.uuid)} />
      </ButtonWrapper>
    </StyledList>
  )
}

export default List
