import styled from 'styled-components'
import Text from '../UI/Text/Text'

export const StyledCheckout = styled.div``

export const ButtonWrapper = styled.div`
  /* width: calc(50%-20px); */
  width: 50%;
  display: flex;
  align-items: center;
  gap: 20px;
`

export const TextWrapper = styled.div`
  padding: 50px;
`

export const HeroHeadline = styled(Text)`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.size.lg};
  text-transform: uppercase;
  font-family: 'bold';
`

export const HeroText = styled(Text)`
  margin-top: 10px;
  color: ${({ theme }) => theme.color.font.secondary};
`

export const SearchWrapper = styled.div`
  width: 70%;
  margin-left: 15%;
`

export const HeroOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  width: 30%;
  height: inherit;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-right: 15px solid ${({ theme }) => theme.color.primary};
`

export const ButtonWrapperWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`
